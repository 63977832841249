import React, { useState, useEffect } from 'react'
import { BorderIpass } from '../../BorderIpass/Index'
import './style.scss'

interface LayoutProps extends React.HTMLAttributes<HTMLInputElement> {
  value: string
  prefix?: string
  label?: string
  SelectStyles?: React.CSSProperties
  setAmount: (value: string) => void
  onEnter?: () => void
  is24HourFormat?: boolean // Permitir formato de 12 o 24 horas
}

export const InputHour: React.FC<LayoutProps> = ({
  value,
  SelectStyles,
  setAmount,
  onEnter,
  prefix,
  label,
  is24HourFormat = true,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const formatTime = (timeString: string) => {
    if (!timeString) return ''

    let [hh, mm] = timeString.split(':').map(Number)
    if (isNaN(hh)) return ''

    hh = Math.min(Math.max(hh, 0), 23)
    mm = mm ? Math.min(Math.max(mm, 0), 59) : 0

    if (!is24HourFormat) {
      const period = hh >= 12 ? 'PM' : 'AM'
      hh = hh % 12 || 12
      return `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')} ${period}`
    }

    return `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')} H`
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9:]/g, '') // Permitir solo números y ':'
    setInternalValue(inputValue)
  }

  const handleBlur = () => {
    if (internalValue.trim() === '') {
      setAmount('') // Permitir borrar completamente
      return
    }
    const formattedTime = formatTime(internalValue)
    setInternalValue(formattedTime)
    setAmount(formattedTime)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (onEnter) onEnter()
    }
  }

  return (
    <BorderIpass margin={1} status="Normal" styleInner={SelectStyles}>
      <div className="custom-number-hour">
        <label
          style={{
            fontSize: '14px',
            marginLeft: '10px',
            marginTop: '10px',
            color: '#DABDF5',
            alignItems: 'center'
          }}
        >
          {label}
        </label>
        <input
          className="input-hour"
          {...props}
          value={internalValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          type="text"
          placeholder={is24HourFormat ? 'Hora inicial (24H)' : 'Hora inicial (AM/PM)'}
        />
      </div>
    </BorderIpass>
  )
}

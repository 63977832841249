import { Certifier, CertifierCreate } from '../types/certifier'
import axiosInstance from './axiosInstance'

export const getCertifiers = async (id: string): Promise<Certifier> => {
  try {
    const response = await axiosInstance.get(`/api/certifier/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista:', error)
    throw error
  }
}

export const createCertifier = async (
  id: string,
  params: CertifierCreate
): Promise<CertifierCreate> => {
  try {
    const response = await axiosInstance.post(`/api/certifier/${id}`, params)

    return response.data
  } catch (error) {
    console.error('Error en la creación:', error)
    throw error
  }
}
export const updateCertifier = async (
  id: string,
  params: CertifierCreate
): Promise<Certifier> => {
  try {
    const response = await axiosInstance.put(`/api/certifier/${id}`, params)

    return response.data
  } catch (error) {
    console.error('Error en la actualización:', error)
    throw error
  }
}

import React, { FC } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { createReceptionist, updateReceptionist } from '../../../services/receptionist'
import { Receptionist, ReceptionistCreate } from '../../../types/receptionist'
import DynamicForm from '../../Form/DynamicForm'
import { FormConfig } from './Columns'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { LightTooltip } from '../../tooltips/text'
import './styles.scss'

const FormReceptionist: FC<{
  onSubmit: () => void
  clientId: string
  initialValue?: Receptionist
}> = ({ onSubmit, initialValue, clientId }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: ReceptionistCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        lastName: formData.lastName,
        userName: formData.userName,
        ...(formData.password ? { password: formData.password } : {}),
        photo: formData.photo,
        clientId: clientId,
        canApprove: formData.canApprove?.toString() ?? 'false'
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      if (initialValue) {
        await updateReceptionist(initialValue.id, data)
      } else {
        await createReceptionist(data)
      }

      onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="newReceptionsForm">
      <div className="title-client">
        <div className="left-component">
          <LightTooltip
            title="Pendiente texto"
            placement="right"
            sx={{
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#261a3a',
                color: '#fff',
                fontSize: '14px',
                padding: '10px',
                borderRadius: '8px',
                border: '1px solid  #5a1fd1',
                boxShadow: 'none'
              }
            }}
          >
            <HelpOutlineIcon style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }} />
          </LightTooltip>
        </div>
        <div className="center-component">
          <h3 className={initialValue ? 'title updating' : 'title creating'}>
            {initialValue ? 'ACTUALIZAR RECEPCIONISTA' : 'NUEVO RECEPCIONISTA'}
          </h3>
        </div>
      </div>
      <div className="container-inputs">
        <DynamicForm
          initialValues={initialValue}
          formConfig={FormConfig}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  )
}

export default FormReceptionist

import React, { useEffect, useState } from 'react'
import './style.scss'
import { BorderIpass } from '../../components/BorderIpass/Index'
import { useLoading } from '../../Provider/LoadingProvider'
import { Toaster, toast } from 'sonner'
import { getPenaltyDate, savePenaltyDate } from '../../services/manageress'
import { InputNumber } from '../../components/Form/inputNumber'
import { LightTooltip } from '../../components/tooltips/text'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Switch from '../../components/Form/Switch'

const InitValues = { valuePorcent: '1', valueForCuota: '5.00' }

const Component: React.FC = () => {
  const { startLoading, stopLoading } = useLoading()

  const [value, setValue] = useState(InitValues)
  const [fee, setFee] = useState<'fixed' | 'percentage'>()
  const [timeSelect, setTimeSelect] = useState<'diary' | 'monthly'>()
  const [enable, setEnable] = useState<boolean>()
  const [chargeAll, setChargeAll] = useState<boolean>()
  const [uploadedData, setuploadedData] = useState<boolean>(false)

  useEffect(() => {
    //se actualiza la data en el backend
    if (uploadedData) {
      handleSave()
    }
  }, [enable, chargeAll])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const data = await getPenaltyDate()
    if (!data) return

    const { value, fee, timeSelect, enable, chargeAll } = data
    setChargeAll(chargeAll)
    setEnable(enable)
    const select = fee === 'percentage' ? 'valuePorcent' : 'valueForCuota'

    setValue(last => ({ ...last, [select]: value }))
    setFee(fee)
    setTimeSelect(timeSelect)

    //damos tiempo para q se cargue la data
    setTimeout(() => {
      setuploadedData(true)
    }, 1000)
  }

  const handleSave = async () => {
    try {
      if (!fee || !timeSelect || !value) {
        toast.error('FALTA CONFIGURAR ALGUN PARÁMETRO')

        return
      }
      setuploadedData(false)

      const configSave = {
        fee,
        timeSelect,
        value: fee === 'percentage' ? value.valuePorcent : value.valueForCuota,
        enable,
        chargeAll
      }
      startLoading()
      const Result = await savePenaltyDate(configSave)
      if (Result) {
        toast.success('PARÁMETROS GUARDADOS CORRECTAMENTE')
      } else {
        toast.error('OCURRIO UN ERROR, PORFAVOR INTENTA MAS TARDE')
      }
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
      getData()
    }
  }

  return (
    <div className="Container_penalty">
      <div className="title">
        <BorderIpass
          margin={0}
          status={'Normal'}
          style={{ ...styleBorderB, width: '70%' }}
          styleInner={{
            ...styleBorderB,
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0.5rem'
          }}
        >
          <h4>CONFIGURACIÓN POLÍTICA DE MORA</h4>
          <p>Debes seleccionar opción A o B y configurar tu política</p>
        </BorderIpass>
      </div>
      <div className="Config_container">
        <div className="Options">
          <BorderIpass
            margin={'0'}
            status={'Normal'}
            styleInner={styleBorderR}
            style={{ ...styleBorderR }}
          >
            <div className=" Options section1">
              <BorderIpass
                personClassName={'button'}
                margin={3}
                status={fee === 'percentage' ? 'Disable' : 'Normal'}
                onClick={() => setFee('percentage')}
                styleInner={{ ...LetterStyleLong, width: '50px', height: '50px' }}
                style={LetterStyleLong}
              >
                A
              </BorderIpass>
              <BorderIpass
                personClassName={'button'}
                margin={4}
                status={'Normal'}
                onClick={() => setFee('percentage')}
                styleInner={{ ...SelectStyles, width: '150px' }}
              >
                PORCENTAJE
              </BorderIpass>
            </div>
          </BorderIpass>
          <div className="Options section2">
            <InputNumber
              prefix={' %'}
              SelectStyles={SelectStyles}
              value={value.valuePorcent}
              setAmount={value =>
                setValue(last => ({
                  ...last,
                  valuePorcent: value
                }))
              }
            />

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={fee === 'percentage' && timeSelect === 'diary' ? 'Disable' : 'Normal'}
              onClick={() => {
                setTimeSelect('diary')
                setFee('percentage')
              }}
              styleInner={SelectStyles}
            >
              POR DÍA
            </BorderIpass>

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={fee === 'percentage' && timeSelect === 'monthly' ? 'Disable' : 'Normal'}
              onClick={() => {
                setTimeSelect('monthly')
                setFee('percentage')
              }}
              styleInner={SelectStyles}
            >
              POR MES
            </BorderIpass>

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={'Normal'}
              styleInner={SelectStyles}
              onClick={() => handleSave()}
            >
              <b>GUARDAR</b>
            </BorderIpass>
          </div>
        </div>

        <div className="Options">
          <BorderIpass
            margin={'0'}
            status={'Normal'}
            styleInner={styleBorderR}
            style={{ ...styleBorderR }}
          >
            <div className=" Options section1">
              <BorderIpass
                personClassName={'button'}
                margin={3}
                status={fee === 'fixed' ? 'Disable' : 'Normal'}
                onClick={() => setFee('fixed')}
                styleInner={{ ...LetterStyleLong, width: '50px', height: '50px' }}
                style={LetterStyleLong}
              >
                B
              </BorderIpass>
              <BorderIpass
                personClassName={'button'}
                margin={4}
                status={'Normal'}
                onClick={() => setFee('fixed')}
                styleInner={{ ...SelectStyles, width: '150px' }}
              >
                CUOTA FIJA
              </BorderIpass>
            </div>
          </BorderIpass>
          <div className="Options section2">
            <InputNumber
              SelectStyles={SelectStyles}
              value={value.valueForCuota}
              prefix={' Q'}
              setAmount={value =>
                setValue(last => ({
                  ...last,
                  valueForCuota: value
                }))
              }
            />

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={fee === 'fixed' && timeSelect === 'diary' ? 'Disable' : 'Normal'}
              onClick={() => {
                setTimeSelect('diary')
                setFee('fixed')
              }}
              styleInner={SelectStyles}
            >
              POR DÍA
            </BorderIpass>

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={fee === 'fixed' && timeSelect === 'monthly' ? 'Disable' : 'Normal'}
              onClick={() => {
                setTimeSelect('monthly')
                setFee('fixed')
              }}
              styleInner={SelectStyles}
            >
              POR MES
            </BorderIpass>

            <BorderIpass
              personClassName={'button'}
              margin={4}
              status={'Normal'}
              styleInner={SelectStyles}
              onClick={() => handleSave()}
            >
              <b>GUARDAR</b>
            </BorderIpass>
          </div>
        </div>
        <div className="title" style={{ width: '70%' }}>
          <BorderIpass
            margin={0}
            status={'Normal'}
            style={{ ...styleBorderB, width: '100%', backgroundColor: 'red' }}
            styleInner={{
              ...styleBorderB,
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0.5rem'
            }}
          >
            <p></p>
          </BorderIpass>
        </div>
        <div className="politic">
          <BorderIpass
            personClassName={'button'}
            margin={3}
            styleInner={{ ...LetterStyleLong, width: '57px', height: '50px' }}
            style={LetterStyleLong}
          >
            C
          </BorderIpass>
          <div className="bottons">
            <div className="boton-active">
              <BorderIpass
                personClassName={'button'}
                margin={4}
                status={enable ? 'Disable' : 'Normal'} // Si está activo, deshabilitado
                onClick={() => setEnable(true)} // Activa la política
                styleInner={{ ...SelectStyles, width: '180px' }}
                PopUp={{
                  title: 'CONFIRMACIÓN',
                  description:
                    'Estás a punto de activar la política de mora para todos los cargos. Esto implicará que cualquier saldo pendiente, ya sea de mantenimiento, amenidades, cargos o multas, estará sujeto a recargos por mora.',
                  acceptText: 'ACTIVAR',
                  rejectText: 'CANCELAR'
                }}
              >
                POLÍTICA ACTIVA
              </BorderIpass>
              <LightTooltip
                title="Al activar la política, se aplicara mora a partir del día siguiente. "
                placement="right"
                sx={{
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: '#261a3a',
                    color: '#fff',
                    fontSize: '14px',
                    padding: '10px',
                    borderRadius: '8px',
                    border: '1px solid  #5a1fd1',
                    boxShadow: 'none'
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleQuestion as any}
                  style={{ cursor: 'pointer', color: '#5a1fd1', fontSize: '25px' }}
                />
              </LightTooltip>
            </div>

            <div className="boton-active">
              <BorderIpass
                personClassName={'button'}
                margin={4}
                status={!enable ? 'Disable' : 'Normal'} // Si está inactivo, deshabilitado
                onClick={() => setEnable(false)} // Desactiva la política
                styleInner={{ ...SelectStyles, width: '180px' }}
                PopUp={{
                  title: 'CONFIRMACIÓN',
                  description:
                    'Estás a punto de desactivar la política de mora para todos los cargos. Esto significa que no se aplicarán recargos por mora a ningún saldo pendiente, incluyendo cuotas de mantenimiento, amenidades, cargos o multas.',
                  acceptText: 'DESACTIVAR',
                  rejectText: 'CANCELAR'
                }}
              >
                POLÍTICA INACTIVA
              </BorderIpass>
              <LightTooltip
                title="Al desactivar la política, deja de aplicarse mora a partir del día siguiente. "
                placement="right"
                sx={{
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: '#261a3a',
                    color: '#fff',
                    fontSize: '14px',
                    padding: '10px',
                    borderRadius: '8px',
                    border: '1px solid  #5a1fd1',
                    boxShadow: 'none'
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleQuestion as any}
                  style={{ cursor: 'pointer', color: '#5a1fd1', fontSize: '25px' }}
                />
              </LightTooltip>
            </div>
          </div>
        </div>
        <div className="title" style={{ width: '70%' }}>
          <BorderIpass
            margin={0}
            status={'Normal'}
            style={{ ...styleBorderB, width: '100%', backgroundColor: 'red' }}
            styleInner={{
              ...styleBorderB,
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0.5rem'
            }}
          >
            <p></p>
          </BorderIpass>
          <div className="section-D">
            <LightTooltip
              title="Al activar mora para todos los cargos, estar indicando al sistema que ya sean cuotas de mantenimiento, amenidades, cargos o multas, tu política será aplicada a todo, en tanto el residente tenga saldos pendientes de pago"
              placement="right"
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#261a3a',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid  #5a1fd1',
                  boxShadow: 'none'
                }
              }}
            >
              <FontAwesomeIcon
                icon={faCircleQuestion as any}
                style={{ cursor: 'pointer', color: '#5a1fd1', fontSize: '30px' }}
              />
            </LightTooltip>
            <BorderIpass
              personClassName={'button'}
              margin={3}
              styleInner={{ ...LetterStyleLong, width: '50px', height: '50px' }}
              style={LetterStyleLong}
            >
              D
            </BorderIpass>
            <h4>Aplicar política de mora a todos los cargos</h4>
            <Switch
              activeColor={'#28a745'}
              ColorDiscative={'#5d7386'}
              value={chargeAll} // Refleja el estado actual
              onChange={() => setChargeAll(prev => !prev)}
              PopUp={{
                title: 'ADVERTENCIA',
                description: `Estás a punto de ${
                  chargeAll ? 'desactivar' : 'activar'
                } la política de mora para todos los cargos.`,
                acceptText: 'CONTINUAR',
                rejectText: 'CANCELAR'
              }}
            />
          </div>
          <BorderIpass
            margin={0}
            status={'Normal'}
            style={{ ...styleBorderB, width: '100%', backgroundColor: 'red' }}
            styleInner={{
              ...styleBorderB,
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0.5rem'
            }}
          >
            <p></p>
          </BorderIpass>
        </div>
      </div>

      <div className="title">
        <BorderIpass
          margin={0}
          status={'Normal'}
          style={{ ...styleBorderB, width: '70%' }}
          styleInner={{
            ...styleBorderB,
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0.5rem'
          }}
        >
          <h4>POLÍTICA APLICADA</h4>
          <p>Estos ejemplos ilustran como se aplicará tu política de mora</p>
        </BorderIpass>
      </div>

      <div className="example">
        <div className="Example_div">
          <div>
            <BorderIpass margin={2} status={'Normal'} styleInner={LetterStyle}>
              A
            </BorderIpass>
          </div>

          <p>
            Cuota Mes por{' '}
            <b>
              <u>Q500.00</u>
            </b>{' '}
            que con política mora por{' '}
            <b>
              <u>PORCENTAJE POR MES</u>{' '}
            </b>{' '}
            hace que la mora sea de:
            <b>
              {' '}
              <u>Q25</u>{' '}
            </b>{' '}
            para hacer que cargos cierren por{' '}
            <b>
              {' '}
              <u>Q525.00</u>
            </b>
          </p>
        </div>

        <div className="Example_div">
          <div>
            <BorderIpass margin={2} status={'Normal'} styleInner={LetterStyle}>
              B
            </BorderIpass>
          </div>
          <p>
            Cuota Mes por{' '}
            <b>
              <u>Q500.00</u>
            </b>{' '}
            que con política mora por{' '}
            <b>
              <u>CUOTA FIJA POR DÍA</u>{' '}
            </b>{' '}
            (10 días retraso) sea de:
            <b>
              {' '}
              <u>Q50</u>{' '}
            </b>{' '}
            para hacer que cargos cierren por{' '}
            <b>
              {' '}
              <u>Q550.00</u>
            </b>
          </p>
        </div>
      </div>
      <Toaster richColors closeButton position="top-right" />
    </div>
  )
}

export default Component

const LetterStyle: React.CSSProperties = {
  alignItems: 'center',
  width: '30px',
  height: '30px',
  color: 'white'
}

const LetterStyleLong: React.CSSProperties = {
  alignItems: 'center',
  borderRadius: '50%',
  fontSize: '40px',
  fontWeight: 'bold',
  color: 'white'
}

const SelectStyles: React.CSSProperties = {
  margin: '4px',
  height: '50px',
  width: '110px',
  color: 'white',
  alignItems: 'center'
}

const styleBorderB: React.CSSProperties = {
  borderRadius: 0,
  margin: 0,
  marginBottom: '5px'
}

const styleBorderR: React.CSSProperties = {
  borderRadius: 0,
  margin: 0,
  marginRight: '3px'
}

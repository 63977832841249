import React, { FC, useEffect } from 'react'
import { createPortal } from 'react-dom'
import './styles.scss'
import { BorderIpass } from '../../../BorderIpass/Index'

type PopUpType = {
  handleAccept: () => void
  handleReject: () => void
  acceptText: string
  rejectText: string
  title: string
  description?: string
  isOpen: boolean
  onClose: () => void
}

const PopUpConfirm: FC<PopUpType> = ({
  handleAccept,
  handleReject,
  acceptText,
  rejectText,
  title,
  description = '',
  isOpen,
  onClose
}) => {
  const popupRoot = document.getElementById('root')

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      document.body.style.overflow = 'hidden'
      document.addEventListener('keydown', handleEscape)
    }

    return () => {
      document.body.style.overflow = ''
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isOpen, onClose])

  if (!isOpen || !popupRoot) return null

  return createPortal(
    <div className="popup-overlay-confirm" onClick={onClose}>
      <BorderIpass>
        <div className="popup" onClick={e => e.stopPropagation()}>
          <p className="regexi">{title}</p>
          <p>{description}</p>
          <div className="button-container">
            <BorderIpass style={{ width: '130px', borderRadius: '12px', border: '5px' }}>
              <button className="accept-button" onClick={handleAccept}>
                {acceptText}
              </button>
            </BorderIpass>
            <BorderIpass style={{ width: '130px', borderRadius: '12px', border: '5px' }}>
              <button className="reject-button" onClick={handleReject}>
                {rejectText}
              </button>
            </BorderIpass>
          </div>
        </div>
      </BorderIpass>
    </div>,
    popupRoot
  )
}

export default PopUpConfirm

// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsProprietarys: TableColumn[] = [
  {
    title: 'Código Destino',
    dataIndex: 'destination',
    key: 'destination',
    type: enumTypeColumn.string
  },
  {
    title: 'Nombres',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Apellidos',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'Teléfono 1',
    dataIndex: 'phone1',
    key: 'phone1',
    type: enumTypeColumn.string
  },
  {
    title: 'Teléfono 2',
    dataIndex: 'phone2',
    key: 'phone2',
    type: enumTypeColumn.string
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    type: enumTypeColumn.string
  },
  {
    title: 'NIT',
    dataIndex: 'nit',
    key: 'nit',
    type: enumTypeColumn.string
  }
]

export const ProprietarysFormConfig: FormField[] = [
  {
    type: FieldTypeEnum.text,
    label: 'Código Destino ',
    name: 'destinationCode',
    required: true
  },
  { type: FieldTypeEnum.text, label: 'Primer Nombre ', name: 'name', required: true },
  { type: FieldTypeEnum.text, label: 'Apellido ', name: 'lastName', required: true },
  { type: FieldTypeEnum.text, label: 'Correo Electrónico', name: 'email' },
  { type: FieldTypeEnum.phone, label: 'Teléfono 1 ', name: 'phone1', required: true },
  { type: FieldTypeEnum.phone, label: 'Teléfono 2', name: 'phone2' },
  { type: FieldTypeEnum.text, label: 'NIT', name: 'nit' }
]

import React, { useState, useEffect } from 'react'
import { BorderIpass } from '../../BorderIpass/Index'

interface LayoutProps extends React.HTMLAttributes<HTMLInputElement> {
  value: string
  prefix?: string
  label?: string
  SelectStyles?: React.CSSProperties
  setAmount: (value: string) => void
  onEnter?: () => void
  showDecimals?: boolean
  isTimeFormat?: boolean // Habilitar formato HH:mm:ss
  isShortTimeFormat?: boolean
}

export const InputNumber: React.FC<LayoutProps> = ({
  value,
  SelectStyles,
  setAmount,
  onEnter,
  prefix,
  label,
  showDecimals = true,
  isTimeFormat = false,
  isShortTimeFormat = false,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value)
  const [dynamicPrefix, setDynamicPrefix] = useState(prefix)

  useEffect(() => {
    setInternalValue(value)
    if (isTimeFormat) {
      const hours = parseInt(value.split(':')[0]) || 0
      setDynamicPrefix(hours >= 12 ? 'PM' : 'AM')
    }
  }, [value, isTimeFormat])

  // Formatear número (para valores normales)
  const formatNumber = (num: number) => {
    return showDecimals ? num.toFixed(2) : num % 1 === 0 ? num.toFixed(0) : num.toFixed(2)
  }

  // Formatear tiempo en HH:mm:ss
  // const formatTime = (seconds: number) => {
  //   const hours = String(Math.floor(seconds / 3600)).padStart(2, '0')
  //   const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0')
  //   const secs = String(seconds % 60).padStart(2, '0')
  //   return `${hours}:${minutes}:${secs}`
  // }

  const formatTime = (seconds: number) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0')
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0')

    if (isShortTimeFormat) {
      return `${hours}:${minutes}` // Solo HH:mm
    }

    const secs = String(seconds % 60).padStart(2, '0')
    return `${hours}:${minutes}:${secs}` // HH:mm:ss
  }

  // Convertir HH:mm:ss a segundos
  // const parseTime = (timeString: string) => {
  //   const [hh, mm, ss] = timeString.split(':').map(Number)
  //   if (hh >= 0 && hh < 24 && mm >= 0 && mm < 60 && ss >= 0 && ss < 60) {
  //     return hh * 3600 + mm * 60 + ss
  //   }
  //   return 0
  // }

  const parseTime = (timeString: string) => {
    const [hh, mm, ss] = timeString.split(':').map(Number)

    if (hh >= 0 && hh < 24 && mm >= 0 && mm < 60) {
      if (isShortTimeFormat) {
        return hh * 3600 + mm * 60 // No toma segundos
      }
      return hh * 3600 + mm * 60 + (ss || 0)
    }
    return 0
  }

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputValue = e.target.value

  //   if (isTimeFormat) {
  //     if (/^\d{0,2}:?\d{0,2}:?\d{0,2}$/.test(inputValue)) {
  //       setInternalValue(inputValue)
  //     }
  //   } else {
  //     if (/^\d*\.?\d*$/.test(inputValue)) {
  //       setInternalValue(inputValue)
  //     }
  //   }
  // }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    if (isTimeFormat) {
      const regex = isShortTimeFormat
        ? /^\d{0,2}:?\d{0,2}$/ // Solo HH:mm
        : /^\d{0,2}:?\d{0,2}:?\d{0,2}$/ // HH:mm:ss

      if (regex.test(inputValue)) {
        setInternalValue(inputValue)
      }
    } else {
      if (/^\d*\.?\d*$/.test(inputValue)) {
        setInternalValue(inputValue)
      }
    }
  }

  // const handleBlur = () => {
  //   if (internalValue === '') {
  //     const defaultValue = isTimeFormat ? '00:00:00' : formatNumber(1)
  //     setInternalValue(defaultValue)
  //     setAmount(defaultValue)
  //     return
  //   }

  //   if (isTimeFormat) {
  //     const formattedTime = formatTime(parseTime(internalValue))
  //     setInternalValue(formattedTime)
  //     setAmount(formattedTime)
  //   } else {
  //     const numericValue = parseFloat(internalValue)
  //     if (isNaN(numericValue)) {
  //       setInternalValue(formatNumber(1))
  //       setAmount(formatNumber(1))
  //     } else {
  //       const formattedValue = formatNumber(numericValue)
  //       setInternalValue(formattedValue)
  //       setAmount(formattedValue)
  //     }
  //   }
  // }

  const handleBlur = () => {
    if (internalValue === '') {
      const defaultValue = isTimeFormat
        ? isShortTimeFormat
          ? '00:00'
          : '00:00:00'
        : formatNumber(1)

      setInternalValue(defaultValue)
      setAmount(defaultValue)
      return
    }

    if (isTimeFormat) {
      const formattedTime = formatTime(parseTime(internalValue))
      setInternalValue(formattedTime)
      setAmount(formattedTime)
    } else {
      const numericValue = parseFloat(internalValue)
      if (isNaN(numericValue)) {
        setInternalValue(formatNumber(1))
        setAmount(formatNumber(1))
      } else {
        const formattedValue = formatNumber(numericValue)
        setInternalValue(formattedValue)
        setAmount(formattedValue)
      }
    }
  }

  const handleIncrement = () => {
    if (isTimeFormat) {
      const newTime = Math.min(parseTime(internalValue) + 1, 86399) // Máx. 23:59:59
      const formattedTime = formatTime(newTime)
      setInternalValue(formattedTime)
      setAmount(formattedTime)
    } else {
      const numericValue = parseFloat(internalValue)
      const newValue = isNaN(numericValue) ? 1 : Math.max(1, numericValue + 1)
      const formattedValue = formatNumber(newValue)
      setInternalValue(formattedValue)
      setAmount(formattedValue)
    }
  }

  const handleDecrement = () => {
    if (isTimeFormat) {
      const newTime = Math.max(0, parseTime(internalValue) - 1)
      const formattedTime = formatTime(newTime)
      setInternalValue(formattedTime)
      setAmount(formattedTime)
    } else {
      const numericValue = parseFloat(internalValue)
      const newValue = isNaN(numericValue) ? 1 : Math.max(1, numericValue - 1)
      const formattedValue = formatNumber(newValue)
      setInternalValue(formattedValue)
      setAmount(formattedValue)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === '+') {
      e.preventDefault()
      handleIncrement()
    } else if (e.key === 'ArrowDown' || e.key === '-') {
      e.preventDefault()
      handleDecrement()
    } else if (e.key === 'Enter') {
      e.preventDefault()
      if (onEnter) onEnter()
    }
  }

  return (
    <BorderIpass margin={1} status="Normal" styleInner={SelectStyles}>
      <div className="custom-number-input">
        <label
          style={{
            fontSize: '14px',
            marginLeft: '10px',
            marginTop: '10px',
            color: '#DABDF5',
            alignItems: 'center'
          }}
        >
          {label}
        </label>
        <input
          {...props}
          value={internalValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          type="text"
        />
        {dynamicPrefix && <span>{dynamicPrefix}</span>}
        <div className="controls">
          <button onClick={handleIncrement}>▲</button>
          <button onClick={handleDecrement}>▼</button>
        </div>
      </div>
    </BorderIpass>
  )
}

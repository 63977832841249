import moment from 'moment'
import { GeneralData } from '../../types/dashboard'

const capitalice = (text: string) =>
  text.toLowerCase().replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()))

// Función helper para capitalizar la primera letra de un string

// Función que retorna las opciones de la gráfica
export const barChartOptions = (generalData: GeneralData) => {
  // Definimos cada status y sus datos correspondientes
  const statusSeries = [
    {
      name: 'Ingresados',
      data: [generalData.T_In, generalData.RS_In, generalData.E_In]
    },
    {
      name: 'Cerrados',
      data: [
        generalData.RS_Close + generalData.E_Close,
        generalData.RS_Close,
        generalData.E_Close
      ]
    },
    {
      name: 'Abiertos',
      data: [generalData.RS_Open + generalData.E_Open, generalData.RS_Open, generalData.E_Open]
    },
    {
      name: 'En espera',
      data: [generalData.RS_Hold + generalData.E_Hold, generalData.RS_Hold, generalData.E_Hold]
    },
    {
      name: 'Evaluación',
      data: [
        generalData.RS_Evaluate + generalData.E_Evaluate,
        generalData.RS_Evaluate,
        generalData.E_Evaluate
      ]
    },
    {
      name: 'Irrelevante',
      data: [generalData.RS_Spam + generalData.E_Spam, generalData.RS_Spam, generalData.E_Spam]
    }
  ]

  // Mapeamos cada serie para asignarle el gradiente correspondiente
  const series = statusSeries.map((serie, index) => ({
    name: serie.name,
    type: 'bar',
    data: serie.data
  }))

  return {
    title: {
      text: 'Reportes / Emergencias',
      subtext: `Mes en curso ${capitalice(moment().locale('es').format('MMMM'))}`,
      left: '50%',
      textAlign: 'center',
      textStyle: {
        color: '#fff',
        fontSize: 18
      },
      subtextStyle: {
        color: '#ccc',
        fontSize: 14
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      textStyle: { color: '#fff' },
      data: statusSeries.map(serie => serie.name),
      left: 'center',
      bottom: '1%'
    },
    xAxis: {
      type: 'category',
      data: ['Total', 'Reportes', 'Emergencias']
    },
    yAxis: {
      type: 'value'
    },
    series: series
  }
}

export const pieChartOptions = (GeneralData: GeneralData) => ({
  title: {
    text: 'Estado Global',
    subtext: `Mes en curso ${capitalice(moment().locale('es').format('MMMM'))}`,
    left: 'center',
    textStyle: {
      color: '#fff'
    }
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    left: 'center',
    bottom: '1%',
    textStyle: {
      color: `#fff`
    },
    data: ['Cerrados', 'Abiertos', 'Espera', 'Irrelevante', 'Evaluación'] // Deben coincidir con los nombres de los datos en la serie
  },
  series: [
    {
      name: 'Estado',
      type: 'pie',
      radius: '50%',
      label: {
        color: '#fff' // Mover el textStyle a nivel superior
      },
      data: [
        { value: GeneralData.T_Close, name: 'Cerrados' },
        { value: GeneralData.T_Open, name: 'Abiertos' },
        { value: GeneralData.T_Hold, name: 'Espera' },
        { value: GeneralData.T_Spam, name: 'Irrelevante' },
        { value: GeneralData.T_Evaluate, name: 'Evaluación' }
      ]
    }
  ]
})

export const barDistributionExpress = (GeneralData: GeneralData) => {
  const { ExPress_Total, ExPress_Estandar, ExPress_Multe } = GeneralData

  const categories: string[] = ['REPORTES EXPRESS', 'ESTÁNDAR', 'LLAMADO DE ATENCIÓN']

  const colors: string[] = [
    '#a621cf', // Morado claro
    '#c218c5', // Rosa-morado
    '#7a28e0' // Morado oscuro
  ]

  const data = categories.map((name, index) => ({
    name,
    value: [ExPress_Total, ExPress_Estandar, ExPress_Multe][index],
    color: colors[index]
  }))

  return {
    title: {
      text: `REPORTES EXPRESS`,
      subtext: `Mes en curso ${capitalice(moment().locale('es').format('MMMM'))}`,
      left: 'center',
      textStyle: {
        color: '#fff',
        fontSize: 16
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '5%', // Margen izquierdo
      right: '0%', // Margen derecho
      bottom: '10%', // Margen inferior
      top: '25%',
      containLabel: true // Asegura que las etiquetas no se corten
    },
    xAxis: {
      type: 'category',
      data: categories,
      axisLabel: {
        color: '#fff',
        fontSize: 12
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#fff'
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.2)'
        }
      }
    },
    series: [
      {
        type: 'bar',
        data: data.map(item => ({
          name: item.name,
          value: item.value,
          itemStyle: {
            color: item.color
          }
        })),
        label: {
          show: true,
          position: 'top',
          color: '#fff',
          fontWeight: 'bold'
        },
        barWidth: '50%' // Ajuste de tamaño de barra
      }
    ],
    backgroundColor: '#2a1744' // Fondo azul oscuro
  }
}

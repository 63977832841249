import React from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import { useCheckIn } from '../../../Provider/CheckInProvider'
import { entries, entriesApprovedEnum } from '../../../types/entries'
import { typeQrEnumAbbreviationString } from '../../../types/qr'
import { roleEnum } from '../../../types/user'
import { printQRCode } from '../../../utils/printer'
import { transformDate } from '../../../utils/utils'
import PlaceholderSvg2 from '../../PlaceHolderSvg2'
import PlaceholderSvg from '../../PlaceholderSvg'
import { Image } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import './style.scss'
import { BorderIpass } from '../../BorderIpass/Index'

const CheckInDetail = () => {
  const { user } = useAuth()

  const { lastEntry, brands } = useCheckIn()
  const type = lastEntry?.qrCode?.slice(0, 4) as keyof typeof typeQrEnumAbbreviationString
  const values = typeQrEnumAbbreviationString[type]
  const brandPhoto = brands.find(e => e.id === lastEntry?.brandId)?.photo

  const handleIconColor = (value: entriesApprovedEnum) =>
    ({
      [entriesApprovedEnum.APPROVED]: (
        <div className="green circle" style={{}}>
          <p>{values}</p>
        </div>
      ),
      [entriesApprovedEnum.CALLING]: (
        <div className="yellow circle" style={{}}>
          <p>{values}</p>
        </div>
      ),
      [entriesApprovedEnum.WAITING]: (
        <div className="yellow circle" style={{}}>
          <p>{values}</p>
        </div>
      ),
      [entriesApprovedEnum.REJECT]: (
        <div className="red circle" style={{}}>
          <p>{values}</p>
        </div>
      ),
      [entriesApprovedEnum.UNANSWERED]: (
        <div className="red circle" style={{}}>
          <p>{values}</p>
        </div>
      ),
      [entriesApprovedEnum.LEAVE]: (
        <div className="yellow circle" style={{}}>
          <p>{values}</p>
        </div>
      ),
      [entriesApprovedEnum.NO_LEAVE]: (
        <div className="red circle" style={{}}>
          <p>{values}</p>
        </div>
      ),
      [entriesApprovedEnum.RECESSION]: (
        <div className="yellow circle" style={{}}>
          <p>{values}</p>
        </div>
      )
    }[value])

  return (
    <div className="containerCheckInDetail">
      <div className="images-container">
        <div className="single-image">
          {!lastEntry?.photo && <PlaceholderSvg />}
          {lastEntry?.photo && (
            <Image
              preview={{
                mask: (
                  <div>
                    <EyeOutlined style={{ marginRight: '5px' }} />
                    <span>Ver</span>
                  </div>
                )
              }}
              wrapperStyle={{ width: '54%', height: '100%' }}
              src={lastEntry?.photo}
              className="image"
            />
          )}
        </div>

        <div className="single-image">
          {!lastEntry?.DNI_A && <PlaceholderSvg />}
          {lastEntry?.DNI_A && (
            <Image
              preview={{
                mask: (
                  <div>
                    <EyeOutlined style={{ marginRight: '5px' }} />
                    <span>Ver</span>
                  </div>
                )
              }}
              wrapperStyle={{ width: '54%', height: '100%' }}
              src={lastEntry?.DNI_A}
              className="image"
            />
          )}
        </div>
        <div className="single-image">
          {!lastEntry?.DNI_B && <PlaceholderSvg />}
          {lastEntry?.DNI_B && (
            <Image
              preview={{
                mask: (
                  <div>
                    <EyeOutlined style={{ marginRight: '5px' }} />
                    <span>Ver</span>
                  </div>
                )
              }}
              wrapperStyle={{ width: '54%', height: '100%' }}
              src={lastEntry?.DNI_B}
              className="image"
            />
          )}
        </div>
        <div className="single-image">
          {handleIconColor(lastEntry?.approved as entriesApprovedEnum)}
        </div>
      </div>
      <div className="content-section">
        <div className="form-container">
          <div className="input-row">
            <div>
              <label>Nombre 1</label>
              <BorderIpass>
                <input
                  className="input-text"
                  type="text"
                  value={lastEntry?.name?.split(' ')[0] || ''}
                  readOnly
                />
              </BorderIpass>
            </div>
            <div>
              <label>Nombre 2</label>
              <BorderIpass>
                <input
                  type="text"
                  value={lastEntry?.name?.split(' ').slice(1).join(' ') || ''}
                  readOnly
                />
              </BorderIpass>
            </div>

            <div className="small">
              <label>Destino</label>
              <BorderIpass>
                <input type="text" value={lastEntry?.destination || ''} readOnly />
              </BorderIpass>
            </div>
            <div className="small">
              <label>Marca</label>
              <BorderIpass>
                <input type="text" value={lastEntry?.brand || ''} readOnly />
              </BorderIpass>
            </div>
          </div>

          <div className="input-row">
            <div>
              <label>Apellido 1</label>
              <BorderIpass>
                <input type="text" value={lastEntry?.lastName?.split(' ')[0] || ''} readOnly />
              </BorderIpass>
            </div>
            <div>
              <label>Apellido 2</label>
              <BorderIpass>
                <input
                  type="text"
                  value={lastEntry?.lastName?.split(' ').slice(1).join(' ') || ''}
                  readOnly
                />
              </BorderIpass>
            </div>
            <div>
              <label>Fecha check-in</label>
              <BorderIpass>
                <input
                  type="text"
                  value={lastEntry ? transformDate(lastEntry.dateIn as any, 'day') : ''}
                  readOnly
                />
              </BorderIpass>
            </div>
            <div>
              <label>Fecha check-out</label>
              <BorderIpass>
                <input
                  type="text"
                  value={lastEntry ? transformDate(lastEntry.dateOut as any, 'day') : ''}
                  readOnly
                />
              </BorderIpass>
            </div>
          </div>

          <div className="input-row">
            <div>
              <label>DPI/DNI</label>
              <BorderIpass>
                <input type="text" value={lastEntry?.DNI || ''} readOnly />
              </BorderIpass>
            </div>
            <div>
              <label>Origen</label>
              <BorderIpass>
                <input type="text" value={lastEntry?.origin || ''} readOnly />
              </BorderIpass>
            </div>
            <div>
              <label>Hora in</label>
              <BorderIpass>
                <input
                  type="time"
                  value={lastEntry ? transformDate(lastEntry.dateIn as any, 'time') : ''}
                  readOnly
                />
              </BorderIpass>
            </div>
            <div>
              <label>Hora out</label>
              <BorderIpass>
                <input
                  type="time"
                  value={lastEntry ? transformDate(lastEntry.dateOut as any, 'time') : ''}
                  readOnly
                />
              </BorderIpass>
            </div>
          </div>
        </div>
        <div className="small-images-container">
          <div className="single-image-brand">
            {brandPhoto ? <img src={brandPhoto} alt="foto de marca" /> : <PlaceholderSvg />}
          </div>

          {user?.role === roleEnum.RECEPTION && (
            <div
              className="small-image"
              onClick={() =>
                lastEntry?.qrCode
                  ? printQRCode(lastEntry?.qrCode, lastEntry, user.client?.name || '')
                  : printQRCode(
                      (lastEntry?.id as string) || 'prueba',
                      lastEntry as entries,
                      user.client?.name || ''
                    )
              }
            >
              <PlaceholderSvg2 />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CheckInDetail

import React, { FC } from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import './style.scss'
import { BorderIpass } from '../../BorderIpass/Index'

type ValueType = {
  start?: dayjs.Dayjs | null
  end?: dayjs.Dayjs | null
}

const RangePickerLarge: FC<{
  onChange: (value: ValueType) => void
  value: ValueType
  allowSingleDate?: boolean // Permitir una sola fecha
}> = ({ value, onChange, allowSingleDate = false }) => {
  const disabledEndDate = (current: dayjs.Dayjs) => {
    if (!value.start || !current) {
      return false
    }
    return current.isBefore(value.start, 'day')
  }

  const handleChange = (date: dayjs.Dayjs | null, type: 'start' | 'end') => {
    if (allowSingleDate) {
      onChange({ start: date, end: null }) // Solo una fecha
    } else {
      onChange({ ...value, [type]: date }) // Rango de fechas normal
    }
  }

  return (
    <BorderIpass
      style={{
        height: '40px'
      }}
    >
      <div className="date-range-picker-large-container">
        <DatePicker
          className="date-picker"
          popupClassName="date-picker-dropdown"
          placeholder="Seleccione una fecha"
          value={value.start}
          onChange={date => handleChange(date, 'start')}
          style={{ marginRight: '10px' }}
        />
        {!allowSingleDate && ( // Ocultar el segundo DatePicker desde el inicio
          <DatePicker
            className="date-picker"
            popupClassName="date-picker-dropdown"
            placeholder="Seleccione una fecha final"
            value={value.end}
            onChange={date => handleChange(date, 'end')}
            disabledDate={disabledEndDate}
          />
        )}
      </div>
    </BorderIpass>
  )
}

export default RangePickerLarge

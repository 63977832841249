import React, { FC } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { createStaff, updateStaff } from '../../../services/staff'
import { Staff, StaffCreate } from '../../../types/staff'
import DynamicForm from '../../Form/DynamicForm'
import { FormConfig } from './Columns'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { LightTooltip } from '../../tooltips/text'
import './styles.scss'
import {} from '../../../utils/utils'

const FormStaff: FC<{
  onSubmit: () => void
  clientId: string
  initialValue?: Staff
}> = ({ onSubmit, initialValue, clientId }) => {
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (formData: StaffCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        profile: formData.profile,
        ...(formData.photo ? { photo: formData.photo } : {}),
        clientId: clientId
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      if (initialValue) {
        await updateStaff(initialValue.id, data)
      } else {
        await createStaff(data)
      }

      onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="newClientForm">
      <div className="title-staff">
        <div className="left-component">
          <LightTooltip
            title="Pendiente texto"
            placement="right"
            sx={{
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#261a3a',
                color: '#fff',
                fontSize: '14px',
                padding: '10px',
                borderRadius: '8px',
                border: '1px solid  #5a1fd1',
                boxShadow: 'none'
              }
            }}
          >
            <HelpOutlineIcon style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }} />
          </LightTooltip>
        </div>
        <div className="center-component">
          <h3 className={initialValue ? 'title updating' : 'title creating'}>
            {`${initialValue ? 'ACTUALIZAR' : 'NUEVO'} TRABAJADOR`}
          </h3>
        </div>
      </div>
      <div className="container-inputs">
        <DynamicForm
          initialValues={initialValue}
          formConfig={FormConfig}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  )
}

export default FormStaff

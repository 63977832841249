// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsResident: TableColumn[] = [
  {
    title: 'Código Destino',
    dataIndex: 'destinationCode',
    key: 'destinationCode',
    type: enumTypeColumn.string
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'Teléfono 1',
    dataIndex: 'phone1',
    key: 'phone1',
    type: enumTypeColumn.string
  }
]

export const residentFormConfig: FormField[] = [
  {
    type: FieldTypeEnum.text,
    label: 'Código Destino ',
    name: 'destinationCode',
    required: true
  },
  { type: FieldTypeEnum.text, label: 'Primer Nombre ', name: 'name', required: true },
  { type: FieldTypeEnum.text, label: 'Apellido ', name: 'lastName', required: true },
  { type: FieldTypeEnum.text, label: 'Correo Electrónico', name: 'email' },
  { type: FieldTypeEnum.phone, label: 'Teléfono 1 ', name: 'phone1', required: true },
  { type: FieldTypeEnum.phone, label: 'Teléfono 2', name: 'phone2' },
  {
    type: FieldTypeEnum.number,
    label: 'Delegados',
    name: 'delegatedQuantity',
    defaultValue: 0
  }
]

import React, { FC, useState } from 'react'
import { ButtonB } from '../../components/Form/ButtonB'
import Modal from '../GeneralComponents/Modal'
import './styles.scss'

interface ButtonInfo {
  text: string
  component: React.ReactNode
  styleContainer?: React.CSSProperties | undefined
}

interface ButtonSectionProps {
  buttonsInfo: ButtonInfo[]
}

const ButtonSection: FC<ButtonSectionProps> = ({ buttonsInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeComponent, setActiveComponent] = useState<React.ReactNode | null>(null)
  const [styleContainer, SetStyleContainer] = useState<React.CSSProperties | undefined>(
    undefined
  )

  const openModal = (
    component: React.ReactNode,
    styleContainer: React.CSSProperties | undefined
  ) => {
    setActiveComponent(component)
    setIsModalOpen(true)
    SetStyleContainer(styleContainer)
  }

  const closeModal = () => {
    setActiveComponent(null)
    setIsModalOpen(false)
  }

  return (
    <div className="button-section">
      {buttonsInfo.map((button, index) => (
        <ButtonB
          key={index}
          text={button.text}
          onClick={() => openModal(button.component, button.styleContainer)}
        />
      ))}

      <Modal isOpen={isModalOpen} onClose={closeModal} styleContainer={styleContainer}>
        {activeComponent}
      </Modal>
    </div>
  )
}

export default ButtonSection

// DynamicForm.tsx

import React, { useState, useEffect } from 'react'
import InputDark from '../InputDark'
import Select from '../Select'
import ImageUpload from '../ImageUpload'
import './styles.scss'
import Switch from '../Switch'
import PhoneInput from '../PhoneInput'
import { useAuth } from '../../../Provider/AuthProvider'
import { ButtonB } from '../ButtonB'
import Button from '../Button'
export enum FieldTypeEnum {
  'text' = 'text',
  'number' = 'number',
  'phone' = 'phone',
  'select' = 'select',
  'image' = 'image',
  'boolean' = 'boolean',
  'FileMaster' = 'FileMaster'
}

export interface FormField {
  name: string
  label: string
  type: FieldTypeEnum
  options?: SelectOption[]
  required?: boolean
  defaultValue?: string | number | boolean
}

interface SelectOption {
  value: string
  label: string
}

interface DynamicFormProps {
  containtFileMaster?: boolean
  formConfig: FormField[]
  onSubmit: (formData: any) => void
  initialValues?: Record<string, any>
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  formConfig,
  onSubmit,
  initialValues,
  containtFileMaster
}) => {
  const [formData, setFormData] = useState<Record<string, any>>(initialValues ?? {})
  const [errors, setErrors] = useState<Record<string, string>>({})
  const { user } = useAuth()

  const handleChange = (name: string, value: string | boolean) => {
    setFormData((prevData: any) => ({ ...prevData, [name]: value }))

    // Si el campo tiene error y el usuario lo corrige, eliminamos el error
    if (errors[name]) {
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors }
        delete newErrors[name]
        return newErrors
      })
    }
  }

  let FileMaster: string = ''

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues)
    } else {
      const defaultValues = formConfig.reduce((acc, field) => {
        acc[field.name] = field.defaultValue ?? undefined
        return acc
      }, {} as Record<string, any>)

      setFormData(defaultValues)
    }

    if (
      containtFileMaster &&
      user?.client?.accessConfigs &&
      user.client.accessConfigs.length > 0 &&
      user.client.accessConfigs[user.client.accessConfigs.length - 1]?.FileMaster
    ) {
      const fileMasterValue =
        user.client.accessConfigs[user.client.accessConfigs.length - 1].FileMaster
      setFormData(prevData => ({ ...prevData, FileMaster: fileMasterValue }))
    }
  }, [initialValues, containtFileMaster, user])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (!formData) {
      console.error('Error: formData es undefined')
      return
    }

    const newErrors: Record<string, string> = {}

    // formConfig.forEach(field => {
    //   if (!formData[field.name] || formData[field.name].toString().trim() === '') {
    //     newErrors[field.name] = 'Este campo es requerido'
    //   }
    // })

    formConfig.forEach(field => {
      const value = formData[field.name]?.toString().trim() || ''

      // Solo validar si el campo es obligatorio
      if (field.required && value === '') {
        newErrors[field.name] = 'Este campo es obligatorio'
      }
    })

    setErrors(newErrors)

    if (Object.keys(newErrors).length > 0) {
      return
    }

    onSubmit(formData)
  }

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="elementsContainer">
        {formConfig.map(field => (
          <div className={`formElement ${field.type}`} key={field.name}>
            {
              {
                [FieldTypeEnum.text]: (
                  <>
                    <InputDark
                      placeholder={field.label}
                      key={field.name}
                      label={field.label}
                      value={formData?.[field.name] ?? ''}
                      onChange={value => handleChange(field.name, value.target.value)}
                    />
                    {errors[field.name] && (
                      <p className="error-message">{errors[field.name]}</p>
                    )}
                  </>
                ),
                [FieldTypeEnum.FileMaster]: (
                  <InputDark
                    placeholder={field.label}
                    key={field.name}
                    label={field.label}
                    value={formData?.[field.name] ?? FileMaster}
                    onChange={value => handleChange(field.name, value.target.value)}
                  />
                ),
                [FieldTypeEnum.number]: (
                  <>
                    <InputDark
                      placeholder={field.label}
                      key={field.name}
                      type="number"
                      label={field.label}
                      value={formData?.[field.name] ?? ''}
                      onChange={value => handleChange(field.name, value.target.value)}
                    />
                    {errors[field.name] && (
                      <p className="error-message">{errors[field.name]}</p>
                    )}
                  </>
                ),
                [FieldTypeEnum.phone]: (
                  <>
                    <PhoneInput
                      placeholder={field.label}
                      key={field.name}
                      label={field.label}
                      value={formData?.[field.name] ?? ''}
                      onChange={value => handleChange(field.name, value)}
                    />
                    {errors[field.name] && (
                      <p className="error-message">{errors[field.name]}</p>
                    )}
                  </>
                ),
                [FieldTypeEnum.select]: (
                  <>
                    <Select
                      key={field.name}
                      label={field.label}
                      options={field.options || []}
                      value={formData?.[field.name] ?? ''}
                      onChange={value => handleChange(field.name, value)}
                    />
                    {errors[field.name] && (
                      <p className="error-message">{errors[field.name]}</p>
                    )}
                  </>
                ),
                [FieldTypeEnum.image]: (
                  <ImageUpload
                    label={field.label}
                    key={field.name}
                    value={formData?.[field.name] ?? ''}
                    onChange={(value: any) => handleChange(field.name, value)}
                    errorMessage={errors[field.name]}
                  />
                ),
                [FieldTypeEnum.boolean]: (
                  <Switch
                    label={field.label}
                    key={field.name}
                    value={formData?.[field.name] ?? false}
                    onChange={(value: boolean) => handleChange(field.name, value)}
                  />
                )
              }[field.type]
            }
          </div>
        ))}
      </div>
      <Button
        className="button"
        text={initialValues ? 'Actualizar' : `Enviar`}
        style={{ marginTop: '10px' }}
        type="submit"
      />
    </form>
  )
}

export default DynamicForm

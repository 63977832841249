import React, { useState, useEffect } from 'react'
import './style.scss'
import { IconButton, Tooltip } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { TitlePages } from '../../components/TitlePages'
import { Add, ExpandLess, ExpandMore, Visibility, ContentCopy } from '@mui/icons-material'
import Modal from '../../components/GeneralComponents/Modal'
import Switch from '../../components/Form/Switch'
import { SetSegment } from './SetSegments'
import { SetLocation } from './SetLocation'
import { deleteSegments, listSegments, updatePatchSegments } from '../../services/Segments'
import {
  createCopyLocations,
  deleteLocations,
  updatePatchLocations
} from '../../services/Locations'
import { Segments } from '../../types/Segments'
import { Pagination } from '../../types/types'
import { useLoading } from '../../Provider/LoadingProvider'
import { useDebounce } from '../../hooks/useDebounce'
import DeleteButton from '../../components/Table/components/DeleteButton'
import { Locations } from '../../types/Locations'
import moment from 'moment'

const LocationsReserves = () => {
  const [selectModal, setSelectModal] = useState<React.ReactNode>()
  const [selectModalLocation, setSelectModalLocation] = useState<React.ReactNode>()
  const [pendingState, setPendingState] = useState<{ id: string; newState: boolean } | null>(
    null
  )
  const [pendingLocationState, setPendingLocationState] = useState<{
    id: string
    newState: boolean
  } | null>(null)

  const debouncedState = useDebounce(pendingState, 1000)
  const debouncedLocationState = useDebounce(pendingLocationState, 1000)

  const [data, setData] = useState<Pagination<Segments>>({
    total: 1,
    data: [],
    currentPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false
  })

  const [selected, setSelected] = useState<Segments | undefined>(undefined)
  const [selectedLocation, setSelectedLocation] = useState<Locations | undefined>(undefined)

  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>({})
  const [switchLocationStates, setSwitchLocationStates] = useState<{
    [key: string]: boolean
  }>({})
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 6
  const total = data.total

  const onPageChange = (newPage: number) => {
    getDataSegments('', newPage)
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= Math.ceil(total / itemsPerPage)) {
      setCurrentPage(page)
      onPageChange(page)
    }
  }

  const handlePrevPage = () => {
    goToPage(currentPage - 1)
  }

  const handleNextPage = () => {
    goToPage(currentPage + 1)
  }

  const closeModal = () => {
    setSelected(undefined)
    setSelectModal(undefined)
  }
  const CloseModalLocation = () => {
    setSelectedLocation(undefined)
    setSelectModalLocation(undefined)
  }

  useEffect(() => {
    const updateState = async () => {
      if (debouncedState !== null) {
        try {
          await updatePatchSegments(debouncedState.id, debouncedState.newState)
          setSwitchStates(prev => ({
            ...prev,
            [debouncedState.id]: debouncedState.newState
          }))
        } catch (error) {
          setSwitchStates(prev => ({
            ...prev,
            [debouncedState.id]: !debouncedState.newState
          }))
        }
      }
    }

    updateState()
  }, [debouncedState])

  useEffect(() => {
    const updateState = async () => {
      if (debouncedLocationState !== null) {
        try {
          await updatePatchLocations(
            debouncedLocationState.id,
            debouncedLocationState.newState
          )
          setSwitchLocationStates(prev => ({
            ...prev,
            [debouncedLocationState.id]: debouncedLocationState.newState
          }))
        } catch (error) {
          setSwitchLocationStates(prev => ({
            ...prev,
            [debouncedLocationState.id]: !debouncedLocationState.newState
          }))
        }
      }
    }

    updateState()
  }, [debouncedLocationState])

  const getDataSegments = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const segments = await listSegments({
        // id,
        params: { page, search: searchValue, limit: itemsPerPage }
      })

      setData(segments)

      const initialSwitchStates: { [key: string]: boolean } = {}
      segments.data.forEach(item => {
        initialSwitchStates[item.id] = !!item.enable
      })
      setSwitchStates(initialSwitchStates)

      const initialLocationSwitchStates: { [key: string]: boolean } = {}
      segments.data.forEach(segment => {
        segment.Locations?.forEach(loc => {
          initialLocationSwitchStates[loc.id] = !!loc.enable
        })
      })
      setSwitchLocationStates(initialLocationSwitchStates)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  useEffect(() => {
    getDataSegments('', 1)
  }, [])

  const handleView = (item: Segments) => {
    setSelected(item)
  }

  const handleViewLocation = (location: Locations) => {
    const formattedLocation = {
      ...location,
      startTime: moment(location.startTime, 'HH:mm:ss').format('HH:mm'),
      endTime: moment(location.endTime, 'HH:mm:ss').format('HH:mm')
    }

    setSelectedLocation(formattedLocation)
  }

  useEffect(() => {
    if (selected) {
      setSelectModal(
        <Modal
          isOpen={true}
          borderColor={true}
          onClose={closeModal}
          style_Subcontainer={{ padding: '1rem' }}
          styleContainerContent={{ padding: '2px', margin: '1px' }}
          styleContainer={{
            width: '420px',
            height: '400px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: '20px', top: '30px' }}
        >
          <SetSegment
            title="CREAR SEGMENTOS"
            onClose={closeModal}
            initialValue={selected}
            onSuccess={() => getDataSegments(search, currentPage)}
          />
        </Modal>
      )
    }
  }, [selected])

  useEffect(() => {
    if (selectedLocation) {
      setSelectModalLocation(
        <Modal
          isOpen={true}
          borderColor={true}
          onClose={CloseModalLocation}
          style_Subcontainer={{ padding: '1rem' }}
          styleContainerContent={{ padding: '2px', margin: '1px' }}
          styleContainer={{
            width: '400px',
            height: '550px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: '20px', top: '30px' }}
        >
          <SetLocation
            title="CREAR LOCACIONES"
            onClose={CloseModalLocation}
            initialValueLocation={selectedLocation}
            onSuccess={() => getDataSegments(search, currentPage)}
            segmentId={selectedLocation.segmentId}
          />
        </Modal>
      )
    }
  }, [selectedLocation])

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteSegments(id)
      getDataSegments(search, data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteLocation = async (id: string) => {
    try {
      startLoading()
      await deleteLocations(id)
      getDataSegments(search, data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }

  const handleToggle = (id: string) => {
    setSwitchStates(prev => {
      const newState = !prev[id]
      setPendingState({ id, newState })
      return {
        ...prev,
        [id]: newState
      }
    })
  }

  const handleCopy = async (id: string) => {
    try {
      startLoading()
      await createCopyLocations(id)

      await getDataSegments(search, data.currentPage)
    } catch (error) {
      console.error('Error al copiar la locacion:', error)
    } finally {
      stopLoading()
    }
  }

  const handleToggleLocation = (id: string) => {
    setSwitchLocationStates(prev => {
      const newState = !prev[id]
      setPendingLocationState({ id, newState })
      return {
        ...prev,
        [id]: newState
      }
    })
  }

  const [expandedSalones, setExpandedSalones] = useState<{ [key: string]: boolean }>({})

  const toggleSalonExpand = (id: string) => {
    setExpandedSalones(prev => ({ ...prev, [id]: !prev[id] }))
  }

  const openModal = (select: 'Segment', data?: any): void => {
    if (select === 'Segment') {
      setSelected(undefined)
      setSelectModal(
        <Modal
          isOpen={true}
          onClose={closeModal}
          borderColor={true}
          style_Subcontainer={{ padding: '1rem' }}
          styleContainerContent={{ padding: '2px', margin: '0px' }}
          styleContainer={{
            width: '410px',
            height: '400px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: ' 20px', top: '30px' }}
        >
          <SetSegment
            title="CREAR SEGMENTOS"
            onClose={closeModal}
            initialValue={selected}
            onSuccess={() => getDataSegments(search, currentPage)}
          />
        </Modal>
      )
    }
  }
  const openModalLocation = (select: 'SetLocation', segmentId: string): void => {
    if (select === 'SetLocation') {
      setSelectedLocation(undefined)
      setSelectModalLocation(
        <Modal
          isOpen={true}
          onClose={CloseModalLocation}
          borderColor={true}
          style_Subcontainer={{ padding: '1rem' }}
          styleContainerContent={{ padding: '2px', margin: '0px' }}
          styleContainer={{
            width: '400px',
            height: '560px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: ' 20px', top: '32px' }}
        >
          <SetLocation
            title="CREAR LOCACIONES"
            onClose={CloseModalLocation}
            initialValueLocation={selectedLocation}
            onSuccess={() => getDataSegments(search, currentPage)}
            segmentId={segmentId}
          />
        </Modal>
      )
    }
  }

  const formatTimeToAMPM = (time: string) => {
    if (!time) return 'N/A'

    const [hour, minute] = time.split(':').map(Number)
    if (isNaN(hour) || isNaN(minute)) return 'N/A'

    const suffix = hour >= 12 ? 'PM' : 'AM'
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12 // Convertir 0 y 12 a 12
    return `${formattedHour}:${String(minute).padStart(2, '0')} ${suffix}`
  }

  return (
    <div className="SegmentsContainer">
      <TitlePages
        title="SEGMENTOS & LOCACIONES"
        tooltip="Dentro de cada segmento, encontrarás las locaciones asociadas, podrás editar o crear nuevas"
      />
      <div className="ContainerSegment">
        <p className="title">SEGMENTOS</p>
        <p>LOCS</p>
        <Tooltip
          title="Crear segmento"
          placement="right-start"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#261a3a', // Color de fondo
                color: 'white', // Color del texto
                fontSize: '14px', // Tamaño del texto
                padding: '8px 12px', // Espaciado interno
                borderRadius: '8px', // Bordes redondeados
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
              }
            }
          }}
        >
          <IconButton onClick={() => openModal('Segment', undefined)}>
            <Add />
          </IconButton>
        </Tooltip>
      </div>

      {selectModal}

      {data?.data?.map(item => (
        <div key={item.id} className="containerSubSegment">
          <div className="header-info">
            <IconButton className="expand" onClick={() => toggleSalonExpand(item.id)}>
              {expandedSalones[item.id] ? (
                <ExpandLess style={{ color: 'white' }} />
              ) : (
                <ExpandMore style={{ color: 'white' }} />
              )}
            </IconButton>

            <p className="title">{item.name}</p>

            <div className="icons">
              <p>{item.Locations?.length}</p>
              <Tooltip
                title="Actualizar"
                placement="top-end"
                slotProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#261a3a', // Color de fondo
                      color: 'white', // Color del texto
                      fontSize: '14px', // Tamaño del texto
                      padding: '8px 12px', // Espaciado interno
                      borderRadius: '8px', // Bordes redondeados
                      boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                    }
                  }
                }}
              >
                <IconButton onClick={() => handleView(item)}>
                  <Visibility style={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Eliminar"
                placement="top-end"
                slotProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#261a3a', // Color de fondo
                      color: 'white', // Color del texto
                      fontSize: '14px', // Tamaño del texto
                      padding: '8px 12px', // Espaciado interno
                      borderRadius: '8px', // Bordes redondeados
                      boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                    }
                  }
                }}
              >
                <IconButton style={{ padding: '0px', margin: '0px' }}>
                  <DeleteButton OnDelete={() => handleDelete(item.id)} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Desactivar / Activar"
                placement="top-end"
                slotProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#261a3a', // Color de fondo
                      color: 'white', // Color del texto
                      fontSize: '14px', // Tamaño del texto
                      padding: '8px 12px', // Espaciado interno
                      borderRadius: '8px', // Bordes redondeados
                      boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                    }
                  }
                }}
              >
                <IconButton>
                  <Switch
                    activeColor={'#5a1fd1'}
                    ColorDiscative={'#6d6697'}
                    value={switchStates[item.id] || false}
                    onChange={() => handleToggle(item.id)}
                    PopUp={{
                      title: 'ADVERTENCIA',
                      description: `Estás a punto de ${
                        switchStates[item.id] ? 'desactivar' : 'activar'
                      } la locacion ${item.name}.`,
                      acceptText: 'CONTINUAR',
                      rejectText: 'CANCELAR'
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          {expandedSalones[item.id] && (
            <div className="expanded-content">
              <div className="subtitle-container">
                <div className="header-icons">
                  <div className="subtitle-header">
                    <p>LOCACIONES</p>
                    <p>DESDE</p>
                    <p>HASTA</p>
                    <p>MAXT</p>
                    <p>MAXP</p>
                    <p>PRECIO</p>
                    <Tooltip
                      title="Crear locacion"
                      placement="right-start"
                      slotProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: '#261a3a', // Color de fondo
                            color: 'white', // Color del texto
                            fontSize: '14px', // Tamaño del texto
                            padding: '8px 12px', // Espaciado interno
                            borderRadius: '8px', // Bordes redondeados
                            boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                          }
                        }
                      }}
                    >
                      <IconButton
                        className="add-button"
                        style={{ color: 'white' }}
                        onClick={() => openModalLocation('SetLocation', item.id)}
                      >
                        <Add />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {selectModalLocation}

              {/* Lista de Locaciones */}
              {item.Locations?.map((loc, index) => (
                <div key={loc.id} className="name-result">
                  <div className="name-result-header">
                    <span>{loc.name}</span>
                    <span>{formatTimeToAMPM(loc.startTime ?? 'N/A')}</span>
                    <span>{formatTimeToAMPM(loc.endTime ?? 'N/A')}</span>
                    <span>{`${loc.maxDuration ?? 'N/A'} HRS`}</span>
                    <span>{`${loc.maxPeople ?? 'N/A'} PRS`}</span>
                    <span>{`Q ${(loc.price ?? 'N/A').toFixed(2)}`}</span>
                    <div className="icon-button">
                      <Tooltip
                        title="Actualizar"
                        placement="top-end"
                        slotProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#261a3a', // Color de fondo
                              color: 'white', // Color del texto
                              fontSize: '14px', // Tamaño del texto
                              padding: '8px 12px', // Espaciado interno
                              borderRadius: '8px', // Bordes redondeados
                              boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                            }
                          }
                        }}
                      >
                        <IconButton onClick={() => handleViewLocation(loc)}>
                          <Visibility style={{ color: 'white' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Desactivar / Activar"
                        placement="top-end"
                        slotProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#261a3a', // Color de fondo
                              color: 'white', // Color del texto
                              fontSize: '14px', // Tamaño del texto
                              padding: '8px 12px', // Espaciado interno
                              borderRadius: '8px', // Bordes redondeados
                              boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                            }
                          }
                        }}
                      >
                        <IconButton>
                          <Switch
                            activeColor={'#00ff08'}
                            ColorDiscative={'#a0d6a0'}
                            value={switchLocationStates[loc.id] || false}
                            onChange={() => handleToggleLocation(loc.id)}
                            PopUp={{
                              title: 'ADVERTENCIA',
                              description: `Estás a punto de ${
                                switchLocationStates[loc.id] ? 'desactivar' : 'activar'
                              } la locacion ${loc.name}.`,
                              acceptText: 'CONTINUAR',
                              rejectText: 'CANCELAR'
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Copiar"
                        placement="top-end"
                        slotProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#261a3a', // Color de fondo
                              color: 'white', // Color del texto
                              fontSize: '14px', // Tamaño del texto
                              padding: '8px 12px', // Espaciado interno
                              borderRadius: '8px', // Bordes redondeados
                              boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                            }
                          }
                        }}
                      >
                        <IconButton>
                          <ContentCopy
                            style={{ color: 'white' }}
                            onClick={() => handleCopy(loc.id)}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Eliminar"
                        placement="top-end"
                        slotProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#261a3a', // Color de fondo
                              color: 'white', // Color del texto
                              fontSize: '14px', // Tamaño del texto
                              padding: '8px 12px', // Espaciado interno
                              borderRadius: '8px', // Bordes redondeados
                              boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                            }
                          }
                        }}
                      >
                        <IconButton>
                          <DeleteButton OnDelete={() => handleDeleteLocation(loc.id)} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <div className="pagination">
        <IconButton onClick={handlePrevPage} disabled={!data.hasPrevPage}>
          <KeyboardArrowLeft style={{ color: data.hasPrevPage ? 'white' : 'gray' }} />
        </IconButton>
        <span className="page-number">{data.currentPage}</span>
        <IconButton onClick={handleNextPage} disabled={!data.hasNextPage}>
          <KeyboardArrowRight style={{ color: data.hasNextPage ? 'white' : 'gray' }} />
        </IconButton>
      </div>
    </div>
  )
}

export default LocationsReserves

import axiosInstance from './axiosInstance'
import { ConciliationCreate } from '../types/conciliations'

export const createConciliation = async (params: FormData): Promise<ConciliationCreate> => {
  try {
    const response = await axiosInstance.post(`/api/conciliation`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en conciliation :', error)
    throw error
  }
}

export const getConciliations = async (data: {
  date: string[]
  type: 'Conciliaciones' | 'Cierre de Mes'
}): Promise<ConciliationCreate> => {
  try {
    const response = await axiosInstance.get(`/api/conciliation`, {
      params: { ...data }
    })
    return response.data
  } catch (error) {
    console.error('Error al obtener conciliations:', error)
    throw error
  }
}

import React, { useEffect, useState, useMemo } from 'react'
/* import { useLoading } from '../../Provider/LoadingProvider' */
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import { useLoading } from '../../Provider/LoadingProvider'

import Button from '../../components/Form/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { savePaymentDate } from '../../services/manageress'
import { PaymentDateType } from '../../types/client'
import Switch from '../../components/Form/Switch'
import { Fade } from '@mui/material'
import { LightTooltip } from '../../components/tooltips/text'
import { Toaster, toast } from 'sonner'
import moment from 'moment'
import 'moment/locale/es'
import Modal from '../../components/GeneralComponents/Modal'
import { Warning } from './Warnig'

function arePaymentDatesDifferent(obj1: PaymentDateType, obj2: PaymentDateType): boolean {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Si tienen diferente cantidad de claves, son diferentes
  if (keys1.length !== keys2.length) {
    return true
  }

  // Comparar los valores de cada clave
  for (const key of keys1) {
    const monthKey = parseInt(key, 10)
    const val1 = obj1[monthKey]
    const val2 = obj2[monthKey]

    // Si alguno de los objetos no tiene esta clave, son diferentes
    if (!val2) {
      return true
    }

    // Comparar las propiedades internas
    if (val1.courtDate !== val2.courtDate || val1.Deadline !== val2.Deadline) {
      return true
    }
  }

  return false // Si todo coincide, son iguales
}

const Component: React.FC = () => {
  /*   const { startLoading, stopLoading } = useLoading()*/

  const { user, initial } = useAuth()
  const { startLoading, stopLoading } = useLoading()

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]
  const days = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']

  const [selectedDates, setSelectedDates] = useState<PaymentDateType>({})
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [valueSelect, setValueSelect] = useState<boolean>(false)
  const [valueConfirm, setvalueConfirm] = useState<{
    month: number
    day: number
    lastDate: string
  }>()
  const year = new Date().getFullYear()

  const paymentDate: PaymentDateType | undefined = useMemo(() => {
    if (!user?.client?.paymentDate) return {}

    try {
      return user.client.paymentDate
    } catch (error) {
      console.error('Invalid date:', user.client.paymentDate)
      return {}
    }
  }, [user])

  const getFirstWeekday = (month: number) => {
    let day = 1
    while (true) {
      const date = new Date(year, month, day)
      const dayOfWeek = date.getDay()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        return day
      }
      day++
    }
  }

  useEffect(() => {
    // Crear una copia del objeto paymentDate
    const initialSelectedDates: PaymentDateType = { ...paymentDate }

    for (let i = 0; i < months.length; i++) {
      const monthIndex = i + 1

      // Asegurarse de que el objeto para el mes existe
      if (!initialSelectedDates[monthIndex]) {
        initialSelectedDates[monthIndex] = { courtDate: 0, Deadline: 0 }
      }

      // Asignar valores si están vacíos
      if (!initialSelectedDates[monthIndex].courtDate) {
        initialSelectedDates[monthIndex].courtDate = getFirstWeekday(i)
      }

      if (!initialSelectedDates[monthIndex].Deadline) {
        initialSelectedDates[monthIndex].Deadline = getFirstWeekday(i)
      }
    }

    // Establecer las fechas seleccionadas en el estado
    setSelectedDates(initialSelectedDates)
  }, [user, months.length, paymentDate])

  useEffect(() => {
    const hasChanges = arePaymentDatesDifferent(paymentDate, selectedDates)

    setHasChanges(hasChanges)
  }, [selectedDates, paymentDate])

  const handleDateClick = (month: number, day: number, jump?: boolean) => {
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() + 1 // Mes actual (1-12)
    const currentDay = currentDate.getDate() // Día actual

    setSelectedDates(prevSelectedDates => {
      const currentMonthDates = prevSelectedDates[month] || {}
      const { courtDate, Deadline } = currentMonthDates
      const lastcourtDate = paymentDate[month]?.courtDate

      if (jump) {
        closeModal()
        return {
          ...prevSelectedDates,
          [month]: {
            ...currentMonthDates,
            ...(valueSelect ? { Deadline: day } : { courtDate: day })
          }
        }
      }

      // Validación: No se puede modificar courtDate si ya pasó en el mes actual
      if (!valueSelect && month === currentMonth && lastcourtDate && day > currentDay) {
        const lastDate = moment(lastcourtDate, 'D').locale('es').format('DD MMMM YYYY')

        setvalueConfirm({ month, day, lastDate })
        return prevSelectedDates // No actualizamos las fechas
      }

      // Validación: courtDate no puede ser mayor que Deadline
      if (valueSelect && courtDate && courtDate > day) {
        toast.warning('La fecha límite', {
          description: `No puede ser menor que la fecha de corte.`
        })
        return prevSelectedDates // No actualizamos las fechas
      }

      // Validación: Deadline no puede ser menor que courtDate
      if (!valueSelect && Deadline && day > Deadline) {
        toast.warning('La fecha de corte', {
          description: `No puede ser mayor que la fecha límite.`
        })
        return prevSelectedDates // No actualizamos las fechas
      }

      return {
        ...prevSelectedDates,
        [month]: {
          ...currentMonthDates,
          ...(valueSelect ? { Deadline: day } : { courtDate: day })
        }
      }
    })
  }

  const handleSaveClick = async () => {
    if (hasChanges) {
      try {
        startLoading()
        await savePaymentDate(selectedDates)
        initial()
      } catch (error) {
        console.error(error)
      } finally {
        stopLoading()
      }
    }
  }

  const closeModal = () => {
    setvalueConfirm(undefined)
  }

  return (
    <div>
      <div className="title">
        <h4>Fechas de pagos</h4>
        <p></p>
      </div>

      <div className="containerButtons">
        <Button
          text="Guardar"
          styleType={hasChanges ? `normal` : `disable`}
          icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCloud} />}
          onClick={handleSaveClick}
        />
        <div className="SelectValue">
          <LightTooltip
            title={
              'Fecha en que todos los cobros de tipo cuotas aplicadas a los clientes se les carga a sus facturas.'
            }
            followCursor
            TransitionComponent={Fade}
          >
            <p>Fecha Corte</p>
          </LightTooltip>
          <Switch
            activeColor={'#ff0000'}
            ColorDiscative={'#ff65c3'}
            value={valueSelect}
            onChange={() => {
              setValueSelect(!valueSelect)
            }}
          />
          <LightTooltip
            title={'Fecha limite para realizar pagos\n luego de esta fecha entran en mora.'}
            followCursor
            TransitionComponent={Fade}
          >
            <p>Fecha Limite</p>
          </LightTooltip>
        </div>
      </div>

      <div className="gridContainer">
        {months.map((month, index) => (
          <div key={index} className="calendarMonth">
            <div
              className={index === new Date().getMonth() ? 'monthHeaderSelect' : `monthHeader`}
            >
              {month}
            </div>
            <div className="DaysCalendar">
              {days.map(dia => (
                <div key={dia} className="DayCalendar">
                  {dia}
                </div>
              ))}
            </div>
            <div className="daysGrid">
              {Array.from({ length: 42 }, (_, i) => {
                const day = i - (new Date(year, index, 1).getDay() - 1)
                const isCurrentMonth = day > 0 && day <= new Date(year, index + 1, 0).getDate()
                const isSelectedDeadline = selectedDates[index + 1]?.Deadline === day
                const isSelectedcourtDate = selectedDates[index + 1]?.courtDate === day
                const colorSelect =
                  isSelectedDeadline && isSelectedcourtDate
                    ? 'mix'
                    : isSelectedDeadline
                    ? 'limit'
                    : isSelectedcourtDate
                    ? 'Court'
                    : undefined

                return (
                  <button
                    key={i}
                    className={`
                      dayButton 
                      ${!isCurrentMonth ? 'inactive' : ''} 
                      ${colorSelect ? `${colorSelect} selected` : ''}
                    `}
                    onClick={() => handleDateClick(index + 1, day)}
                    disabled={!isCurrentMonth}
                  >
                    {isCurrentMonth ? day : ''}
                  </button>
                )
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="secondContainerButtons">
        <Button
          text="Guardar"
          styleType={hasChanges ? `normal` : `disable`}
          icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCloud} />}
          onClick={handleSaveClick}
        />
      </div>

      {valueConfirm && (
        <Modal
          isOpen={valueConfirm ? true : false}
          borderColor={true}
          onClose={closeModal}
          styleContainerContent={{ padding: '5px', margin: '0px' }}
          styleContainer={{
            width: '360px',
            height: '180px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
        >
          <Warning
            onClose={closeModal}
            onSend={() => handleDateClick(valueConfirm.month, valueConfirm.day, true)}
            lastDate={valueConfirm?.lastDate}
          />
        </Modal>
      )}
      <Toaster richColors closeButton position="top-right" />
    </div>
  )
}

export default Component

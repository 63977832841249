import React, { useState, useEffect } from 'react'
import './style.scss'
import { useLoading } from '../../Provider/LoadingProvider'
import { InputNumber } from '../../components/Form/inputNumber'
import { Locations, LocationsCreate } from '../../types/Locations'
import { createLocations, updateLocations } from '../../services/Locations'
import { LabelANTDLarge } from '../../components/Form/labels/LabelLarge'
import { ButtonB } from '../../components/Form/ButtonB'
import { LightTooltip } from '../../components/tooltips/text'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
export interface ReportModalProps {
  title: string
  onClose: () => void
  initialValueLocation?: Locations
  onSuccess?: () => void
  segmentId: string
}

export const SetLocation: React.FC<ReportModalProps> = ({
  title,
  onClose,
  onSuccess,
  segmentId,
  initialValueLocation
}) => {
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      segmentId: segmentId
    }))
  }, [segmentId])

  const { startLoading, stopLoading } = useLoading()

  const [formData, setFormData] = useState<LocationsCreate>(
    initialValueLocation || {
      name: '',
      abbrev: '',
      startTime: '09:00',
      endTime: '22:00',
      maxDuration: 0,
      maxPeople: 0,
      price: 0,
      segmentId: ''
    }
  )

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleChange = <K extends keyof Omit<typeof formData, 'errors'>>(params: {
    name: K
    value: (typeof formData)[K]
  }) => {
    setFormData(prev => ({
      ...prev,
      [params.name]: params.value
    }))
  }

  const handleSubmit = async () => {
    try {
      startLoading()
      const formDataSend = new FormData()
      const formatToHHmmss = (time: string) => `${time}:00`
      formDataSend.append('name', formData.name.trim())
      formDataSend.append('abbrev', formData.abbrev.trim())
      formDataSend.append('startTime', formatToHHmmss(formData.startTime))
      formDataSend.append('endTime', formatToHHmmss(formData.endTime))

      formDataSend.append('maxDuration', formData.maxDuration.toString().trim())
      formDataSend.append('maxPeople', formData.maxPeople.toString().trim())
      formDataSend.append('price', formData.price.toString().trim())

      formDataSend.append('segmentId', formData.segmentId)

      if (initialValueLocation) {
        await updateLocations(initialValueLocation.id, formDataSend)
      } else {
        await createLocations(formDataSend)
      }

      if (onSuccess) {
        onSuccess()
      }

      if (onClose) {
        onClose()
      }
    } catch (error) {
      setErrorMessage('Ocurrió un error al guardar los datos.')
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="deleteConceptContainerLocation">
      <div className="title-location">
        <div className="left-component">
          <LightTooltip
            title="Pendiente texto"
            placement="right"
            sx={{
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#261a3a',
                color: '#fff',
                fontSize: '14px',
                padding: '10px',
                borderRadius: '8px',
                border: '1px solid  #5a1fd1',
                boxShadow: 'none'
              }
            }}
          >
            <HelpOutlineIcon style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }} />
          </LightTooltip>
        </div>
        <div className="center-component">
          <h3>LOCACIONES</h3>
        </div>
      </div>
      <LabelANTDLarge
        prefix="Nombre : "
        borderColor
        value={formData.name}
        onChange={val => handleChange({ name: 'name', value: val?.toString() ?? '' })}
      />
      <LabelANTDLarge
        prefix="Abbrev : "
        borderColor
        value={formData.abbrev}
        onChange={val => handleChange({ name: 'abbrev', value: val?.toString() ?? '' })}
      />

      <InputNumber
        label="Desde:"
        value={`${formData.startTime}`}
        isTimeFormat={true}
        isShortTimeFormat={true}
        setAmount={val => handleChange({ name: 'startTime', value: String(val) })}
        onChange={val => handleChange({ name: 'startTime', value: String(val) })}
      />
      <InputNumber
        label="Hasta:"
        isTimeFormat={true}
        isShortTimeFormat={true}
        value={`${formData.endTime}`}
        setAmount={val => handleChange({ name: 'endTime', value: String(val) })}
        onChange={val => handleChange({ name: 'endTime', value: String(val) })}
      />
      <InputNumber
        label="MaxT:"
        prefix="HRS"
        showDecimals={false}
        value={`${formData.maxDuration}`}
        setAmount={val => handleChange({ name: 'maxDuration', value: Number(val) })}
        onChange={val => handleChange({ name: 'maxDuration', value: Number(val) })}
      />
      <InputNumber
        label="MaxP:"
        prefix="Personas"
        showDecimals={false}
        value={`${formData.maxPeople}`}
        setAmount={val => handleChange({ name: 'maxPeople', value: Number(val) })}
        onChange={val => handleChange({ name: 'maxPeople', value: Number(val) })}
      />
      <InputNumber
        SelectStyles={SelectStyles}
        showDecimals={true}
        label="Precio:"
        prefix="Q"
        value={`${formData.price}`}
        setAmount={value => handleChange({ name: 'price', value: Number(value) })}
        onChange={value => handleChange({ name: 'price', value: Number(value) })}
      />
      <div className="button-container">
        <ButtonB
          className="button"
          text={initialValueLocation ? ' Actualizar' : 'Guardar'}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}
const SelectStyles: React.CSSProperties = {
  paddingRight: '5px'
}

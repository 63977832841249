import React, { FC, useState, useEffect } from 'react'
import DateRangePicker from '../../components/Form/RangePicker3'
import FileUpload from '../../components/Form/FileUpload'
import { ButtonB } from '../../components/Form/ButtonB'
import './style.scss'
import { FileExcelOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faFileLines, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Toaster, toast } from 'sonner'
import { useAuth } from '../../Provider/AuthProvider'
import { createConciliation, getConciliations } from '../../services/conciliation'
import moment from 'moment'
import { useLoading } from '../../Provider/LoadingProvider'
import { BorderIpass } from '../../components/BorderIpass/Index'
import { LightTooltip } from '../../components/tooltips/text'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

interface Conciliation {
  createdAt: string
  xlsx_url: string | null
  csv_url: string | null
  txt_url: string | null
}

const ModalLoading: FC<{ progress: number; progressMessage: string | null }> = ({
  progress,
  progressMessage
}) => {
  return (
    <div className="modal-loading">
      <div className="modal-process">
        <p className="process">Procesamiento en curso</p>

        <div className="progress-barr">
          <div className="progresss" style={{ width: `${progress}%` }}></div>
        </div>
        <p className="msg">{progressMessage ? progressMessage : `${progress}%`}</p>
      </div>
    </div>
  )
}

const MonthEndReconciliations: FC<{
  onClose: () => void
  text: string
  title: 'Conciliaciones' | 'Cierre de Mes'
}> = ({ onClose, text, title }) => {
  const { SocketBackEnd, user } = useAuth()
  const [message, setMessage] = useState('')
  const [progress, setProgress] = useState(0)
  const [progressMessage, setProgressMessage] = useState<string | null>(null)
  const { startLoading, stopLoading } = useLoading()
  const [loading, setLoading] = useState(false)
  const [formFile, setFormFile] = React.useState<File | undefined>(undefined)
  const [conciliationId, setConciliationId] = useState<String | null>(null)
  const [conciliationData, setConciliationData] = useState<any | null>(null)
  const [conciliationDate, setConciliationDate] = useState<any>([])
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const isDisabled = !formFile

  useEffect(() => {
    if (!SocketBackEnd || !conciliationId) return

    const StartEvent = `Start-Conciliation-${conciliationId}`
    const progressEvent = `Progress-Conciliation-${conciliationId}`
    const finishedEvent = `Finished-Conciliation-${conciliationId}`
    const errorEvent = `Error-Conciliation-${conciliationId}`

    const handleStart = ({ msg }: { msg: string }) => {
      setLoading(true)
      toast.success(msg || 'Conciliación iniciada.', { duration: 5000, closeButton: true })
    }

    const handleProgress = (progressData: { current: number; total: number; msg: string }) => {
      const percentage = Math.round((progressData.current / progressData.total) * 100)
      setProgress(percentage)
      if (progressData.current === progressData.total && progressData.msg) {
        setProgressMessage(progressData.msg)
      }
    }

    const handleFinished = (data?: any) => {
      if (data?.conciliation) {
        setConciliationData(data.conciliation)
      }

      setLoading(false)
      toast.success('Conciliación finalizada con éxito.')
      setConciliationId(null)
    }

    const handleError = ({ msg }: { msg: string }) => {
      setLoading(false)
      toast.error(msg || 'Error desconocido', { duration: Infinity, closeButton: true })
      setConciliationId(null)
    }

    SocketBackEnd.on(StartEvent, handleStart)
    SocketBackEnd.on(progressEvent, handleProgress)
    SocketBackEnd.on(finishedEvent, handleFinished)
    SocketBackEnd.on(errorEvent, handleError)

    return () => {
      SocketBackEnd.off(progressEvent, handleProgress)
      SocketBackEnd.off(finishedEvent, handleFinished)
      SocketBackEnd.off(errorEvent, handleError)
    }
  }, [SocketBackEnd, conciliationId])

  const handleFileChange = (file?: File | string) => {
    if (typeof file === 'string') return
    if (file) {
      const allowedTypes = ['text/csv']

      const maxFileSize = 16 * 1024 * 1024 // 16 MB

      // Validar tipo de archivo
      if (!allowedTypes.includes(file.type)) {
        toast.error('Tipo de archivo no permitido. Solo se aceptan archivos CSV.')

        setFormFile(undefined)
        return
      }

      // Validar tamaño del archivo
      if (file.size > maxFileSize) {
        toast.error('El archivo supera el límite de 16 MB.')
        setFormFile(undefined)
        return
      }
      setFormFile(file)
      toast.success('Archivo cargado correctamente.')
    } else {
      setFormFile(undefined)
    }
  }

  const handleDateChange = (value: any) => {
    setSelectedDate(value)
  }

  const handleSearch = async () => {
    if (!selectedDate) return
    const times = { date: selectedDate, type: title }
    try {
      const data = await getConciliations(times)
      setConciliationDate(data)

      if (!Array.isArray(data) || data.length === 0) {
        setMessage(
          `No hay conciliaciones disponibles entre ${selectedDate[0]} y ${selectedDate[1]}`
        )
      } else {
        setMessage('') // Limpiar el mensaje si hay datos
      }
    } catch (error) {
      console.error('Error conciliations:', error)
    }
  }

  const handleSubmit = async () => {
    if (!formFile) {
      toast.error('Debe seleccionar un archivo.')
      return
    }

    setProgress(0)
    startLoading()
    try {
      const formDataToSend = new FormData()
      formDataToSend.append('file', formFile)

      // Determinar el tipo de conciliación según el título
      const conciliationType = title.toLowerCase().includes('cierre de mes') ? '1' : '0'
      formDataToSend.append('isConciliationClosed', conciliationType)

      const { conciliationId } = await createConciliation(formDataToSend)

      if (conciliationId) {
        setConciliationId(conciliationId)
      } else {
        toast.error('No se recibió un conciliationId válido.')
      }
    } catch (error) {
      toast.error('Error al iniciar la conciliación.')
    } finally {
      stopLoading()
    }
  }

  const handleManualDownload = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url)
      const blob = await response.blob()

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error(`Error al descargar ${fileName}:`, error)
    }
  }
  const name = user?.client?.name.trim()

  return (
    <div className="MonthEndReconciliations">
      <div className="title-conciliation">
        <div className="left-component">
          <LightTooltip
            title="Pendiente texto"
            placement="right"
            sx={{
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#261a3a',
                color: '#fff',
                fontSize: '14px',
                padding: '10px',
                borderRadius: '8px',
                border: '1px solid  #5a1fd1',
                boxShadow: 'none'
              }
            }}
          >
            <HelpOutlineIcon style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }} />
          </LightTooltip>
        </div>
        <div className="center-component">
          <h1 className="title">{title}</h1>
        </div>
      </div>
      <div className="options">
        <div className="optionA">
          <div className="letter-a">
            <BorderIpass
              personClassName={'button'}
              margin={3}
              styleInner={{ ...LetterStyleLong, width: '50px', height: '50px' }}
              style={LetterStyleLong}
            >
              A
            </BorderIpass>
            <FileUpload
              title="Archivo para conciliación"
              personClassName="FileAreaSegment"
              minHeight="10px"
              label="Elige o arrastra archivo csv"
              onChange={file => {
                if (typeof file === 'string') return
                handleFileChange(file)
              }}
              accept=".csv"
              value={formFile}
            />
          </div>
          <div className="border-right">
            <div className="search">
              <DateRangePicker
                style={{ maxWidth: '75%' }}
                label1={'Desde'}
                label2={'Hasta'}
                onChange={handleDateChange}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{ fontSize: '30px', marginTop: '5px', cursor: 'pointer' }}
                onClick={handleSearch}
              />
            </div>
            <div className="dates">
              {message && <p style={{ width: '100%', padding: '20px' }}>{message}</p>}

              {conciliationDate.length > 0
                ? conciliationDate.map((item: Conciliation, index: number) => (
                    <div className="date" key={index}>
                      <h6>{moment(item.createdAt).format('DD/MM/YY HH:mm')}</h6>
                      <FileExcelOutlined
                        style={{ fontSize: '24px', color: '#da29ba', cursor: 'pointer' }}
                        title="Exportar excel"
                        onClick={() =>
                          item.xlsx_url &&
                          handleManualDownload(
                            item.xlsx_url,
                            `Conciliación ${name} ${moment(item.createdAt).format(
                              'DD-MM-YY'
                            )}.xlsx`
                          )
                        }
                      />
                      <FontAwesomeIcon
                        icon={faFileCsv}
                        style={{ fontSize: '24px', color: '#da29ba', cursor: 'pointer' }}
                        title="Exportar csv"
                        onClick={() =>
                          item.csv_url &&
                          handleManualDownload(
                            item.csv_url,
                            `Conciliación ${name} ${moment(item.createdAt).format(
                              'DD-MM-YY'
                            )}.csv`
                          )
                        }
                      />
                      <FontAwesomeIcon
                        icon={faFileLines}
                        style={{ fontSize: '24px', color: '#da29ba', cursor: 'pointer' }}
                        title="Exportar txt"
                        onClick={() =>
                          item.txt_url &&
                          handleManualDownload(
                            item.txt_url,
                            `Conciliación ${name} ${moment(item.createdAt).format(
                              'DD-MM-YY'
                            )}.txt`
                          )
                        }
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>

        <div className="optionB">
          <div className="letter-b">
            <BorderIpass
              personClassName={'button'}
              margin={3}
              styleInner={{ ...LetterStyleLong, width: '50px', height: '50px', border: '5px' }}
              style={LetterStyleLong}
            >
              B
            </BorderIpass>
            <ButtonB
              text="Iniciar conciliación"
              style={{ width: '300px', height: '45px', fontSize: '16px' }}
              disabled={isDisabled}
              onClick={handleSubmit}
            />
          </div>

          <div className="letter-c">
            <BorderIpass
              personClassName={'button'}
              margin={3}
              styleInner={{ ...LetterStyleLong, width: '50px', height: '50px' }}
              style={LetterStyleLong}
            >
              C
            </BorderIpass>
            <BorderIpass
              style={{
                width: '300px',
                height: '45px',
                borderRadius: '10px'
              }}
            >
              <p
                style={{
                  width: '100%',
                  alignItems: 'center',
                  borderRadius: '10px',
                  backgroundColor: '#261a3a',
                  paddingTop: '6px'
                }}
              >
                Conciliaciones del Dia
              </p>
            </BorderIpass>
          </div>
          <div className="dates">
            {conciliationData && (
              <div className="conciliation">
                <div className="date">
                  <FileExcelOutlined
                    style={{
                      fontSize: '30px',
                      color: '#da29ba',
                      cursor: 'pointer'
                    }}
                    title="Exportar excel"
                    onClick={() =>
                      handleManualDownload(conciliationData.xlsx_url, 'conciliacion.xlsx')
                    }
                  />
                  <FontAwesomeIcon
                    icon={faFileCsv}
                    style={{
                      fontSize: '30px',
                      color: '#da29ba',
                      cursor: 'pointer'
                    }}
                    title="Exportar csv"
                    onClick={() =>
                      handleManualDownload(conciliationData.csv_url, 'conciliacion.csv')
                    }
                  />
                  <FontAwesomeIcon
                    icon={faFileLines}
                    style={{
                      fontSize: '30px',
                      color: '#da29ba',
                      cursor: 'pointer'
                    }}
                    title="Exportar txt"
                    onClick={() =>
                      handleManualDownload(conciliationData.txt_url, 'conciliacion.txt')
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <ModalLoading progress={progress} progressMessage={progressMessage} />}
      <Toaster position="top-right" richColors />
    </div>
  )
}

export default MonthEndReconciliations

const LetterStyleLong: React.CSSProperties = {
  alignItems: 'center',
  borderRadius: '50%',
  fontSize: '40px',
  fontWeight: 'bold',
  color: 'white',
  marginRight: '3px'
}

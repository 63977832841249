import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useLoading } from '../../../Provider/LoadingProvider'
import {
  ExpandLess,
  ExpandMore,
  Add,
  PictureAsPdf,
  Delete,
  Visibility
} from '@mui/icons-material'
import { IconButton } from '@mui/material'

import Modal from '../../GeneralComponents/Modal'
import DeleteButton from '../../Table/components/DeleteButton'
import {
  PaymentCharge,
  PaymentConceptResident,
  PaymentDetaild,
  statement
} from '../../../types/Payments'
import { getstatement, getStatementPdf } from '../../../services/Payments'
import './style.scss'
import {
  deleteConceptResident,
  getCharge,
  newCharge,
  newCuota,
  statusConceptResident,
  updateCharge,
  updateCuota
} from '../../../services/PaymentsConceptsResident'
import { ConceptsRes, LastSelectType, SelectType } from './Concepts'
import Switch from '../../Form/Switch'
import { PopUpDeleteConcept } from './PopUpDeleteConcept'
import { Resident } from '../../../types/resident'
import { getConcept } from '../../../services/PaymentsConceptsResident'
import { SelectOption } from '../../Form/Select'
import { Warning } from './Warning'
import { WarningMora } from './WarningMora'
import { base64ToBlob } from '../../../utils/base64ToBlob'

interface SendConcept {
  dataSend: SelectType
  residentId: string
  lastData?: LastSelectType
}

const StatementResident: FC<{ id: string; clientId: string; residentDetail: Resident }> = ({
  id,
  residentDetail,
  clientId
}) => {
  const { startLoading, stopLoading } = useLoading()
  const [slides, setSlides] = useState({
    slider_1: true,
    slider_2: true,
    slider_3: true,
    slider_4: true
  })
  const [data, setData] = useState<statement | undefined>(undefined)
  const [selectModal, setSelectModal] = useState<React.ReactNode>(<></>)
  const [optionsCuota, setoptionsCuota] = useState<SelectOption[]>([])
  const [optionsCharge, setoptionsCharge] = useState<SelectOption[]>([])
  const [sendConcept, setSendConcept] = useState<SendConcept>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (sendConcept) {
      if (!sendConcept.lastData) {
        openModal('Warning', sendConcept)
      } else {
        onSubmitConceptsResident(sendConcept)
      }
    }
  }, [sendConcept])

  const getData = async () => {
    try {
      startLoading()
      const [statement, OptionsCuotas, optionsCharges] = await Promise.all([
        getstatement(id, clientId),
        getConcept(),
        getCharge()
      ])
      setoptionsCuota(OptionsCuotas)
      setData(statement)
      setoptionsCharge(optionsCharges)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleDelete = async (idItem: string) => {
    try {
      startLoading()
      await deleteConceptResident(idItem)
      await getData()
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }

  const ChangerSliders = (nameSlider: 'slider_1' | 'slider_2' | 'slider_3' | 'slider_4') => {
    setSlides(prevSliders => ({
      ...prevSliders,
      [nameSlider]: !prevSliders[nameSlider] // Invertimos el valor
    }))
  }

  const ChangerStatus = async (data: PaymentConceptResident) => {
    setData(prevData => {
      if (!prevData) return prevData // Si es undefined, retorna sin cambios

      return {
        ...prevData,
        paymentConceptResident: prevData.paymentConceptResident.map(item =>
          item.id === data.id ? { ...item, active: !item.active } : item
        )
      }
    })

    const isGlobal = data.residentId ? false : true
    await statusConceptResident(data.id!, isGlobal, id)
  }

  const Actions = ({ idItem, item }: { idItem: string; item: PaymentConceptResident }) => {
    const isGlobal = item.residentId ? false : true
    const disabledButtonStyles = {
      color: 'gray',
      pointerEvents: 'none'
    }

    const enabledButtonStyles = {
      color: 'white'
    }

    const newStatus = item.active ? 'desactivar' : 'activar'
    const setStatustext = `una cuota ${isGlobal ? 'obligatoria' : ''}`

    const datePoup = {
      title: 'ADVERTENCIA',
      description: `Estas a punto de ${newStatus} ${setStatustext}\n\n`,
      acceptText: newStatus.toUpperCase(),
      rejectText: 'CANCELAR'
    }

    return (
      <div className="actionsContainer">
        <Switch
          key={idItem}
          value={item.active}
          onChange={() => ChangerStatus(item)}
          PopUp={datePoup}
        />
        <IconButton
          onClick={
            () => openModal('cuotas', item) // Abre el modal cuando dataSelect cambie
          }
          disabled={isGlobal}
          style={isGlobal ? disabledButtonStyles : enabledButtonStyles}
        >
          <Visibility />
        </IconButton>

        {<DeleteButton OnDelete={() => handleDelete(idItem)} disabled={isGlobal} />}
      </div>
    )
  }

  const ActionsCharge = ({ item }: { item: PaymentCharge }) => {
    return (
      <div className="actionsContainer">
        <IconButton
          onClick={
            () => openModal('Charge', item) // Abre el modal cuando dataSelect cambie
          }
          style={{ color: 'white' }}
        >
          <Visibility />
        </IconButton>
        <IconButton
          onClick={() => openModal('DeleteCuotas', item)}
          style={{ color: 'tomato', padding: '0px', width: '40px' }}
        >
          <Delete />
        </IconButton>
      </div>
    )
  }

  const onSubmitConceptsResident = async ({ dataSend, residentId, lastData }: SendConcept) => {
    try {
      startLoading()

      const data = {
        name: dataSend.concept,
        active: true,
        clientId,
        residentId,
        paymentsConceptsId: dataSend.option
      }

      if (lastData && lastData?.id) {
        await updateCuota(data, lastData.id)
      } else {
        await newCuota(data)
      }
    } catch (error) {
    } finally {
      closeModal()
    }
  }

  const onSubmitCharge = async (
    dataSend: SelectType,
    residentId: string,
    lastData?: LastSelectType
  ) => {
    try {
      startLoading()

      const data = {
        name: dataSend.concept,
        residentId,
        paymentsConceptsId: dataSend.option
      }

      if (lastData && lastData?.id) {
        await updateCharge(data, lastData.id)
      } else {
        await newCharge(data)
      }
    } catch (error) {
    } finally {
      closeModal()
    }
  }

  const openModal = (
    select: 'cuotas' | 'DeleteCuotas' | 'Charge' | 'Warning' | 'WarningMora',
    data?: PaymentConceptResident | PaymentDetaild | PaymentCharge | undefined | SendConcept
  ): void => {
    const production = process.env.REACT_APP_DEPURE !== 'true'
    if (select === 'cuotas') {
      if (residentDetail.statusPayment === 'EN MORA' && production)
        return openModal('WarningMora')
      const selectData = data as PaymentConceptResident
      const lastData =
        selectData && selectData?.paymentsConceptsId
          ? {
              id: selectData?.id,
              option: selectData?.paymentsConceptsId,
              concept: selectData?.name
            }
          : undefined

      setSelectModal(
        <Modal
          isOpen={true}
          borderColor={true}
          onClose={closeModal}
          style_Subcontainer={{ padding: '2rem' }}
          styleContainerContent={{ padding: '2px', margin: '0px' }}
          styleContainer={{
            width: '350px',
            height: '380px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
        >
          <ConceptsRes
            title={`${lastData ? 'EDITAR' : 'CREAR'} CUOTA OPCIONAL`}
            placeholderSelect={'Selecciona la cuota opcional'}
            placeholderText={'Concepto'}
            lastData={lastData}
            residentId={id}
            onSubmit={(dataSend, residentId, lastData) =>
              setSendConcept({ dataSend, residentId, lastData })
            }
            opcions={optionsCuota}
          />
        </Modal>
      )
    } else if (select === 'Charge') {
      if (residentDetail.statusPayment === 'EN MORA' && production)
        return openModal('WarningMora')

      const selectData = data as PaymentConceptResident
      const lastData =
        selectData && selectData?.paymentsConceptsId
          ? {
              id: selectData?.id,
              option: selectData?.paymentsConceptsId,
              concept: selectData?.name
            }
          : undefined

      setSelectModal(
        <Modal
          isOpen={true}
          borderColor={true}
          onClose={closeModal}
          style_Subcontainer={{ padding: '2rem' }}
          styleContainerContent={{ padding: '2px', margin: '0px' }}
          styleContainer={{
            width: '350px',
            height: '380px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
        >
          <ConceptsRes
            title={`${lastData ? 'EDITAR CARGO' : 'CREAR CARGO'}`}
            placeholderSelect={'Selecciona el cargo'}
            placeholderText={'Concepto'}
            lastData={lastData}
            residentId={id}
            onSubmit={onSubmitCharge}
            opcions={optionsCharge}
          />
        </Modal>
      )
    } else if (select === 'DeleteCuotas' && data) {
      const dataSelect = data as PaymentDetaild
      setSelectModal(
        <Modal
          isOpen={true}
          borderColor={true}
          onClose={closeModal}
          styleContainerContent={{ padding: '5px', margin: '0px' }}
          styleContainer={{
            width: '680px',
            height: '500px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: ' 15px', top: '50px' }}
        >
          <PopUpDeleteConcept
            onClose={closeModal}
            title={`Estas a punto de ${
              dataSelect.isDelete ? 'restaurar' : 'eliminar'
            } el siguiente cargo del ${residentDetail?.destinationCode || '0'}`}
            paymentDetaild={dataSelect}
          />
        </Modal>
      )
    } else if (select === 'Warning' && sendConcept) {
      setSelectModal(
        <Modal
          isOpen
          borderColor
          onClose={closeModal}
          styleContainerContent={{ padding: '5px', margin: '0px' }}
          styleContainer={{
            width: '400px',
            height: '230px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
        >
          <Warning onClose={closeModal} onSend={() => onSubmitConceptsResident(sendConcept)} />
        </Modal>
      )
    } else if (select === 'WarningMora') {
      setSelectModal(
        <Modal
          isOpen
          borderColor
          onClose={closeModal}
          styleContainerContent={{ padding: '5px', margin: '0px' }}
          styleContainer={{
            width: '25rem',
            height: '10rem',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
        >
          <WarningMora onClose={closeModal} />
        </Modal>
      )
    }
  }

  const closeModal = () => {
    setSelectModal(<></>)
    setSendConcept(undefined)
    getData()
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setPdfUrl('') // Limpia la URL del PDF
  }

  const handlePdfPayments = async () => {
    if (id) {
      try {
        startLoading()
        console.log(id)
        const dataPdf64 = await getStatementPdf(id) // Pedimos el base64 del pdf

        // Convierte el base64 en un objeto Blob
        const pdfBlob = base64ToBlob(dataPdf64, 'application/pdf')

        // Crea un enlace para ver el PDF
        const url = URL.createObjectURL(pdfBlob)
        setPdfUrl(url)

        // Abre el modal
        setIsModalVisible(true)
      } catch (error) {
        console.error('Error al crear reporte', error) // Maneja el error
      } finally {
        stopLoading()
      }
    }
  }

  return (
    <div className="StatementResident">
      {data && (
        <>
          <div className="title">
            <div className="svgHeader">
              <IconButton onClick={() => ChangerSliders('slider_1')}>
                {slides.slider_1 ? (
                  <ExpandMore style={{ color: 'white' }} />
                ) : (
                  <ExpandLess style={{ color: 'white' }} />
                )}
              </IconButton>
            </div>
            <div className="svgPdf">
              <IconButton onClick={handlePdfPayments}>
                <PictureAsPdf style={{ color: 'white' }} />
              </IconButton>
            </div>

            <div className="headerAcountState">
              <div className="acountState">
                <h4>ESTADO DE CUENTA {data.date}</h4>
                <p>Fecha Corte {data.courtDate}</p>
                <p>Fecha Máxima {data.deadLine}</p>
              </div>

              <div className="DataResident">
                <p>
                  {residentDetail.name} {residentDetail.lastName}
                </p>
                <p>{residentDetail.destinationCode}</p>
              </div>

              <div className="DataStatus">
                <p
                  style={{
                    color: residentDetail.statusPayment === 'EN MORA' ? 'red' : 'green'
                  }}
                >
                  {residentDetail.statusPayment}
                </p>
              </div>
            </div>
          </div>
          <div
            className="containerSlider"
            style={{
              maxHeight: slides.slider_1 ? `${50 * (data.paymentDetaild.length + 1)}px` : '0px'
            }}
          >
            {data.paymentDetaild.map((detail, index) => (
              <div key={index} className="containerMap">
                <div
                  className="details"
                  // style={{ gridTemplateColumns: '0.5fr 0.5fr 1.5fr 1.5fr', gap: '0.7rem' }}
                >
                  <span className="container-span" style={{ fontSize: '15px' }}>
                    {detail.originalDate}
                  </span>
                  <span className="container-span-type" style={{ fontSize: '15px' }}>
                    {detail.type}
                  </span>
                  <span className="container-span-type" style={{ fontSize: '13px' }}>
                    {detail.name}
                  </span>
                  <span className="container-span-type" style={{ fontSize: '11px' }}>
                    {detail.conceptDate}
                  </span>
                </div>

                <div
                  style={{
                    width: '120px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                  }}
                >
                  <div className="PriceContainer">
                    <span
                      style={
                        detail.isDelete && detail.id !== '0'
                          ? {
                              textDecoration: 'line-through',
                              textDecorationColor: '#ff0000',
                              textDecorationThickness: '1px'
                            }
                          : {}
                      }
                    >
                      Q {detail.amount.toFixed(2)}
                    </span>
                  </div>
                  {!detail.isDelete && detail.type !== 'Pago' ? (
                    <IconButton
                      onClick={() => openModal('DeleteCuotas', detail)}
                      style={{
                        color: 'tomato',
                        padding: '0px',
                        width: '40px'
                      }}
                    >
                      <Delete />
                    </IconButton>
                  ) : (
                    <div
                      style={{
                        padding: '0px',
                        width: '40px'
                      }}
                    ></div>
                  )}
                </div>
              </div>
            ))}

            <div className="totalPrice">
              <div className="details" style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <span>SALDO A LA FECHA</span>
              </div>
              <div className="PriceContainer" style={{ marginRight: '20px' }}>
                <span>Q {data.totalPrice.toFixed(2)}</span>
              </div>
            </div>
          </div>

          <div className="title">
            <div className="svg">
              <IconButton onClick={() => ChangerSliders('slider_2')}>
                {slides.slider_2 ? (
                  <ExpandMore style={{ color: 'white' }} />
                ) : (
                  <ExpandLess style={{ color: 'white' }} />
                )}
              </IconButton>
            </div>
            <p>CUOTAS</p>

            <div className="svgPlus">
              <IconButton onClick={() => openModal('cuotas', undefined)}>
                <Add style={{ color: 'white' }} />
              </IconButton>
            </div>
          </div>
          <div
            className="containerSlider-cuotas"
            style={{
              maxHeight: slides.slider_2
                ? `${50 * data.paymentConceptResident.length}px`
                : '0px'
            }}
          >
            {data.paymentConceptResident.map((detail, index) => (
              <div key={index} className="containerMap">
                <div className="details" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                  <span>{detail.name}</span>
                  <span>{detail.conceptDate || ''}</span>
                </div>
                <div className="PriceContainer-cuotas">
                  <span>Q {detail.price.toFixed(2)}</span>
                </div>

                <Actions idItem={detail.id as string} item={detail} />
              </div>
            ))}
          </div>

          <div>
            <div className="title">
              <div className="svg">
                <IconButton onClick={() => ChangerSliders('slider_3')}>
                  {slides.slider_3 ? (
                    <ExpandMore style={{ color: 'white' }} />
                  ) : (
                    <ExpandLess style={{ color: 'white' }} />
                  )}
                </IconButton>
              </div>
              <p>CARGOS</p>

              <div className="svgPlus">
                <IconButton onClick={() => openModal('Charge', undefined)}>
                  <Add style={{ color: 'white' }} />
                </IconButton>
              </div>
            </div>
            <div
              className="containerSlider"
              style={{
                maxHeight: slides.slider_3 ? `${50 * data?.paymentCharge?.length}px` : '0px'
              }}
            >
              {data?.paymentCharge.map((detail, index) => (
                <div key={index} className="containerMap">
                  <div className="details" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <span>{detail.name}</span>
                    <span>{detail.conceptDate}</span>
                  </div>
                  <div className="PriceContainer">
                    <span>Q {detail.amount.toFixed(2)}</span>
                  </div>

                  <ActionsCharge item={detail} />
                </div>
              ))}
            </div>
          </div>

          <div className="title">
            {data.resevations.length > 0 && (
              <div className="svg">
                <IconButton onClick={() => ChangerSliders('slider_4')}>
                  {slides.slider_4 ? (
                    <ExpandMore style={{ color: 'white' }} />
                  ) : (
                    <ExpandLess style={{ color: 'white' }} />
                  )}
                </IconButton>
              </div>
            )}
            <p>RESERVAS</p>
          </div>
          <div
            className="containerSlider"
            style={{
              maxHeight: slides.slider_4 ? `${50 * data.resevations.length}px` : '0px'
            }}
          >
            {data.resevations.map((detail, index) => (
              <div key={index} className="containerMap">
                <div className="details" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                  <span className="Resevations-name">{detail.name}</span>
                  <span className="Resevations-date">{detail.conceptDate}</span>
                </div>
                <div className="PriceContainer">
                  <span className="Resevations-type">Q {detail.amount.toFixed(2)}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {selectModal}

      <Modal
        isOpen={isModalVisible}
        styleContainer={{
          width: ' 100%',
          height: ' 100%',
          padding: '0px',
          background: 'rgb(0 0 0 / 0%)',
          overflow: 'hidden'
        }}
        styleContainerContent={{ padding: '0 60px', height: '100%', maxHeight: '100vh' }}
        onClose={handleModalClose}
      >
        <object
          data={pdfUrl}
          type="application/pdf"
          width="100%"
          height="99%" // Ajusta la altura según tus necesidades
        >
          <Link to={pdfUrl} target="_blank" rel="noopener noreferrer">
            Descargar PDF
          </Link>
        </object>
      </Modal>
    </div>
  )
}

export default StatementResident

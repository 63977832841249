import React, { useEffect, useState } from 'react'
import './style.scss'
import { IconButton, Tooltip } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight, Add as AddIcon } from '@mui/icons-material'
import { TitlePages } from '../../components/TitlePages'
import { Add, Visibility, ContentCopy } from '@mui/icons-material'
import Modal from '../../components/GeneralComponents/Modal'
import Switch from '../../components/Form/Switch'
import { SetReasonAndRules } from './SetReasonAndRules'
import { ReasonAndRules } from '../../types/reasonAndRules'
import {
  listResonAndRules,
  deleteReasonAndRules,
  createCopyReasonAndRules,
  updatePatchReasonAndRules
} from '../../services/reasonAndRules'
import { Pagination } from '../../types/types'
import { useLoading } from '../../Provider/LoadingProvider'
import DeleteButton from '../../components/Table/components/DeleteButton'
import { useDebounce } from '../../hooks/useDebounce'

const ReasonAndRulesPage = () => {
  const [selectModal, setSelectModal] = useState<React.ReactNode>()
  const [pendingState, setPendingState] = useState<{ id: string; newState: boolean } | null>(
    null
  )
  const debouncedState = useDebounce(pendingState, 1000) //esperamos un segundo antes de hacer culquier peticion

  const [data, setData] = useState<Pagination<ReasonAndRules>>({
    total: 1,
    data: [],
    currentPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false
  })

  const [selected, setSelected] = useState<ReasonAndRules | undefined>(undefined)
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>({})

  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 6
  const total = data.total

  const onPageChange = (newPage: number) => {
    getDataReasonAndRules('', newPage)
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= Math.ceil(total / itemsPerPage)) {
      setCurrentPage(page)
      onPageChange(page)
    }
  }

  const handlePrevPage = () => {
    goToPage(currentPage - 1)
  }

  const handleNextPage = () => {
    goToPage(currentPage + 1)
  }

  const closeModal = () => {
    setSelectModal(undefined)
  }

  useEffect(() => {
    const updateState = async () => {
      if (debouncedState !== null) {
        try {
          await updatePatchReasonAndRules(debouncedState.id, debouncedState.newState)
          setSwitchStates(prev => ({
            ...prev,
            [debouncedState.id]: debouncedState.newState
          }))
        } catch (error) {
          setSwitchStates(prev => ({
            ...prev,
            [debouncedState.id]: !debouncedState.newState
          }))
        }
      }
    }

    updateState()
  }, [debouncedState])

  const getDataReasonAndRules = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const reasonAndRules = await listResonAndRules({
        // id,
        params: { page, search: searchValue, limit: itemsPerPage }
      })

      setData(reasonAndRules)
      const initialSwitchStates: { [key: string]: boolean } = {}
      reasonAndRules.data.forEach(item => {
        initialSwitchStates[item.id] = !!item.enable
      })
      setSwitchStates(initialSwitchStates)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  useEffect(() => {
    getDataReasonAndRules('', 1)
  }, [])

  const handleView = (item: ReasonAndRules) => {
    setSelected(item)
  }
  useEffect(() => {
    if (selected) {
      setSelectModal(
        <Modal
          isOpen={true}
          onClose={closeModal}
          borderColor={true}
          style_Subcontainer={{ padding: '1rem' }}
          styleContainerContent={{ padding: '2px', margin: '1px' }}
          styleContainer={{
            width: '550px',
            height: '600px',
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          styleClose={{ fontSize: '20px', right: '10px', top: '10px' }}
        >
          <SetReasonAndRules
            title="MOTIVO & REGLA"
            onClose={closeModal}
            initialValue={selected}
            onSuccess={() => getDataReasonAndRules(search, currentPage)}
          />
        </Modal>
      )
    }
  }, [selected])

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteReasonAndRules(id)
      getDataReasonAndRules(search, data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleCopy = async (id: string) => {
    try {
      startLoading()
      await createCopyReasonAndRules(id)

      await getDataReasonAndRules(search, currentPage)
    } catch (error) {
      console.error('Error al copiar la regla:', error)
    } finally {
      stopLoading()
    }
  }

  const handleToggle = (id: string) => {
    setSwitchStates(prev => {
      const newState = !prev[id]
      setPendingState({ id, newState })
      return {
        ...prev,
        [id]: newState
      }
    })
  }

  return (
    <>
      <div className="SegmentsContainer">
        <TitlePages
          title="MOTIVOS & REGLAS"
          tooltip="Crear motivos para llamadas de atención a residentes y reglas de acumulación para pasar a posición de multas."
        />
        <div className="ContainerSegment">
          <p className="title">MOTIVOS</p>
          <IconButton
            onClick={() =>
              setSelectModal(
                <Modal
                  isOpen={true}
                  onClose={closeModal}
                  borderColor={true}
                  style_Subcontainer={{ padding: '1rem' }}
                  styleContainerContent={{ padding: '2px', margin: '1px' }}
                  styleContainer={{
                    width: '550px',
                    height: '600px',
                    padding: '0px',
                    overflow: 'hidden',
                    margin: '5px',
                    borderRadius: '20px'
                  }}
                  styleClose={{ fontSize: '20px', right: '10px', top: '10px' }}
                >
                  <SetReasonAndRules
                    title="MOTIVO & REGLA"
                    onClose={closeModal}
                    onSuccess={() => getDataReasonAndRules(search, currentPage)}
                  />
                </Modal>
              )
            }
          >
            <Tooltip
              title="Crear"
              placement="right-start"
              slotProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#261a3a', // Color de fondo
                    color: 'white', // Color del texto
                    fontSize: '14px', // Tamaño del texto
                    padding: '8px 12px', // Espaciado interno
                    borderRadius: '8px', // Bordes redondeados
                    boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                  }
                }
              }}
            >
              <IconButton>
                <AddIcon style={{ color: 'white', fontSize: '40px' }} />
              </IconButton>
            </Tooltip>
          </IconButton>
        </div>

        {selectModal}
        <div className="Container-reason">
          <div className="title-reason-and-rules">
            <div className="info-header">
              <span className="name">NOMBRE</span>
              <span className="rules">REGLA</span>
              <span className="reason-rule">MULTA REGLA</span>
            </div>
          </div>

          {data.data.map(item => (
            <div key={item.id} className="name-result">
              <div className="name-result-info">
                <span>{item.name}</span>
                <span>{item.rule}</span>
                <span>Q {item.penalty.toFixed(2)}</span>
              </div>
              <li className="icons-grid">
                <Tooltip
                  title="Copiar"
                  placement="top-end"
                  slotProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#261a3a', // Color de fondo
                        color: 'white', // Color del texto
                        fontSize: '14px', // Tamaño del texto
                        padding: '8px 12px', // Espaciado interno
                        borderRadius: '8px', // Bordes redondeados
                        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                      }
                    }
                  }}
                >
                  <IconButton>
                    <ContentCopy
                      onClick={() => handleCopy(item.id)}
                      style={{ color: 'white' }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Desactivar / Activar"
                  placement="top-end"
                  slotProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#261a3a', // Color de fondo
                        color: 'white', // Color del texto
                        fontSize: '14px', // Tamaño del texto
                        padding: '8px 12px', // Espaciado interno
                        borderRadius: '8px', // Bordes redondeados
                        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                      }
                    }
                  }}
                >
                  <IconButton>
                    <Switch
                      activeColor={'#28a745'}
                      ColorDiscative={'#5d7386'}
                      value={switchStates[item.id] || false}
                      onChange={() => handleToggle(item.id)}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Actualizar"
                  placement="top-end"
                  slotProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#261a3a', // Color de fondo
                        color: 'white', // Color del texto
                        fontSize: '14px', // Tamaño del texto
                        padding: '8px 12px', // Espaciado interno
                        borderRadius: '8px', // Bordes redondeados
                        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                      }
                    }
                  }}
                >
                  <IconButton onClick={() => handleView(item)}>
                    <Visibility style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Eliminar"
                  placement="top-end"
                  slotProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#261a3a', // Color de fondo
                        color: 'white', // Color del texto
                        fontSize: '14px', // Tamaño del texto
                        padding: '8px 12px', // Espaciado interno
                        borderRadius: '8px', // Bordes redondeados
                        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                      }
                    }
                  }}
                >
                  <IconButton style={{ padding: '0px', margin: '0px' }}>
                    <DeleteButton OnDelete={() => handleDelete(item.id)} />
                  </IconButton>
                </Tooltip>
              </li>
            </div>
          ))}
        </div>
        <div className="pagination">
          <IconButton onClick={handlePrevPage} disabled={!data.hasPrevPage}>
            <KeyboardArrowLeft style={{ color: data.hasPrevPage ? 'white' : 'gray' }} />
          </IconButton>
          <span className="page-number">{data.currentPage}</span>
          <IconButton onClick={handleNextPage} disabled={!data.hasNextPage}>
            <KeyboardArrowRight style={{ color: data.hasNextPage ? 'white' : 'gray' }} />
          </IconButton>
        </div>
      </div>
    </>
  )
}

export default ReasonAndRulesPage

import React, { useState, useEffect, useMemo } from 'react'

import { ButtonB } from '../../components/Form/ButtonB'
import Modal from '../../components/GeneralComponents/Modal'
import { LightTooltip } from '../../components/tooltips/text'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useLoading } from '../../Provider/LoadingProvider'
import RefreshIcon from '@mui/icons-material/Refresh'
import './style.scss'
import InputLarge from '../../components/Form/InputLarge'
import { MyTextArea } from '../../components/Form/labels/MyTextArea'
import dayjs from 'dayjs'
import RangePickerLarge from '../../components/Form/RangePickerLarge'
import { ReserveCreate, Reserve, confirmDates } from '../../types/reserve'
import { Location } from '../../types/reserve'
import MultiSelectANTD from '../../components/Form/multiSelet'
import { BorderIpass } from '../../components/BorderIpass/Index'
import { confirmationDates, createReserve, updateReserves } from '../../services/reserve'
import { Pagination } from '../../types/types'
import { Segments } from '../../types/Segments'
import { InputHour } from '../../components/Form/InputHour'
import { IconButton } from '@mui/material'
import { Select_Option_Resident } from '../../types/Message_center'
import { get_all_Residents } from '../../services/Message'
import { ResidentData } from '../../types/reserve'
import { getDataResident } from '../../services/reserve'
interface ModalCreateReserveProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  initialValue?: Reserve
  data?: Pagination<Segments>
  destinationCode?: string
}

const ModalCreateReserve: React.FC<ModalCreateReserveProps> = ({
  isOpen,
  onClose,
  onSuccess,
  initialValue,
  data
}) => {
  useEffect(() => {
    if (initialValue) {
      setDateRange({
        start: initialValue.date ? dayjs(initialValue.date, 'DD/MM/YY') : null,
        end: initialValue.date ? dayjs(initialValue.date, 'DD/MM/YY') : null
      })

      setFormData(prev => ({
        ...prev,
        date: initialValue.date
          ? dayjs(initialValue.date, 'DD/MM/YY').format('YYYY-MM-DD')
          : '',
        startHour: initialValue.startHour
          ? dayjs(initialValue.startHour, ['HH:mm', 'HH:mm:ss']).format('HH:mm')
          : ''
      }))

      const segmentData = data?.data.find(seg => seg.id === initialValue.segmentId) ?? null
      if (segmentData) {
        setSelectedSegment(segmentData.name)
        if (segmentData.Locations && segmentData.Locations.length > 0) {
          setFilteredLocationsTwo(segmentData.Locations ?? [])
        }
        const location = segmentData.Locations?.find(loc => loc.name === initialValue.location)
        if (location) {
          setSelectedLocation(location)
        }
      }
    }
  }, [initialValue, data])

  const [dateRange, setDateRange] = useState<{
    start?: dayjs.Dayjs | null
    end?: dayjs.Dayjs | null
  }>({ start: null, end: null })

  const { startLoading, stopLoading } = useLoading()
  const [residentDetail, setResidentDetail] = useState<ResidentData>()
  const [residenteDetalle, setResidenteDetalle] = useState<Select_Option_Resident>()
  const [allResidents, setAllResidents] = useState<Select_Option_Resident[]>([])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [selectedSegment, setSelectedSegment] = useState<string>('')
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
  const [filteredLocations, setFilteredLocations] = useState<Location[]>([])
  const [filteredLocationsTwo, setFilteredLocationsTwo] = useState<Location[]>([])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [confirmationReserveMessage, setConfirmationReserveMessage] = useState<confirmDates[]>(
    []
  )
  const [open, setOpen] = useState(false)
  const [openLocation, setOpenLocation] = useState(false)
  const [openResident, setOpenResident] = useState(false)
  const [formValues, setFormValues] = useState<{ value: string | null }>({ value: null })
  const [formData, setFormData] = useState<Reserve>(
    initialValue || {
      id: '',
      date: '',
      startHour: '',
      endHour: '',
      endTime: '',
      location: '',
      sendMessage: '',
      destinationCode: ''
    }
  )

  const getResidentes = async (destines?: string[]) => {
    const data = await get_all_Residents({ search: destines })
    setAllResidents(data)
  }

  useEffect(() => {
    getResidentes()
  }, [])

  useEffect(() => {
    if (confirmationReserveMessage.length > 0) {
      setIsConfirmationModalOpen(true)
    }
  }, [confirmationReserveMessage])

  useEffect(() => {
    if (selectedSegment) {
      const segment = data?.data.find(seg => seg.id === selectedSegment[0])
      if (segment) {
        setFilteredLocations(segment.Locations ?? [])
      } else {
        setFilteredLocations([])
      }
    } else {
      setFilteredLocations([])
    }
  }, [selectedSegment])

  const handleChange = <K extends keyof ReserveCreate>(params: {
    name: K
    value: ReserveCreate[K]
  }) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [params.name]: params.value ?? ''
    }))
  }

  const handleSubmit = async () => {
    try {
      startLoading()
      const residentId = residenteDetalle?.value || initialValue?.residentId

      if (!residentId || !selectedLocation || !formData.date || !formData.startHour) {
        setErrorMessage('Por favor, completa todos los campos obligatorios.')
        return
      }

      const formattedDate = dayjs(formData.date).format('DD/MM/YYYY')
      let formattedStartHour = dayjs(formData.startHour, ['HH:mm', 'HH:mm:ss']).format(
        'HH:mm:ss'
      )

      const formDataSend = new FormData()
      formDataSend.append('locationId', selectedLocation.id)
      formDataSend.append('residentId', residentId || '')
      formDataSend.append('date', formattedDate)
      formDataSend.append('startHour', formattedStartHour)
      formDataSend.append('sendMessage', confirmationMessage)

      if (initialValue) {
        await updateReserves(initialValue.id, formDataSend)
      } else {
        await createReserve(formDataSend)
      }

      onSuccess()
    } catch (error) {
      setErrorMessage('Ocurrió un error al guardar la reserva.')
    } finally {
      stopLoading()
    }
  }

  const handleConfirmDates = async () => {
    try {
      startLoading()

      if (!selectedLocation || !formData.date || !formData.startHour) {
        setErrorMessage('Por favor, completa todos los campos obligatorios.')
        return
      }

      const formattedDate = dayjs(formData.date).format('DD/MM/YYYY')
      const formattedStartHour = dayjs(formData.startHour, ['HH:mm', 'HH:mm:ss']).format(
        'HH:mm:ss'
      )

      const formDataSend = new FormData()
      formDataSend.append('locationId', selectedLocation.id)
      formDataSend.append('date', formattedDate)
      formDataSend.append('startHour', formattedStartHour)

      const response = await confirmationDates(formDataSend)

      setConfirmationReserveMessage(
        response ? (Array.isArray(response) ? response : [response]) : []
      )
    } catch (error) {
      setErrorMessage('Ocurrió un error al confirmar la reserva.')
    } finally {
      stopLoading()
    }
  }

  const handleReset = () => {
    setFormData({
      id: '',
      date: '',
      startHour: '',
      endHour: '',
      endTime: '',
      location: '',
      sendMessage: '',
      destinationCode: '',
      code: ''
    })
    setDateRange({ start: null, end: null })
    setSelectedSegment('')
    setSelectedLocation(null)
    setResidenteDetalle(undefined)
    setErrorMessage(null)
    setFilteredLocations([])
    setFilteredLocationsTwo([])
    setOpen(false)
    setOpenLocation(false)
    setFormValues({ value: null })
  }
  const handleInputChange = ({ name, value }: { name: string; value: string | null }) => {
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleResidentSelection = async (value: string | string[] | null) => {
    const selectedValue = Array.isArray(value) ? value[0] : value

    handleInputChange({ name: 'value', value: selectedValue })

    if (selectedValue) {
      try {
        startLoading()
        const residentData = await getDataResident({ id: selectedValue })
        setResidentDetail(residentData)
      } catch (error) {
        console.error('Error al obtener el residente:', error)
      } finally {
        stopLoading()
      }
    }
  }
  const labelWithoutNumbers = residenteDetalle?.label.replace(/[0-9]/g, '').trim()
  const confirmationMessage = useMemo(() => {
    return `Hola ${
      initialValue
        ? `${initialValue.nameResident || ''} ${initialValue.lastNameResident || ''}`
        : `${labelWithoutNumbers || ''}`
    }. Tu reserva ${
      initialValue
        ? `${initialValue.code || ''}`
        : `${residenteDetalle?.destinationCode || ''}`
    } para ${selectedLocation?.name || ''} el día ${formData?.date || ''}  a ${
      formData?.startHour || ''
    } horas a quedado registrada exitosamente. Han sido cargados ${
      selectedLocation?.price?.toFixed(2) || ''
    } a tu estado de cuenta, los que deberás acreditar desde la opción de saldos y acreditamientos. Recuerda, son ${
      selectedLocation?.maxDuration || ''
    } horas de uso y observar las normas de convivencia acostumbradas. Podrás eliminar o cambiar esta reserva desde la opción MIS RESERVAS. Un placer servirte y que disfrutes tu evento.`
  }, [residenteDetalle, selectedLocation, formData, initialValue])

  const popUpMessage = useMemo(() => {
    const formattedDate = dayjs(formData?.date).format('DD [de] MMMM')
    return `¿Estás seguro de realizar la reserva en ${
      selectedLocation?.name || ''
    } para el día ${formattedDate} a las ${formData?.startHour || ''}?`
  }, [selectedLocation, formData])

  const isAvailable =
    confirmationReserveMessage.length > 0 ? confirmationReserveMessage[0].available : false

  const unavailableMessage =
    'La reserva no está disponible para el horario y la locacion seleccionada.'

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      borderColor={true}
      position="left"
      style_Subcontainer={{ padding: '1rem' }}
      styleContainerContent={{ padding: '2px', margin: '1px' }}
      styleContainer={{
        width: '600px',
        height: '100%',
        padding: '0px',
        overflow: 'hidden',
        margin: '2px',
        borderRadius: '15px'
      }}
      styleClose={{ fontSize: '20px', right: '25px', top: '30px' }}
    >
      <div className="container-reserve">
        <div className="title-reserve">
          <div className="left-component">
            <LightTooltip
              title="Pendiente texto"
              placement="right"
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#261a3a',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid  #5a1fd1',
                  boxShadow: 'none'
                }
              }}
            >
              <HelpOutlineIcon
                style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }}
              />
            </LightTooltip>
          </div>
          <div className="center-component">
            <h3 className={initialValue ? 'title updating' : 'title creating'}>
              {initialValue ? 'ACTUALIZANDO RESERVA' : 'CREANDO RESERVA'}
            </h3>
          </div>
        </div>
        <div className="inputs-reserve">
          <div className="one-line">
            <BorderIpass margin={1} status={'Normal'} style={{ width: '70%', height: '40px' }}>
              <MultiSelectANTD
                colorBg="trasparent"
                showSearch
                className="multiselect"
                style={{
                  width: '100%',
                  cursor: 'pointer',
                  height: '42px',
                  borderRadius: '20px',
                  color: 'rgb(220, 217, 217)'
                }}
                placeholder="Destino"
                options={allResidents.map(option => ({
                  key: option.label,
                  label: (
                    <LightTooltip
                      title={option.label}
                      placement="right"
                      sx={{
                        '& .MuiTooltip-tooltip': {
                          backgroundColor: '#261a3a',
                          color: '#fff',
                          fontSize: '14px',
                          padding: '10px',
                          borderRadius: '8px',
                          boxShadow: 'none',
                          border: 'none'
                        }
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.1rem',
                          maxWidth: '100%',
                          widows: '100px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          lineHeight: '1.5rem',
                          maxHeight: '1.5rem'
                        }}
                      >
                        {option.destinationCode}
                      </div>
                    </LightTooltip>
                  ),
                  value: option.value
                }))}
                filterOption={(input, option) =>
                  typeof option?.key === 'string' &&
                  option.key.toLowerCase().includes(input.toLowerCase())
                }
                value={initialValue ? initialValue.code : formValues.value ?? null}
                onChange={value => {
                  const selectedResidente = allResidents.find(
                    resident => resident.value === value[0]
                  )
                  setResidenteDetalle(selectedResidente)
                  handleResidentSelection(value)
                  setOpenResident(false)
                }}
                allowClear
                maxTagCount={1}
                open={openResident}
                onDropdownVisibleChange={setOpenResident}
              />
            </BorderIpass>
            <InputLarge
              placeholder={'Nombre del residente '}
              key={'name'}
              style={{ color: 'rgb(214, 210, 210)' }}
              value={
                initialValue
                  ? `${initialValue.nameResident} ${initialValue.lastNameResident}`
                  : `${labelWithoutNumbers || 'Nombre del residente'}`
              }
            />
          </div>
          <div className="two-line">
            <RangePickerLarge
              value={dateRange}
              onChange={newRange => {
                setDateRange(newRange)
                setFormData(prev => ({
                  ...prev,
                  date: newRange.start ? newRange.start.format('YYYY-MM-DD') : ''
                }))
              }}
              allowSingleDate={true}
            />
            <InputHour
              is24HourFormat={true}
              value={`${formData.startHour}`}
              setAmount={val => handleChange({ name: 'startHour', value: String(val) })}
              onChange={val => handleChange({ name: 'startHour', value: String(val) })}
            />
          </div>
          <div className="three-line">
            <BorderIpass>
              <MultiSelectANTD
                value={
                  selectedSegment && selectedSegment.length > 0 ? selectedSegment : undefined
                }
                showSearch
                colorBg="trasparent"
                onChange={value => {
                  setSelectedSegment(value)
                  setOpen(false)
                }}
                placeholder="Seleccione el Segmento"
                options={data?.data.map(seg => ({
                  label: seg.name,
                  value: seg.id
                }))}
                allowClear
                maxTagCount={1}
                open={open}
                onDropdownVisibleChange={setOpen}
                style={{
                  width: '250px',
                  cursor: 'pointer',
                  height: '42px',
                  borderRadius: '20px'
                }}
              />
            </BorderIpass>
            <BorderIpass>
              <MultiSelectANTD
                value={selectedLocation ? [selectedLocation.name] : undefined}
                colorBg="trasparent"
                showSearch
                onChange={values => {
                  const selectedLoc = (
                    filteredLocationsTwo.length > 0 ? filteredLocationsTwo : filteredLocations
                  ).find(loc => loc.id === values[0])

                  setSelectedLocation(selectedLoc || null)
                  setOpenLocation(false)
                }}
                placeholder="Seleccione la Locación"
                options={(filteredLocationsTwo.length > 0
                  ? filteredLocationsTwo
                  : filteredLocations
                ).map(loc => ({
                  label: `${loc.name}`,
                  value: loc.id
                }))}
                allowClear
                maxTagCount={1}
                onDropdownVisibleChange={setOpenLocation}
                open={openLocation}
                style={{
                  backgroundColor: 'transparent',
                  width: '250px',
                  cursor: 'pointer',
                  height: '35px',
                  borderRadius: '20px'
                }}
              />
            </BorderIpass>
          </div>
        </div>
        <div className="button">
          <ButtonB style={{ width: '25%' }} onClick={handleConfirmDates} text="Registrar" />
        </div>
        <div className="sub-container">
          <div className="sub-title-reserve">
            <div className="left-component"></div>
            <div className="center-component">
              <h3>CONFIRMAR RESERVA</h3>
            </div>
          </div>
          <MyTextArea
            personClassName="fullHeight"
            colorBg={'#00000000'}
            BorderSize={2}
            borderColor
            value={confirmationMessage}
            onChange={val =>
              handleChange({ name: 'sendMessage', value: val?.toString() ?? '' })
            }
            placeholder="Confirmacion de reserva: "
            style={{
              height: '170px',
              resize: 'none',
              paddingBottom: '70px',
              fontSize: '16px',
              color: '#dabdf5'
            }}
          />
          <div className="button-reserve">
            <IconButton onClick={handleReset}>
              <RefreshIcon style={{ color: 'white', fontSize: '35px' }} />
            </IconButton>
            <ButtonB
              text={initialValue ? 'Actualizar' : 'Confirmar'}
              style={{
                textAlign: 'center',
                marginLeft: '140px',
                marginRight: 'auto',
                width: '25%'
              }}
              onClick={handleSubmit}
              disabled={!isAvailable}
              PopUp={{
                title: 'CONFIRMACIÓN',
                description: popUpMessage,
                acceptText: 'ACEPTAR',
                rejectText: 'CANCELAR'
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        borderColor={true}
        position="left"
        style_Subcontainer={{ padding: '1rem' }}
        styleContainerContent={{ padding: '2px', margin: '1px' }}
        styleContainer={{
          width: '450px',
          height: '210px',
          padding: '0px',
          overflow: 'hidden',
          margin: '2px',
          borderRadius: '15px'
        }}
        styleClose={{ fontSize: '20px', right: '25px', top: '27px', display: 'none' }}
      >
        <div className="container-reserve-confirm">
          <h3>Información de la Reserva</h3>

          {isAvailable ? (
            <>
              <p>{confirmationReserveMessage.map(item => item.msg).join('\n')}</p>
            </>
          ) : (
            <div className="unavailable-message">
              <p>{unavailableMessage}</p>
            </div>
          )}

          <ButtonB
            style={{ marginTop: '20px', width: '40%' }}
            onClick={() => setIsConfirmationModalOpen(false)}
            text="Aceptar"
          />
        </div>
      </Modal>
    </Modal>
  )
}

export default ModalCreateReserve

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ButtonB } from '../../components/Form/ButtonB'
import Modal from '../../components/GeneralComponents/Modal'
import { LightTooltip } from '../../components/tooltips/text'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import './style.scss'
import InputLarge from '../Form/InputLarge'
import PhoneInputLarge from '../Form/PhoneInputLarge'
import { useLoading } from '../../Provider/LoadingProvider'
import { createCertifier, updateCertifier, getCertifiers } from '../../services/certifier'
import { CertifierCreate, Certifier } from '../../types/certifier'
import InputDark from '../Form/InputDark'

interface CertifierModalProps {
  isOpen: boolean
  onClose: () => void
  initialValue?: Certifier
  onSuccess: () => void
}

const dataDefault = {
  name: '',
  phone1: '',
  phone2: '',
  email: '',
  contact1: '',
  contact2: '',
  url: '',
  user: '',
  space1: '',
  space2: '',
  notes: '',

  nit: '',
  transmitterName: '',
  codeEstablishments: '',
  tradeName: '',
  Address: '',
  ZipCode: '',
  town: '',
  Department: '',
  abbrCountry: ''
}

const CertifierModal: React.FC<CertifierModalProps> = ({
  isOpen,
  onClose,
  initialValue,
  onSuccess
}) => {
  const { id } = useParams<{ id: string }>()

  const { startLoading, stopLoading } = useLoading()

  const [formData, setFormData] = useState<CertifierCreate>(initialValue || dataDefault)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleChange = <K extends keyof CertifierCreate>(params: {
    name: K
    value: CertifierCreate[K]
  }) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [params.name]: params.value
    }))
  }

  const handleSubmit = async () => {
    try {
      startLoading()

      if (initialValue) {
        await updateCertifier(initialValue.id as string, formData)
      } else {
        await createCertifier(id as string, formData)
      }

      if (onSuccess) {
        onSuccess()
      }

      if (onClose) {
        onClose()
      }
    } catch (error) {
      setErrorMessage('Ocurrió un error al guardar los datos.')
    } finally {
      stopLoading()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      borderColor={true}
      style_Subcontainer={{ padding: '1rem' }}
      styleContainerContent={{ padding: '2px', margin: '1px' }}
      styleContainer={{
        width: '90%',
        height: '100%',
        padding: '0px',
        overflow: 'hidden',
        margin: '2px',
        borderRadius: '15px'
      }}
      styleClose={{ fontSize: '20px', right: '25px', top: '42px' }}
    >
      <div className="container-certifier">
        <div className="title-certifier">
          <div className="left-component">
            <LightTooltip
              title="Integrador
                      oficial para
                      generación de
                      documentos
                      fiscales"
              placement="right"
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#261a3a',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid  #5a1fd1',
                  boxShadow: 'none'
                }
              }}
            >
              <HelpOutlineIcon
                style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }}
              />
            </LightTooltip>
          </div>
          <div className="center-component">
            <h3>Certificador</h3>
          </div>
        </div>
        <div className="buttons-certifier">
          <InputLarge
            placeholder={'Nombres'}
            key={'name'}
            label={'Nombres'}
            value={formData.name}
            onChange={e => handleChange({ name: 'name', value: e.target.value })} //TODO: cambiar por el nombre del campo
          />

          <PhoneInputLarge
            placeholder={'Telefono 1'}
            key={'phone1'}
            label={'Telefono 1'}
            value={`${formData.phone1}`}
            onChange={val => handleChange({ name: 'phone1', value: val?.toString() ?? '' })}
          />
          <PhoneInputLarge
            placeholder={'Telefono 2'}
            key={'phone2'}
            label={'Telefono 2'}
            value={`${formData.phone2}`}
            onChange={val => handleChange({ name: 'phone2', value: val?.toString() ?? '' })}
          />

          <InputLarge
            placeholder={'Correo electronico'}
            key={'email'}
            label={'Correo electronico'}
            value={formData.email}
            onChange={e => handleChange({ name: 'email', value: e.target.value })}
          />
          <InputLarge
            placeholder={'Contacto 1'}
            key={'contact1'}
            label={'Contacto 1'}
            value={formData.contact1}
            onChange={e => handleChange({ name: 'contact1', value: e.target.value })}
          />
          <InputLarge
            placeholder={'Contacto 2'}
            key={'contact2'}
            label={'Contacto 2'}
            value={formData.contact2}
            onChange={e => handleChange({ name: 'contact2', value: e.target.value })}
          />

          <InputLarge
            placeholder={'Notas'}
            key={'notes'}
            label={'Notas'}
            className="largue3"
            value={formData.notes}
            onChange={e => handleChange({ name: 'notes', value: e.target.value })}
          />
        </div>

        <div className="title-certifier">
          <div className="left-component">
            <LightTooltip
              title="Integrador
                      oficial para
                      generación de
                      documentos
                      fiscales"
              placement="right"
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#261a3a',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid  #5a1fd1',
                  boxShadow: 'none'
                }
              }}
            >
              <HelpOutlineIcon
                style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }}
              />
            </LightTooltip>
          </div>
          <div className="center-component">
            <h3>Datos emisor</h3>
          </div>
        </div>

        <div className="buttons-certifier">
          <InputLarge
            placeholder={'Nit'}
            key={'nit'}
            label={'Nit'}
            value={formData.nit}
            onChange={e => handleChange({ name: 'nit', value: e.target.value })}
          />

          <InputLarge
            placeholder={'Nombre Emisor'}
            key={'transmitterName'}
            label={'Nombre Emisor'}
            className="largue2"
            value={formData.transmitterName}
            onChange={e => handleChange({ name: 'transmitterName', value: e.target.value })}
          />

          <InputLarge
            placeholder={'Codigo establecimiento'}
            key={'codeEstablishments'}
            label={'Codigo establecimiento'}
            value={formData.codeEstablishments}
            onChange={e => handleChange({ name: 'codeEstablishments', value: e.target.value })}
          />

          <InputLarge
            placeholder={'Nombre comercial'}
            key={'tradeName'}
            label={'Nombre comercial'}
            value={formData.tradeName}
            onChange={e => handleChange({ name: 'tradeName', value: e.target.value })}
          />

          <InputLarge
            placeholder={'Codigo postal'}
            key={'ZipCode'}
            label={'Codigo postal'}
            value={formData.ZipCode}
            onChange={e => handleChange({ name: 'ZipCode', value: e.target.value })}
          />

          <InputLarge
            placeholder={'Dirección'}
            key={'Address'}
            label={'Dirección'}
            className="largue3"
            value={formData.Address}
            onChange={e => handleChange({ name: 'Address', value: e.target.value })}
          />

          <InputLarge
            placeholder={'Municipio'}
            key={'town'}
            label={'Municipio'}
            value={formData.town}
            onChange={e => handleChange({ name: 'town', value: e.target.value })}
          />

          <InputLarge
            placeholder={'Departamento'}
            key={'Department'}
            label={'Departamento'}
            value={formData.Department}
            onChange={e => handleChange({ name: 'Department', value: e.target.value })}
          />

          <InputLarge
            placeholder={'Pais abreviado'}
            key={'abbrCountry'}
            label={'Pais abreviado'}
            value={formData.abbrCountry}
            onChange={e => handleChange({ name: 'abbrCountry', value: e.target.value })}
          />
        </div>

        <div className="title-certifier">
          <div className="left-component">
            <LightTooltip
              title="Integrador
                      oficial para
                      generación de
                      documentos
                      fiscales"
              placement="right"
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#261a3a',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid  #5a1fd1',
                  boxShadow: 'none'
                }
              }}
            >
              <HelpOutlineIcon
                style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }}
              />
            </LightTooltip>
          </div>
          <div className="center-component">
            <h3>Configuracion API</h3>
          </div>
        </div>

        <div className="input-dark-large">
          <InputLarge
            placeholder={'Url Web Service'}
            key={'url'}
            label={'Url Web Service'}
            value={formData.url}
            onChange={e => handleChange({ name: 'url', value: e.target.value })}
          />
          <InputLarge
            placeholder={'Usuario'}
            key={'user'}
            label={'Usuario'}
            value={formData.user}
            onChange={e => handleChange({ name: 'user', value: e.target.value })}
          />
          <InputLarge
            placeholder={'Campo A'}
            key={'space1'}
            label={'Campo A'}
            value={formData.space1}
            onChange={e => handleChange({ name: 'space1', value: e.target.value })}
          />
          <InputLarge
            placeholder={'Campo B'}
            key={'space2'}
            label={'Campo B'}
            value={formData.space2}
            onChange={e => handleChange({ name: 'space2', value: e.target.value })}
          />
        </div>
        <div className="button-certifier">
          <ButtonB
            text={initialValue ? 'Actualizar' : 'Guardar'}
            style={{ width: '20%', marginBottom: '10px' }}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CertifierModal

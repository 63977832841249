import { Select_Option_Resident, typeMessage, typesearch } from '../types/Message_center'
import axiosInstance from './axiosInstance'
import axiosWhatsApp from './axiosInstanceWAPP'

export const get_all_SMG = async (filter?: typesearch): Promise<typeMessage[]> => {
  try {
    const response = await axiosInstance.post(`/api/messages/get_msg`, filter)

    return response.data
  } catch (error) {
    throw error
  }
}

export const get_all_Residents = async ({
  search
}: {
  search?: string[]
}): Promise<Select_Option_Resident[]> => {
  try {
    const response = await axiosInstance.get(`/api/destinations/all_residents/`, {
      params: { search }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export const sendMessageWhatsapp = async (params: FormData): Promise<string> => {
  try {
    const response = await axiosWhatsApp.post(`/api/messages/send_messages`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export const firmate_aws = async ({ url }: { url: string }): Promise<string> => {
  try {
    const response = await axiosInstance.get(`/api/messages/firmate_aws/`, {
      params: { url }
    })

    return response.data
  } catch (error) {
    throw error
  }
}


export const generateReportMessage = async ({
  params
}: {
  params: any
}): Promise<{ url: string }> => {
  try {
    const response = await axiosInstance.get(`/api/messages/get_report/`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de equipos:', error)
    throw error
  }
}
import React from 'react'
import { useLoading } from '../../Provider/LoadingProvider'
import { useAuth } from '../../Provider/AuthProvider'
import { typesearch } from '../../types/Message_center'
import { LightTooltip } from '../../components/tooltips/text'
import { IconButton } from '@mui/material'
import { GridOn } from '@mui/icons-material'
import { Fade } from '@mui/material'
import { generateReportMessage } from '../../services/Message'

interface ExportToExcelProps {
  search?: typesearch
  style?: React.CSSProperties
}

export const ExportToExcelMessage: React.FC<ExportToExcelProps> = ({ search }) => {
  const { startLoading, stopLoading } = useLoading()
  const { token } = useAuth()

  const handleExport = async () => {
    try {
      startLoading()

      const value = await generateReportMessage({
        params: {
          ...search
        }
      })

      // Hacer fetch para obtener el archivo
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_BACKEND}/report/${value.url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = value.url // Nombre del archivo descargado
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      // Liberar el objeto URL
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(`error al crear reporte`, error) // Maneja el error
    } finally {
      stopLoading()
    }
  }

  return (
    <LightTooltip title="Descargar Reporte" TransitionComponent={Fade}>
      <IconButton
        onClick={handleExport}
        style={{ border: 'none', padding: 0, margin: 0 }}
        disableRipple
        disableFocusRipple
      >
        <GridOn style={{ color: 'white', fontSize: 30, marginBottom: '4px' }} />
      </IconButton>
    </LightTooltip>
  )
}

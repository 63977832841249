import { listGraphPayment, listPaymentsResident, PaymentDetails } from '../types/Payments'
import { PaymentConcept, statement } from '../types/Payments'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listPaymentConcept = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<PaymentConcept>> => {
  try {
    const response = await axiosInstance.get(`/api/payments/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en listPaymentConcept:', error)
    throw error
  }
}

export const createPaymentConcept = async (params: FormData): Promise<PaymentConcept> => {
  try {
    const response = await axiosInstance.post(`/api/payments`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en createPaymentConcept:', error)
    throw error
  }
}

export const updatePaymentConcept = async (
  id: string,
  params: FormData
): Promise<PaymentConcept> => {
  try {
    const response = await axiosInstance.post(`/api/payments/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en updatePaymentConcept:', error)
    throw error
  }
}

export const setGlobalConcepts = async (id: string): Promise<PaymentConcept> => {
  try {
    const response = await axiosInstance.post(`/api/payments/setglobal/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en setGlobalConcepts:', error)
    throw error
  }
}

export const deletePaymentConcept = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/payments/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en deletePaymentConcept:', error)
    throw error
  }
}

export const getDasboardPayments = async (
  id: string,
  dataSearch: any
): Promise<listPaymentsResident[]> => {
  try {
    const response = await axiosInstance.get(`/api/payments/dashboard/list/${id}`, {
      params: { ...dataSearch }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard payment Get getDasboardPayments', error)
    throw error
  }
}

export const getDasboardGraphPayments = async (id: string): Promise<listGraphPayment> => {
  try {
    const response = await axiosInstance.get(`/api/payments/dashboard/graph/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard payment Get getDasboardGraphPayments', error)
    throw error
  }
}

export const getstatement = async (id: string, clientId: string): Promise<statement> => {
  try {
    const response = await axiosInstance.get(`/api/payments/getstatement/${id}/${clientId}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error getstatement payment Get', error)
    throw error
  }
}

export const getPaymentDetail = async ({
  residentId,
  month
}: {
  residentId: string
  month: string
}): Promise<PaymentDetails> => {
  try {
    const response = await axiosInstance.get(
      `/api/payments/getPaymentDetail/${residentId}/${month}`
    )

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error dashboard payment Get', error)
    throw error
  }
}

export const processPayment = async ({
  paymentId,
  type,
  message,
  amount
}: {
  paymentId: string
  type: 'aprovado' | 'rechazado'
  message?: string
  amount: number
}): Promise<void> => {
  try {
    await axiosInstance.post(`/api/payments/processPayment/${paymentId}`, {
      message,
      type,
      amount
    })

    return
  } catch (error) {
    // Manejo de errores
    console.error('Error en processPayment:', error)
    throw error
  }
}

export const getStatementPdf = async (id: string, month?: string): Promise<string> => {
  try {
    const response = await axiosInstance.get(`/api/payments/getstatementPdf/${id}`, {
      params: { month }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error getstatement payment Get getStatementPdf', error)
    throw error
  }
}

import React from 'react'
import './styles.scss'

import { LightTooltip } from '../tooltips/text'
import { Fade } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'

interface TitlePagesProps {
  title?: string
  tooltip: string
  classContainer?: string
}

export const TitlePages: React.FC<TitlePagesProps> = ({ title, tooltip, classContainer }) => {
  return (
    <div className={`TitlePageContainer ${classContainer}`}>
      <h1>{`${title}`}</h1>
      <LightTooltip
        title={`${tooltip}`}
        TransitionComponent={Fade}
        placement="right"
        sx={{
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'transparent',
            color: 'white',
            fontSize: '16px',
            padding: '12px',
            border: 'none',
            cursor: 'pointer',
            ':hover': {
              // backgroundColor: 'transparent',
              // color: 'darkslategray',
              fontSize: '16px',
              padding: '12px',
              border: 'none',
              cursor: 'pointer'
            }
          }
        }}
      >
        <FontAwesomeIcon icon={faCircleQuestion as any} />
      </LightTooltip>
    </div>
  )
}
//IconDefinition

import React, { FC, useEffect, useState, useRef } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Pagination } from '../../../types/types'
import Modal from '../../GeneralComponents/Modal'
import Table from '../../Table'
import { deleteStaff, listStaffClient, NotificationStaff } from '../../../services/staff'
import { Staff } from '../../../types/staff'
import DeleteButton from '../../Table/components/DeleteButton'
import { columnsStaff } from './Columns'
import FormStaff from './Form'
import { Visibility, Notifications, NotificationsOff } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import './styles.scss'

const ReceptionistByClientTable: FC<{ id: string }> = ({ id }) => {
  const [data, setData] = useState<Pagination<Staff>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Staff | undefined>(undefined)
  const isFirstRender = useRef(true)

  useEffect(() => {
    getDataStaff('', 1)
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const delayDebounceFn = setTimeout(() => {
      getDataStaff(search, 1)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onCloseModal = () => {
    setSelected(undefined)
    setOpen(false)
  }
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteStaff(id)
      getDataStaff(search, data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleView = (value: Staff) => {
    setSelected(value)
  }

  const toggleNotification = async (StaffId: string, notification: boolean) => {
    try {
      startLoading()
      await NotificationStaff(StaffId, !notification)
      await getDataStaff(search, data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const Actions = ({ id, item }: { id: string; item: Staff }) => {
    return (
      <div className="actionsContainer">
        <div onClick={() => toggleNotification(item?.id, item.enableNotification)}>
          {item.enableNotification ? (
            <Notifications style={{ color: 'green' }} />
          ) : (
            <NotificationsOff style={{ color: 'red' }} />
          )}
        </div>
        <Tooltip
          title="Actualizar"
          placement="top-end"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#261a3a', // Color de fondo
                color: 'white', // Color del texto
                fontSize: '14px', // Tamaño del texto
                padding: '8px 12px', // Espaciado interno
                borderRadius: '8px', // Bordes redondeados
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
              }
            }
          }}
        >
          <IconButton onClick={() => handleView(item as Staff)}>
            <Visibility style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>

        <Tooltip
          title="Eliminar"
          placement="top-end"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#261a3a', // Color de fondo
                color: 'white', // Color del texto
                fontSize: '14px', // Tamaño del texto
                padding: '8px 12px', // Espaciado interno
                borderRadius: '8px', // Bordes redondeados
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
              }
            }
          }}
        >
          <IconButton style={{ padding: '0px', margin: '0px' }}>
            <DeleteButton OnDelete={() => handleDelete(id)} />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const handlePageChange = (newPage: number) => {
    getDataStaff(search, newPage)
  }

  const getDataStaff = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const staff = await listStaffClient({
        id,
        params: { page, search: searchValue, limit }
      })
      setData(staff)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = () => {
    setOpen(false)
    getDataStaff(search, data.currentPage)
  }

  useEffect(() => {
    if (selected) {
      setOpen(true)
    }
  }, [selected])
  return (
    <div className="container-worker">
      <div className="title">
        <h2>Trabajadores</h2>
      </div>
      <div className="table-worker">
        <Table
          onPageChange={handlePageChange}
          onChangeSearch={setSearch}
          pagination={data}
          handleOpenModal={handleOpenModal}
          columns={columnsStaff}
          Actions={Actions as any}
          itemsPerPage={limit}
        />
      </div>
      <Modal
        isOpen={open}
        onClose={onCloseModal}
        borderColor={true}
        style_Subcontainer={{ padding: '1rem' }}
        styleContainerContent={{ padding: '2px', margin: '1px' }}
        styleContainer={{
          width: '620px',
          height: '620px',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: '23px', top: '47px' }}
      >
        <FormStaff initialValue={selected} clientId={id} onSubmit={handleSubmit} />
      </Modal>
    </div>
  )
}

export default ReceptionistByClientTable

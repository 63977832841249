import React, { useState, useEffect } from 'react'
import './style.scss'
import { useLoading } from '../../Provider/LoadingProvider'
import FileUpload from '../../components/Form/FileUpload'
import Switch from '../../components/Form/Switch'
import { createSegments, updateSegments } from '../../services/Segments'
import { Segments } from '../../types/Segments'
import { ButtonB } from '../../components/Form/ButtonB'
import { LabelANTDLarge } from '../../components/Form/labels/LabelLarge'
import { LightTooltip } from '../../components/tooltips/text'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export interface ReportModalProps {
  title: string
  initialValue?: Segments
  onClose: () => void
  onSuccess?: () => void
}

export const SetSegment: React.FC<ReportModalProps> = ({
  title,
  onClose,
  initialValue,
  onSuccess
}) => {
  const { startLoading, stopLoading } = useLoading()
  const [errorText, setErrorText] = useState<string | null>(null)
  const [errorFile, setErrorFile] = useState<string | null>(null)
  const [preview, setPreview] = useState<string | undefined>(undefined)

  const [formData, setFormData] = useState({
    name: initialValue?.name || '',
    isPosPayment: initialValue?.isPosPayment ?? false,
    icon: initialValue?.icon || ''
  })

  useEffect(() => {
    if (typeof formData.icon === 'string') {
      setPreview(formData.icon)
    } else if (formData.icon) {
      const objectUrl = URL.createObjectURL(formData.icon)
      setPreview(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [formData.icon])

  const handleChange = (name: keyof typeof formData, value: any) => {
    setFormData(prev => ({
      ...prev,
      [name]: name === 'isPosPayment' ? Boolean(value) : value
    }))
  }

  const handleFileChange = (file?: File | string) => {
    if (file instanceof File) {
      const allowedTypes = ['image/jpeg', 'image/png']
      const maxFileSize = 16 * 1024 * 1024 // 16 MB

      if (!allowedTypes.includes(file.type)) {
        setErrorFile('Tipo de archivo no permitido.')
        return
      }
      if (file.size > maxFileSize) {
        setErrorFile('El archivo supera el límite de 16 MB.')
        return
      }

      setErrorFile(null)
    }

    handleChange('icon', file) // Puede ser un File o una URL (string)
  }
  const handleSubmit = async () => {
    startLoading()

    if (!formData.name || formData.name.length < 5) {
      setErrorText('Se requiere mínimo 5 caracteres')
      stopLoading()
      return
    }
    setErrorText(null)

    const data = new FormData()
    data.append('name', formData.name.trim())
    data.append('isPosPayment', formData.isPosPayment.toString())
    if (formData.icon && typeof formData.icon !== 'string') {
      data.append('icon', formData.icon)
    }

    try {
      if (initialValue) {
        await updateSegments(initialValue.id, data)
      } else {
        await createSegments(data)
      }
      onSuccess?.()
      onClose()
    } catch (error) {
      console.error('Error al guardar segmento:', error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="deleteConceptContainer">
      <div className="title-segment">
        <div className="left-component">
          <LightTooltip
            title="Pendiente texto"
            placement="right"
            sx={{
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#261a3a',
                color: '#fff',
                fontSize: '14px',
                padding: '10px',
                borderRadius: '8px',
                border: '1px solid  #5a1fd1',
                boxShadow: 'none'
              }
            }}
          >
            <HelpOutlineIcon style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }} />
          </LightTooltip>
        </div>
        <div className="center-component">
          <h3>SEGMENTOS</h3>
        </div>
      </div>

      <LabelANTDLarge
        prefix="NOMBRE : "
        borderColor
        value={formData.name}
        onChange={val => handleChange('name', val?.toString() ?? '')}
      />

      <FileUpload
        personClassName="FileAreaSegment"
        minHeight="100px"
        label="ICONO (arrastra/suelta/busca)"
        value={formData.icon}
        onChange={handleFileChange}
        accept="image/jpeg,image/png"
      />

      <div className="SelectValue">
        <p>PRE PAGO</p>
        <Switch
          activeColor={'#5a1fd1'}
          ColorDiscative={'#6d6697'}
          value={formData.isPosPayment}
          onChange={() => handleChange('isPosPayment', !formData.isPosPayment)}
        />
        <p>POST PAGO</p>
      </div>

      <ButtonB
        className="button"
        text={initialValue ? 'Actualizar' : 'Guardar'}
        onClick={handleSubmit}
      />
    </div>
  )
}

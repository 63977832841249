import axiosInstance from './axiosInstance'
import { Pagination, PaginationQuery } from '../types/types'
import { SegmentsCreate, Segments } from '../types/Segments'

export const listSegments = async ({
  params
}: {
  params: PaginationQuery
}): Promise<Pagination<Segments>> => {
  try {
    const response = await axiosInstance.get(`/api/reserves/segments/`, {
      params
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en listSegments :', error)
    throw error
  }
}

export const createSegments = async (params: FormData): Promise<Segments> => {
  try {
    const response = await axiosInstance.post(`/api/reserves/segments`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en createSegments :', error)
    throw error
  }
}
export const updateSegments = async (id: string, params: FormData): Promise<Segments> => {
  try {
    const response = await axiosInstance.put(`/api/reserves/segments/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en  updateSegments :', error)
    throw error
  }
}
export const deleteSegments = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/reserves/segments/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en deleteSegments :', error)
    throw error
  }
}
export const updatePatchSegments = async (
  id: string,
  newStatus: boolean
): Promise<Segments> => {
  try {
    const response = await axiosInstance.patch(`/api/reserves/segments/${id}`, {
      newStatus
    })

    return response.data
  } catch (error) {
    console.error('Error en updateSegments:', error)
    throw error
  }
}

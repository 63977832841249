import React, { useState } from 'react'
import { useLoading } from '../../Provider/LoadingProvider'
import { LabelANTD } from '../../components/Form/labels/oneLine'
import { MyTextArea } from '../../components/Form/labels/MyTextArea'
import { InputNumber } from '../../components/Form/inputNumber'
import { createReasonAndRules, updateReasonAndRules } from '../../services/reasonAndRules'
import { ReasonAndRules, ReasonAndRulesCreate } from '../../types/reasonAndRules'
import Button from '../../components/Form/Button'
import './style.scss'

export interface ReportModalProps {
  title: string
  initialValue?: ReasonAndRules
  onClose?: () => void
  onSuccess?: () => void
}

export const SetReasonAndRules: React.FC<ReportModalProps> = ({
  initialValue,
  title,
  onClose,
  onSuccess
}) => {
  const { startLoading, stopLoading } = useLoading()

  const [formData, setFormData] = useState<ReasonAndRulesCreate>(
    initialValue || {
      name: '',
      rule: 1,
      penalty: 1,
      basis: '',
      argument: ''
    }
  )

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleSubmit = async () => {
    try {
      startLoading()
      const formDataSend = new FormData()
      formDataSend.append('name', formData.name.trim())
      formDataSend.append('rule', formData.rule.toString().trim())
      formDataSend.append('penalty', formData.penalty.toString().trim())
      formDataSend.append('basis', formData.basis.trim())
      formDataSend.append('argument', formData.argument.trim())

      if (initialValue) {
        await updateReasonAndRules(initialValue.id, formDataSend)
      } else {
        await createReasonAndRules(formDataSend)
      }

      if (onSuccess) {
        onSuccess()
      }

      if (onClose) {
        onClose()
      }
    } catch (error) {
      setErrorMessage('Ocurrió un error al guardar los datos.')
    } finally {
      stopLoading()
    }
  }

  const handleChange = <K extends keyof ReasonAndRulesCreate>(params: {
    name: K
    value: ReasonAndRulesCreate[K]
  }) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [params.name]: params.value
    }))
  }

  return (
    <div className="Container_reason_and_rules">
      <p className="title">{title}</p>

      <LabelANTD
        prefix="Nombre : "
        borderColor
        value={formData.name}
        onChange={val => handleChange({ name: 'name', value: val?.toString() ?? '' })}
      />

      <div className="sub-container-reason-and-rules">
        <InputNumber
          SelectStyles={SelectStyles}
          label="Define Regla:"
          showDecimals={false}
          value={`${formData.rule}`}
          setAmount={val => handleChange({ name: 'rule', value: Number(val) || 0 })}
          onChange={val => handleChange({ name: 'rule', value: Number(val) || 0 })}
        />

        <InputNumber
          SelectStyles={SelectStyles}
          label="Multa regla:"
          prefix={'Q'}
          value={`${formData.penalty.toFixed(2)}`}
          setAmount={val => handleChange({ name: 'penalty', value: Number(val) || 0 })}
          onChange={val => handleChange({ name: 'penalty', value: Number(val) || 0 })}
        />
      </div>

      <MyTextArea
        personClassName="fullHeight"
        colorBg={'#00000000'}
        BorderSize={2}
        borderColor
        value={formData.basis}
        onChange={val => handleChange({ name: 'basis', value: val?.toString() ?? '' })}
        placeholder="Fundamento: "
        style={{
          height: '100%',
          resize: 'none',
          marginBottom: '30px',
          paddingBottom: '50px',
          fontSize: '16px',
          color: '#dabdf5'
        }}
      />

      <MyTextArea
        personClassName="fullHeight"
        colorBg={'#00000000'}
        BorderSize={2}
        borderColor
        placeholder="Argumento multa: "
        value={formData.argument}
        onChange={val => handleChange({ name: 'argument', value: val?.toString() ?? '' })}
        style={{
          height: '100%',
          resize: 'none',
          marginBottom: '30px',
          paddingBottom: '50px',
          fontSize: '16px',
          color: '#dabdf5'
        }}
      />

      {errorMessage && <span className="error-text">{errorMessage}</span>}

      <Button className="button" text="GUARDAR" type="button" onClick={handleSubmit} />
    </div>
  )
}

const SelectStyles: React.CSSProperties = {
  paddingRight: '5px'
}

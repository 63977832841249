import { Proprietary, ProprietaryCreate } from '../types/proprietarys'
import axiosInstance from './axiosInstance'

export const getProprietarys = async (id: string): Promise<Proprietary> => {
  try {
    const response = await axiosInstance.get(`/api/proprietarys/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de propietraios:', error)
    throw error
  }
}

export const createProprietary = async (
  id: string,
  params: FormData
): Promise<ProprietaryCreate> => {
  try {
    const response = await axiosInstance.post(`/api/proprietarys/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la creación de propietraio:', error)
    throw error
  }
}

export const createProprietarys = async (file: FormData): Promise<Proprietary> => {
  try {
    const response = await axiosInstance.post('/api/proprietarys/massive', file, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la creación de propietraio:', error)
    throw error
  }
}

export const updateProprietarys = async (
  id: string,
  params: FormData
): Promise<Proprietary> => {
  try {
    const response = await axiosInstance.put(`/api/proprietarys/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error en la creación de propietraio:', error)
    throw error
  }
}

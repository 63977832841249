import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ButtonB } from '../../components/Form/ButtonB'
import Modal from '../../components/GeneralComponents/Modal'
import { LightTooltip } from '../../components/tooltips/text'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useLoading } from '../../Provider/LoadingProvider'

import './style.scss'
import { ProprietaryCreate, Proprietary } from '../../types/proprietarys'
import {
  createProprietary,
  getProprietarys,
  updateProprietarys
} from '../../services/propietaries'
import InputLarge from '../Form/InputLarge'
import PhoneInputLarge from '../Form/PhoneInputLarge'

interface PropietaryModalProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  initialValue?: Proprietary
}

const dataDefault = {
  name: '',
  lastName: '',
  phone1: '',
  phone2: '',
  email: '',
  nit: ''
}

const PropietaryModal: React.FC<PropietaryModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  initialValue
}) => {
  const { id } = useParams<{ id: string }>()
  console.log(id)
  const { startLoading, stopLoading } = useLoading()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [formData, setFormData] = useState<ProprietaryCreate>(initialValue || dataDefault)

  const handleChange = <K extends keyof ProprietaryCreate>(params: {
    name: K
    value: ProprietaryCreate[K]
  }) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [params.name]: params.value
    }))
  }
  const handleSubmit = async () => {
    try {
      startLoading()
      const formDataSend = new FormData()
      formDataSend.append('name', (formData.name ?? '').toString().trim())
      formDataSend.append('phone1', (formData.phone1 ?? '').toString().trim())
      formDataSend.append('phone2', (formData.phone2 ?? '').toString().trim())
      formDataSend.append('email', (formData.email ?? '').toString().trim())
      formDataSend.append('nit', (formData.nit ?? '').toString().trim())
      formDataSend.append('lastName', (formData.lastName ?? '').toString().trim())

      if (initialValue) {
        await updateProprietarys(initialValue.id as string, formDataSend)
      } else {
        await createProprietary(id as string, formDataSend)
      }

      if (onSuccess) {
        onSuccess()
      }

      if (onClose) {
        onClose()
      }
    } catch (error) {
      setErrorMessage('Ocurrió un error al guardar los datos.')
    } finally {
      stopLoading()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      borderColor={true}
      style_Subcontainer={{ padding: '1rem' }}
      styleContainerContent={{ padding: '2px', margin: '1px' }}
      styleContainer={{
        width: '650px',
        height: '470px',
        padding: '0px',
        overflow: 'hidden',
        margin: '2px',
        borderRadius: '15px'
      }}
      styleClose={{ fontSize: '20px', right: '25px', top: '42px' }}
    >
      <div className="container-propietary">
        <div className="title-propietary">
          <div className="left-component">
            <LightTooltip
              title="Información de
                    propietarios
                    para contacto y
                    constancias
                    contables"
              placement="right"
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#261a3a',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid  #5a1fd1',
                  boxShadow: 'none'
                }
              }}
            >
              <HelpOutlineIcon
                style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }}
              />
            </LightTooltip>
          </div>
          <div className="center-component">
            <h3>PROPIETARIOS</h3>
          </div>
        </div>
        <div className="buttons-propietary">
          <InputLarge
            placeholder={'Nombres'}
            key={'name'}
            label={'Nombres'}
            value={formData.name}
            onChange={e => handleChange({ name: 'name', value: e.target.value })}
          />
          <InputLarge
            placeholder={'Apellidos'}
            key={'lastName'}
            label={'Apellidos'}
            value={formData.lastName}
            onChange={e => handleChange({ name: 'lastName', value: e.target.value })}
          />
          <PhoneInputLarge
            placeholder={'Telefono 1'}
            key={'phone1'}
            label={'Telefono 1'}
            value={formData.phone1}
            onChange={val => handleChange({ name: 'phone1', value: val?.toString() ?? '' })}
          />
          <PhoneInputLarge
            placeholder={'Telefono 2'}
            key={'phone2'}
            label={'Telefono 2'}
            value={formData.phone2}
            onChange={val => handleChange({ name: 'phone2', value: val?.toString() ?? '' })}
          />

          <InputLarge
            placeholder={'Correo electronico'}
            key={'email'}
            label={'Correo electronico'}
            value={formData.email}
            onChange={e => handleChange({ name: 'email', value: e.target.value })}
          />
          <InputLarge
            placeholder={'NIT'}
            key={'nit'}
            label={'NIT'}
            value={formData.nit}
            onChange={e => handleChange({ name: 'nit', value: e.target.value })}
          />
        </div>
        <div className="button-propietary">
          <ButtonB
            text={initialValue ? 'Actualizar' : 'Guardar'}
            style={{ width: '20%' }}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  )
}

export default PropietaryModal

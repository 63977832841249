import React, { ReactNode, useState } from 'react'
import PopUpConfirm from '../Table/components/PopupConfirm'
import './styles.scss'

interface PopUpProps {
  title: string
  description?: string
  acceptText: string
  rejectText: string
}

interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  personClassName?: string
  children: ReactNode
  status?: 'Normal' | 'Disable' | 'Error' | 'NO_BORDER'
  margin?: string | number
  styleInner?: React.CSSProperties
  PopUp?: PopUpProps
  onClick?: (event?: any) => void
}

export const BorderIpass: React.FC<LayoutProps> = ({
  children,
  styleInner,
  status = 'Normal',
  margin = 1,
  personClassName = '',
  PopUp,
  onClick,
  ...props
}) => {
  const [showPopUp, setShowPopUp] = useState(false)

  const handleClick = () => {
    if (PopUp) {
      setShowPopUp(true)
    } else {
      onClick?.()
    }
  }

  const handleAccept = () => {
    setShowPopUp(false)
    onClick?.() // Ejecuta la acción después de aceptar
  }

  const handleReject = () => {
    setShowPopUp(false)
  }

  const classStatus =
    status === 'Disable'
      ? 'borderAnimateIpassDisable'
      : status === 'Error'
      ? 'borderAnimateIpassError'
      : 'borderAnimateIpass'

  return (
    <div className={`${classStatus} border ${personClassName}`} {...props}>
      <div className="inner" style={{ margin: margin, ...styleInner }} onClick={handleClick}>
        {children}
      </div>

      {/* Usa PopUpConfirm en lugar del pop-up interno */}
      {PopUp && (
        <PopUpConfirm
          isOpen={showPopUp}
          onClose={handleReject}
          handleAccept={handleAccept}
          handleReject={handleReject}
          title={PopUp.title}
          description={PopUp.description}
          acceptText={PopUp.acceptText}
          rejectText={PopUp.rejectText}
        />
      )}
    </div>
  )
}

import React, { FC, useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { typeQrEnum } from '../../../types/qr'
import { entries } from '../../../types/entries'
import { useAuth } from '../../../Provider/AuthProvider'

import { validate_qr_recepcion } from '../../../services/entries'
import { listcamerasFile, startCamerasLocaFile } from '../../../services/cameras'
import { AccessConfig } from '../../../types/AccessConfig'
import SocketReadQr from './readQr'

const FilesWachs: FC = () => {
  const { user, token } = useAuth()
  const [clientCameras, setclientCameras] = useState<AccessConfig[]>([])

  useEffect(() => {
    if (user?.role !== 'reception' || clientCameras.length === 0) return

    const socketFiles = io('http://localhost:8085') // Socket principal

    // Cuando se conecta, emitir una acción al servidor
    socketFiles.on('connect', () => {
      console.log('Conectado al servidor localxx')
      startCamerasLocaFile(clientCameras, token)
    })

    clientCameras.forEach(camera => {
      socketFiles.on(`QR-${camera.FileDNI1}`, (codeQr: string | false) => {
        console.log('entrada base64File por:', camera.FileDNI1)
        if (codeQr) {
          processDataqr(codeQr, camera.FileDNI1)
        }
      })

      socketFiles.on(`QR-${camera.FileFace}`, (codeQr: string | false) => {
        console.log('entrada base64File por:', camera.FileFace)
        if (codeQr) {
          processDataqr(codeQr, camera.FileFace)
        }
      })
    })

    // Limpiar los sockets cuando se desmonte el componente
    return () => {
      socketFiles.disconnect() // Desconectar el socket principal
    }
  }, [clientCameras, user, token])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      if (!user?.clientId) return
      const clientCameras = await listcamerasFile(user.clientId)
      setclientCameras(clientCameras)
    } catch (error) {
      console.error(error)
    }
  }

  const processDataqr = async (dataQr: string, file: string) => {
    if (dataQr) {
      const type = dataQr.slice(0, 4)
      if (Object.values(typeQrEnum).includes(type as typeQrEnum)) {
        const entrie = new entries()
        entrie.qrCode = dataQr
        entrie.cameraIn = file
        const params = {
          camera: file,
          code: dataQr,
          clientID: user?.clientId as string
        }
        try {
          await validate_qr_recepcion({ params, token })
        } catch (error) {}
      }
    }
  }

  return <>{<SocketReadQr userId={user?.clientId} />}</>
}

export default FilesWachs

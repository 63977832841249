import React, { useState, useEffect, useMemo } from 'react'

import { ButtonB } from '../../../components/Form/ButtonB'
import Modal from '../../../components/GeneralComponents/Modal'
import { LightTooltip } from '../../../components/tooltips/text'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useLoading } from '../../../Provider/LoadingProvider'

import './style.scss'
import InputLarge from '../../../components/Form/InputLarge'
import { MyTextArea } from '../../../components/Form/labels/MyTextArea'
import dayjs from 'dayjs'
import RangePickerLarge from '../../../components/Form/RangePickerLarge'
import { ReserveCreate, searchResident, Reserve } from '../../../types/reserve'
import { Location } from '../../../types/reserve'
import MultiSelectANTD from '../../../components/Form/multiSelet'
import { BorderIpass } from '../../../components/BorderIpass/Index'
import { createReserve, searchResidents, updateReserves } from '../../../services/reserve'
import { Pagination } from '../../../types/types'
import { Segments } from '../../../types/Segments'
import { InputNumber } from '../../../components/Form/inputNumber'
import { useDebounce } from '../../../hooks/useDebounce'

interface ModalCreateReserveProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  initialValue?: Reserve
  data?: Pagination<Segments>
  destinationCode?: string
}

const SetUpPlan: React.FC<ModalCreateReserveProps> = ({
  isOpen,
  onClose,
  onSuccess,
  initialValue,
  data,
  destinationCode
}) => {
  useEffect(() => {
    if (initialValue) {
      setDateRange({
        start: initialValue.date ? dayjs(initialValue.date, 'DD/MM/YY') : dayjs(),
        end: initialValue.date ? dayjs(initialValue.date, 'DD/MM/YY') : dayjs()
      })

      setFormData(prev => ({
        ...prev,
        date: initialValue.date
          ? dayjs(initialValue.date, 'DD/MM/YY').format('YYYY-MM-DD')
          : '',
        startHour: initialValue.startHour
          ? dayjs(initialValue.startHour, 'HH:mm:ss').format('HH:mm')
          : ''
      }))

      const segmentData = data?.data.find(seg => seg.id === initialValue.segmentId) ?? null
      if (segmentData) {
        setSelectedSegment(segmentData.name)
        if (segmentData.Locations && segmentData.Locations.length > 0) {
          setFilteredLocationsTwo(segmentData.Locations ?? [])
        } else {
          console.warn('🚨 segmentData.Locations está vacío o undefined')
        }
        const location = segmentData.Locations?.find(loc => loc.name === initialValue.location)
        if (location) {
          setSelectedLocation(location)
        }
      }
    }
  }, [initialValue, data])

  const [dateRange, setDateRange] = useState<{
    start?: dayjs.Dayjs | null
    end?: dayjs.Dayjs | null
  }>({ start: dayjs(), end: dayjs() })

  const { startLoading, stopLoading } = useLoading()
  const [residentName, setResidentName] = useState<searchResident | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [selectedSegment, setSelectedSegment] = useState<string>('')
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
  const [filteredLocations, setFilteredLocations] = useState<Location[]>([])
  const [filteredLocationsTwo, setFilteredLocationsTwo] = useState<Location[]>([])

  const [open, setOpen] = useState(false)
  const [openLocation, setOpenLocation] = useState(false)

  const [formData, setFormData] = useState<Reserve>(
    initialValue || {
      id: '',
      date: dayjs().format('YYYY-MM-DD'),
      startHour: '09:00',
      endHour: '',
      endTime: '',
      location: '',
      sendMessage: '',
      destinationCode: ''
    }
  )

  const debouncedCode = useDebounce(formData.code, 200)

  useEffect(() => {
    if (selectedSegment) {
      const segment = data?.data.find(seg => seg.id === selectedSegment[0])
      if (segment) {
        setFilteredLocations(segment.Locations ?? [])
      } else {
        setFilteredLocations([])
      }
    } else {
      setFilteredLocations([])
    }
  }, [selectedSegment])

  useEffect(() => {
    const fetchResidentName = async () => {
      const codeToSearch = destinationCode || debouncedCode

      if (codeToSearch) {
        try {
          const data = await searchResidents(codeToSearch)
          setResidentName(data)
        } catch (error) {
          setResidentName(null)
        }
      } else {
        setResidentName(null)
      }
    }

    fetchResidentName()
  }, [debouncedCode])

  useEffect(() => {
    if (residentName && residentName.name && residentName.lastName) {
      const codeToSet = residentName.code
      setFormData(prev => ({
        ...prev,
        code: codeToSet
      }))
    }
  }, [residentName])

  const handleChange = <K extends keyof ReserveCreate>(params: {
    name: K
    value: ReserveCreate[K]
  }) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [params.name]: params.value ?? ''
    }))
  }

  const handleSubmit = async () => {
    try {
      startLoading()

      if (!residentName?.id || !selectedLocation || !formData.date || !formData.startHour) {
        setErrorMessage('Por favor, completa todos los campos obligatorios.')
        return
      }

      const formattedDate = dayjs(formData.date).format('DD/MM/YYYY')
      let formattedStartHour = formData.startHour
      if (!formattedStartHour.includes(':')) {
        formattedStartHour = `${formattedStartHour}:00:00`
      } else if (formattedStartHour.length === 5) {
        formattedStartHour = `${formattedStartHour}:00`
      }
      const formDataSend = new FormData()
      formDataSend.append('locationId', selectedLocation.id)
      formDataSend.append('residentId', residentName.id)
      formDataSend.append('date', formattedDate)
      formDataSend.append('startHour', formattedStartHour)
      formDataSend.append('sendMessage', confirmationMessage)

      if (initialValue) {
        await updateReserves(initialValue.id, formDataSend)
      } else {
        await createReserve(formDataSend)
      }

      onSuccess()
    } catch (error) {
      setErrorMessage('Ocurrió un error al guardar la reserva.')
    } finally {
      stopLoading()
    }
  }

  const confirmationMessage = useMemo(() => {
    return `Hola ${residentName?.name || ''} ${residentName?.lastName || ''} . Tu reserva ${
      residentName?.code || ''
    } para ${selectedLocation?.name || ''} el día ${formData?.date || ''}  a ${
      formData?.startHour || ''
    } horas a quedado registrada exitosamente. Han sido cargados ${
      selectedLocation?.price?.toFixed(2) || ''
    } a tu estado de cuenta, los que deberás acreditar desde la opción de saldos y acreditamientos. Recuerda, son ${
      selectedLocation?.maxDuration || ''
    } horas de uso y observar las normas de convivencia acostumbradas. Podrás eliminar o cambiar esta reserva desde la opción MIS RESERVAS. Un placer servirte y que disfrutes tu evento.`
  }, [residentName, selectedLocation, formData])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      borderColor={true}
      style_Subcontainer={{ padding: '1rem' }}
      styleContainerContent={{ padding: '2px', margin: '1px' }}
      styleContainer={{
        width: '600px',
        height: '870px',
        padding: '0px',
        overflow: 'hidden',
        margin: '2px',
        borderRadius: '15px'
      }}
      styleClose={{ fontSize: '20px', right: '25px', top: '54px' }}
    >
      <div className="container-set-up-plan">
        <div className="title-set-up-plan">
          <div className="left-component">
            <LightTooltip
              title="Pendiente texto"
              placement="right"
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#261a3a',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid  #5a1fd1',
                  boxShadow: 'none'
                }
              }}
            >
              <HelpOutlineIcon
                style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }}
              />
            </LightTooltip>
          </div>
          <div className="center-component">
            <h3 className={initialValue ? 'title updating' : 'title creating'}>
              CONFIGURANDO PLAN
              {/* {initialValue ? 'ACTUALIZANDO RESERVA' : 'CREANDO RESERVA'} */}
            </h3>
          </div>
        </div>
        <div className="inputs-reserve">
          {/*  <div className="one-line">
            <InputLarge
              placeholder={'Codigo de reserva'}
              key={'code'}
              value={destinationCode ? destinationCode : formData.code}
              style={{ width: '30%' }}
              onChange={e => handleChange({ name: 'code', value: e.target.value })}
            />
            <InputLarge
              placeholder={'Nombre del residente '}
              key={'name'}
              value={residentName ? `${residentName.name} ${residentName.lastName}  ` : ''}
            />
          </div>
          <div className="two-line">
            <RangePickerLarge
              value={dateRange}
              onChange={newRange => {
                setDateRange(newRange)
                setFormData(prev => ({
                  ...prev,
                  date: newRange.start ? newRange.start.format('YYYY-MM-DD') : ''
                }))
              }}
              allowSingleDate={true}
            />
            <InputNumber
              value={`${formData.startHour}`}
              isTimeFormat={true}
              isShortTimeFormat={true}
              showDecimals={false}
              setAmount={val => handleChange({ name: 'startHour', value: String(val) })}
              onChange={val => handleChange({ name: 'startHour', value: String(val) })}
            />
          </div>
          <div className="three-line">
            <BorderIpass>
              <MultiSelectANTD
                value={
                  selectedSegment && selectedSegment.length > 0 ? selectedSegment : undefined
                }
                showSearch
                colorBg="#261a3a"
                onChange={value => {
                  setSelectedSegment(value)
                  setOpen(false)
                }}
                placeholder="Seleccione el Segmento"
                options={data?.data.map(seg => ({
                  label: seg.name,
                  value: seg.id
                }))}
                allowClear
                maxTagCount={1}
                open={open}
                onDropdownVisibleChange={setOpen}
                style={{
                  width: '250px',
                  cursor: 'pointer',
                  height: '42px',
                  borderRadius: '20px'
                }}
              />
            </BorderIpass>
            <BorderIpass>
              <MultiSelectANTD
                value={selectedLocation ? [selectedLocation.name] : undefined}
                colorBg="#261a3a"
                showSearch
                onChange={values => {
                  const selectedLoc = (
                    filteredLocationsTwo.length > 0 ? filteredLocationsTwo : filteredLocations
                  ).find(loc => loc.id === values[0])

                  setSelectedLocation(selectedLoc || null)
                  setOpenLocation(false)
                }}
                placeholder="Seleccione la Locación"
                options={(filteredLocationsTwo.length > 0
                  ? filteredLocationsTwo
                  : filteredLocations
                ).map(loc => ({
                  label: `${loc.name}`,
                  value: loc.id
                }))}
                allowClear
                maxTagCount={1}
                onDropdownVisibleChange={setOpenLocation}
                open={openLocation}
                style={{
                  backgroundColor: 'transparent',
                  width: '250px',
                  cursor: 'pointer',
                  height: '42px',
                  borderRadius: '20px'
                }}
              />
            </BorderIpass>
          </div>
        </div>
        <div className="sub-container">
          <div className="sub-title-reserve">
            <div className="left-component"></div>
            <div className="center-component">
              <h3>CONFIRMAR RESERVA</h3>
            </div>
          </div>
          <MyTextArea
            personClassName="fullHeight"
            colorBg={'#00000000'}
            BorderSize={2}
            borderColor
            value={confirmationMessage}
            onChange={val =>
              handleChange({ name: 'sendMessage', value: val?.toString() ?? '' })
            }
            placeholder="Confirmacion de reserva: "
            style={{
              height: '170px',
              resize: 'none',
              // marginBottom: '30px',
              paddingBottom: '70px',
              fontSize: '16px',
              color: '#dabdf5'
            }}
          />*/}
          <div className="button-reserve">
            <ButtonB text="Crear Plan" style={{ width: '25%' }} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SetUpPlan

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { message } from 'antd'
import React, { FC, useState } from 'react'
import readXlsxFile from 'read-excel-file'
import { useAuth } from '../../../Provider/AuthProvider'
import { useLoading } from '../../../Provider/LoadingProvider'
import FileUpload from '../../../components/Form/FileUpload'
import Modal from '../../../components/GeneralComponents/Modal'
import Table, { enumTypeColumn, TableColumn } from '../../../components/Table'
import DeleteButton from '../../../components/Table/components/DeleteButton'
import { columnsResident } from '../../../components/Tables/Resident/Columns'
import { createResidents } from '../../../services/resident'
import { ResidentCreateExcel } from '../../../types/resident'
import { LightTooltip } from '../../../components/tooltips/text'
import { Fade } from '@mui/material'
import { ButtonB } from '../../../components/Form/ButtonB'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { ProprietaryCreateExcel } from '../../../types/proprietarys'
import { columnsProprietarys } from '../../../components/Tables/Propietaries/Columns'
import { columnsAccions } from '../../../components/Tables/Accions/Columns'
import './style.scss'
import { createProprietarys } from '../../../services/propietaries'

import SetUpPlan from './SetUpPlan'

const UploadExcelT: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [valueFile, setValueFile] = useState<File>()
  const [dataResident, setDataResident] = useState<ResidentCreateExcel[]>()
  const [dataAccions, setDataAccions] = useState()
  const [dataProprertys, setDataProprertys] = useState<ProprietaryCreateExcel[]>()
  const [Select, setSelect] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { startLoading, stopLoading, showMessage } = useLoading()
  const onCloseModal = () => {
    setSelect(undefined)
    setValueFile(undefined)
    setDataProprertys(undefined)
    setIsOpen(false)
  }

  const Actions = ({ id }: { id: string; item: ResidentCreateExcel }) => {
    return (
      <div style={{ display: 'flex' }}>
        <DeleteButton OnDelete={() => handleDelete(id)} />
      </div>
    )
  }

  const parsePhoneNumber = (phoneNumber: string | undefined): number => {
    const countryCode = '502'
    if (!phoneNumber) {
      return NaN
    }
    const formattedPhoneNumber = phoneNumber.startsWith(countryCode)
      ? phoneNumber
      : `${countryCode}${phoneNumber}`

    return parseInt(formattedPhoneNumber)
  }

  const handleChangeFile = async (file?: File | string) => {
    if (typeof file === 'string') return

    await handleChangeFileResident(file)
  }

  const handleChangeFileProprietarys = async (file?: File) => {
    if (!file) {
      setValueFile(file)
      setDataProprertys([])
      return
    }

    setValueFile(file)
    const rows = await readXlsxFile(file)

    if (
      rows[0][0] !== 'CREACIÓN DE PROPIETARIOS' ||
      rows[1][0] !== 'DESTINO' ||
      rows[1][1] !== 'NOMBRES' ||
      rows[1][2] !== 'APELLIDOS' ||
      rows[1][3] !== 'TELÉFONO 1' ||
      rows[1][4] !== 'TELÉFONO 2' ||
      rows[1][5] !== 'EMAIL' ||
      rows[1][6] !== 'NIT'
    ) {
      message.error('No coincide el formato')
      setValueFile(file)
      setDataProprertys([])
      return
    }

    if (rows[2] === undefined) {
      message.error('la planilla debe ser llenada desde la fila 3')
      setValueFile(file)
      setDataProprertys([])
      return
    }

    const result: ProprietaryCreateExcel[] = []

    if (rows.length >= 0) {
      for (let k = 2; k < rows.length; k++) {
        const row = rows[k]

        const proprietary: ProprietaryCreateExcel = {
          id: (k - 1).toString(),
          destination: row[0] as string,
          name: row[1] as string,
          lastName: row[2] as string,
          phone1: row[3] ? parsePhoneNumber(row[3].toString())?.toString() : undefined,
          phone2: row[4] ? parsePhoneNumber(row[4].toString())?.toString() : undefined,
          email: row[5] as string,
          nit: row[6] as string,
          clientId: user?.clientId as string
        }
        result.push(proprietary)
      }
    }
    setDataProprertys(result)
  }

  const handleChangeFileResident = async (file?: File) => {
    if (!file) {
      setValueFile(file)
      setDataResident(undefined)
      return
    }

    setValueFile(file)
    const rows = await readXlsxFile(file)

    if (
      rows[0][0] !== 'CREACIÓN DE RESIDENTES Y DELEGADOS' ||
      rows[1][0] !== 'TIPO DE DESTINO' ||
      rows[1][1] !== 'CÓDIGO DE DESTINO' ||
      rows[1][2] !== 'NOMBRES' ||
      rows[1][3] !== 'APELLIDOS' ||
      rows[1][4] !== 'TELÉFONO 1' ||
      rows[1][5] !== 'TELÉFONO 2' ||
      rows[1][6] !== 'EMAIL' ||
      rows[1][7] !== 'DELEGADOS' ||
      rows[1][8] !== 'NOMBRES D1'
    ) {
      message.error('No coincide el formato')
      setValueFile(file)
      setDataResident(undefined)
      return
    }

    if (rows[2] === undefined) {
      message.error('la planilla debe ser llenada desde la fila 3')
      setValueFile(file)
      setDataResident(undefined)
      return
    }

    const result: ResidentCreateExcel[] = []

    if (rows.length >= 0) {
      for (let k = 2; k < rows.length; k++) {
        const row = rows[k]

        const resident: ResidentCreateExcel = {
          id: (k - 1).toString(),
          destinationName: row[0] as string,
          destinationCode: (row[1] as string).toString(),
          name: row[2] as string,
          lastName: row[3] as string,
          phone1: row[4] ? parsePhoneNumber(row[4].toString()) : undefined,
          phone2: row[5] ? parsePhoneNumber(row[5].toString()) : undefined,
          email: row[6] as string,
          clientId: user?.clientId as string,
          delegatedQuantity: parseInt(row[7] as string),
          delegateResidents: [] // Aquí almacenaremos los residentes delegados
        }

        // Procesar columnas a partir de la 8 (índice 7)
        for (let col = 8; col < row.length; col += 3) {
          if (row[col] || row[col + 1] || row[col + 2]) {
            const delegatedResident = {
              name: (row[col] as string) || '',
              lastName: (row[col + 1] as string) || '',
              phone: row[col + 2] ? parsePhoneNumber(row[col + 2].toString()) : undefined
            }
            resident.delegateResidents.push(delegatedResident)
          } else {
            // Si no hay datos en las columnas, dejamos de buscar
            break
          }
        }

        result.push(resident)
      }
    }

    setDataResident(result)
  }

  const handleDelete = (id: string) => {
    const newData = dataResident
      ? dataResident.filter(resident => resident.id !== id)
      : dataResident
    setDataResident(newData)
  }

  const handleCreateResidents = async () => {
    try {
      startLoading()
      await sendMultiple(dataResident || [])
      showMessage('success', 'Creados con éxito')
      onClose()
    } catch (error) {
      showMessage('error', (error as any)?.response?.data?.message)
    } finally {
      stopLoading()
    }
  }

  const handleCreatePropietarys = async () => {
    try {
      startLoading()
      // if (!valueFile) {
      //   showMessage('error', 'Debe seleccionar un archivo')
      //   return
      // }

      const file = new FormData()
      // file.append('file', valueFile)

      await createProprietarys(file)
      showMessage('success', 'Creados con éxito')
      onClose()
    } catch (error) {
      showMessage('error', (error as any)?.response?.data?.message || 'error en servidor.')
    } finally {
      stopLoading()
    }
  }
  async function sendMultiple(residents: ResidentCreateExcel[]): Promise<void> {
    const batchSize = 10
    const chunks = residents.reduce<ResidentCreateExcel[][]>((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / batchSize)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    const promises = chunks.map(async chunk => {
      try {
        await createResidents({ residents: chunk })
      } catch (error) {
        console.error('Error al enviar chunk:', error)
      }
    })

    try {
      await Promise.all(promises)
      console.info('Todos los residentes han sido enviados')
    } catch (error) {
      console.error('Error al enviar residentes:', error)
    }
  }

  const onClosePlan = () => {
    setIsModalOpen(false)
  }
  const columnsResidentEnable: TableColumn[] = [
    ...columnsResident,
    {
      title: 'Delegados',
      dataIndex: 'delegatedQuantity',
      key: 'delegatedQuantity',
      type: enumTypeColumn.custom,
      CustomRender: ({ item }: { item?: ResidentCreateExcel }) => {
        if (!item) return null
        return (
          <LightTooltip
            title={
              item.delegateResidents?.length > 0
                ? item.delegateResidents.map((delegate, index) => (
                    <span key={index}>
                      {` ${delegate.name} ${delegate.lastName}`}
                      {index < item.delegateResidents?.length - 1 && <br />}
                    </span>
                  ))
                : 'SIN DELEGADOS'
            }
            followCursor
            TransitionComponent={Fade}
          >
            <div className="containerDelegatesQuantity">
              <div>{`${item?.delegatedQuantity || 0}/${
                item?.delegateResidents?.length || 0
              }`}</div>
            </div>
          </LightTooltip>
        )
      }
    }
  ]

  const columnsProprietaryes: TableColumn[] = columnsProprietarys
  const columnsAcciones: TableColumn[] = columnsAccions

  return (
    <>
      <ButtonB
        onClick={() => setIsOpen(true)}
        icon={
          <CloudUploadOutlinedIcon
            style={{
              fontSize: '30px'
            }}
          />
        }
        style={{ width: '120px' }}
      />
      <Modal
        onClose={onCloseModal}
        isOpen={isOpen}
        borderColor={true}
        style_Subcontainer={{ padding: '1rem' }}
        styleContainerContent={{ padding: '2px', margin: '1px' }}
        styleContainer={{
          width: '60%',
          ...(Select ? { height: '100%' } : { height: '70%' }),
          padding: '0px',
          overflow: 'hidden',
          margin: '2px',
          borderRadius: '15px'
        }}
        styleClose={{ fontSize: '30px', right: '25px', top: '32px' }}
      >
        <div className="title-upload">
          <div className="headers-modal">
            <div className="title-questions">
              <div className="left-component">
                <LightTooltip
                  title="pendiente texto"
                  placement="right"
                  sx={{
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: '#261a3a',
                      color: '#fff',
                      fontSize: '14px',
                      padding: '10px',
                      borderRadius: '8px',
                      border: '1px solid  #5a1fd1',
                      boxShadow: 'none'
                    }
                  }}
                >
                  <HelpOutlineIcon
                    style={{ cursor: 'pointer', color: '#fff', fontSize: '35px' }}
                  />
                </LightTooltip>
              </div>
              <div className="center-component">
                <h2>Acciones & Planes</h2>
              </div>
            </div>

            <FileUpload
              title={'Carga de acciones'}
              label={'Selecciona ó arrastra la planilla excel'}
              value={valueFile}
              // onChange={handleChangeFile}
              onChange={file => {
                if (typeof file === 'string') return
                handleChangeFile(file)
              }}
              personClassName="custom-upload-style"
            />
          </div>

          <>
            <Table
              onPageChange={() => {}}
              pagination={{
                currentPage: 0,
                hasNextPage: false,
                hasPrevPage: false,
                total: 0,
                totalPages: 0,
                data: dataAccions || []
              }}
              itemsPerPage={dataAccions}
              canAdd={false}
              columns={columnsAcciones}
              Actions={Select === 'Resident' ? (Actions as any) : undefined}
            />

            <div className="button-container">
              <ButtonB
                onClick={() => setIsModalOpen(true)}
                text="Configurar Plan"
                style={{ width: '20%', marginBottom: '25px' }}
                // disabled={!valueFile}
              />
            </div>
          </>
        </div>
      </Modal>
      {isModalOpen && (
        <SetUpPlan
          isOpen={isOpen}
          onClose={onClosePlan}
          onSuccess={() => console.log('hola')}
        />
      )}
    </>
  )
}

export default UploadExcelT

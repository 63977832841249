import React, { useState } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './style.scss'

interface PhoneInputProps {
  icon?: string
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
  className?: string
  label?: string
  errorMessage?: string
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  icon,
  className,
  placeholder,
  value,
  onChange,
  label,
  errorMessage
}) => {
  const [selected, setSelected] = useState(false)

  return (
    <div
      className={`input-container-phone-dark ${selected ? 'selectedInput' : ''} ${className}`}
    >
      {label && <label className="input-label">{label}</label>}
      <div
        className="icon-input-container"
        onFocus={() => setSelected(true)}
        onBlur={() => setSelected(false)}
      >
        {icon && <img className="icon" src={icon} alt={placeholder} />}
        <ReactPhoneInput
          country={'gt'}
          value={value}
          onChange={onChange}
          autoFormat={false} // Deshabilitar el formateo automático
          enableLongNumbers={true} // Permitir números largos sin formateo
          inputClass="input-element"
          buttonClass="country-selector"
          containerClass="react-tel-input"
          inputStyle={{
            backgroundColor: 'transparent',
            border: 'none',
            color: 'white',
            width: '100%',
            height: '100%',
            fontSize: '1rem'
          }}
          buttonStyle={{
            backgroundColor: 'transparent',
            border: 'none'
          }}
          placeholder={placeholder}
        />
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  )
}

export default PhoneInput

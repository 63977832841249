// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsAccions: TableColumn[] = [
  {
    title: 'Acción',
    dataIndex: 'destination',
    key: 'destination',
    type: enumTypeColumn.string
  },
  {
    title: 'Dirección',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Tipo',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'TE',
    dataIndex: 'phone1',
    key: 'phone1',
    type: enumTypeColumn.string
  }
]

export const AccionsFormConfig: FormField[] = [
  {
    type: FieldTypeEnum.text,
    label: 'Acción',
    name: 'destinationCode',
    required: true
  },
  { type: FieldTypeEnum.text, label: 'Dirección ', name: 'name', required: true },
  { type: FieldTypeEnum.text, label: 'Tipo', name: 'lastName', required: true },
  { type: FieldTypeEnum.text, label: 'TE', name: 'email' }
]

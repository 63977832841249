import React, { useState, useEffect } from 'react'
import Modal from '../../../components/GeneralComponents/Modal'
import { LightTooltip } from '../../../components/tooltips/text'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import './style.scss'
import { ButtonB } from '../../../components/Form/ButtonB'

interface TemplateModalProps {
  isOpen: boolean
  onClose: () => void
}

const TemplateModalT: React.FC<TemplateModalProps> = ({ isOpen, onClose }) => {
  const [isResident, setIsResident] = useState<boolean | null>(null)

  useEffect(() => {
    if (isOpen) {
      setIsResident(null)
    }
  }, [isOpen])

  const downloadTemplate = () => {
    if (isResident === null) return

    const urlTemplate = isResident
      ? 'Documents/Plantilla_Crear_Residentes.xlsx'
      : 'Documents/Plantilla_Crear_Propietarios.xlsx'

    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = urlTemplate
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      borderColor={true}
      style_Subcontainer={{ padding: '1rem' }}
      styleContainerContent={{ padding: '2px', margin: '1px' }}
      styleContainer={{
        width: '430px',
        height: '230px',
        padding: '0px',
        overflow: 'hidden',
        margin: '2px',
        borderRadius: '15px'
      }}
      styleClose={{ fontSize: '20px', right: '25px', top: '32px' }}
    >
      <div className="container-templateT">
        <div className="title-templateT">
          <div className="left-component">
            <LightTooltip
              title="Para cargar datos de residentes/propietarios por lotes."
              placement="right"
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#261a3a',
                  color: '#fff',
                  fontSize: '14px',
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid  #5a1fd1',
                  boxShadow: 'none'
                }
              }}
            >
              <HelpOutlineIcon
                style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }}
              />
            </LightTooltip>
          </div>
          <div className="center-component">
            <h3>PLANTILLA</h3>
          </div>
        </div>
        {/* <div className="buttons-template">
          <ButtonB
            text="RESIDENTES"
            onClick={() => setIsResident(true)}
            selected={isResident === true}
            style={{ borderRadius: '10px', width: '35%' }}
          />
          <ButtonB
            text="PROPIETARIOS"
            onClick={() => setIsResident(false)}
            selected={isResident === false}
            style={{ borderRadius: '10px', width: '35%' }}
          />
        </div> */}
        <div className="download-template">
          <Tooltip title="Descargar plantilla">
            <CloudDownloadIcon
              style={{ fontSize: '100px', color: '#da29ba', cursor: 'pointer' }}
              onClick={downloadTemplate}
            />
          </Tooltip>
        </div>
      </div>
    </Modal>
  )
}

export default TemplateModalT

import React, { FC, useEffect, useState } from 'react'
import DynamicForm, { FieldTypeEnum, FormField } from './DynamicFormRoutines'
import './styles.scss'

import { useLoading } from '../../../Provider/LoadingProvider'

import { routinesFormConfig } from './Columns'
import { RoutinesCreate, LocationsItems } from '../../../types/routines'
import { createRoutines, updateRoutines } from '../../../services/routines'
import { Routines } from '../../../types/routines'
import { getLocations } from '../../../services/routines'
import { useAuth } from '../../../Provider/AuthProvider'
import { cleanObject } from '../../../utils/utils'

const FormRoutines: FC<{ initialValue?: Routines; onSubmit: () => void }> = ({
  onSubmit,
  initialValue
}) => {
  const { startLoading, stopLoading } = useLoading()
  const [locations, setLocations] = useState<LocationsItems[]>([])
  const { user } = useAuth()

  // Cambia `fullRoutinesFormConfig` a un estado
  const [fullRoutinesFormConfig, setFullRoutinesFormConfig] = useState<FormField[]>([])

  const handleSubmit = async (formData: RoutinesCreate) => {
    try {
      startLoading()
      const dataToSend = {
        name: formData.name,
        TypeRoutine: formData.TypeRoutine,
        Frequency: formData.Frequency,
        DateRoutine: formData.Frequency === 'Diario' ? formData.DateRoutine : undefined,
        MensualOptions: formData.Frequency === 'Mensual' ? formData.MensualOptions : undefined,
        Locations: formData.Locations,
        clientId: user?.clientId as string
      }

      const clearData = cleanObject(dataToSend) as RoutinesCreate
      if (initialValue) {
        await updateRoutines(initialValue.id, clearData)
      } else {
        await createRoutines(clearData)
      }

      onSubmit()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  useEffect(() => {
    getDestination()
  }, [initialValue])

  useEffect(() => {
    setFullRoutinesFormConfig([
      ...routinesFormConfig,

      {
        type: FieldTypeEnum.search,
        label: 'Search',
        name: 'Search',
        Locations: locations
      },
      {
        type: FieldTypeEnum.DragAndDrop,
        label: 'Rutina',
        name: 'Locations',
        Locations: locations
      }
    ])
  }, [locations])

  const getDestination = async () => {
    try {
      if (!user?.clientId) return
      const newDestinations: LocationsItems[] = await getLocations(user.clientId)

      const oldDestinations: LocationsItems[] = initialValue?.Locations
        ? JSON.parse(initialValue.Locations)
        : [] // Si initialValue.Locations es undefined, usamos un array vacío

      // oldDestinations para mantener su orden, sobrescribiendo campos con los de newDestinations
      const combinedDestinations: LocationsItems[] = oldDestinations.reduce((acc, oldItem) => {
        const newItem = newDestinations.find(newItem => newItem.id === oldItem.id)

        if (newItem) {
          acc.push({
            ...newItem,
            list: oldItem.list,
            id: oldItem.id
          })
        }

        return acc
      }, [] as LocationsItems[])

      // Si hay elementos en newDestinations que no están en oldDestinations, los agregamos al final
      const remainingNewDestinations = newDestinations.filter(
        newItem => !oldDestinations.find(oldItem => oldItem.id === newItem.id)
      )

      // Concatenamos los elementos restantes de newDestinations al final
      const finalDestinations = [...combinedDestinations, ...remainingNewDestinations]

      setLocations(finalDestinations)
    } catch (error) {
      console.error('Error fetching destinations:', error)
    }
  }

  return (
    <div className="newRoutinesForm">
      <h2>{initialValue ? 'Actualizar Plan' : 'Nuevo Plan'}</h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={fullRoutinesFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormRoutines

// administratorConfig.ts
import { FieldTypeEnum, FormField } from '../../Form/DynamicForm'
import { TableColumn, enumTypeColumn } from '../../Table'

const Profiles: any[] = [
  { value: `Administrador`, label: `Administrador` },
  { value: `Contador`, label: `Contador` },
  { value: `Limpieza`, label: `Limpieza` },
  { value: `Mantenimiento`, label: `Mantenimiento` },
  { value: `Seguridad`, label: `Seguridad` }
]

export const columnsStaff: TableColumn[] = [
  {
    title: 'Foto',
    dataIndex: 'photo',
    key: 'photo',
    type: enumTypeColumn.photo
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'Teléfono',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    type: enumTypeColumn.string
  },
  {
    title: 'Rol',
    dataIndex: 'profile',
    key: 'profile',
    type: enumTypeColumn.string
  },
  {
    title: 'IC',
    dataIndex: 'staffPercent',
    key: 'staffPercent',
    type: enumTypeColumn.string
  }
]

export const FormConfig: FormField[] = [
  { type: FieldTypeEnum.text, label: 'Nombre', name: 'name' },
  { type: FieldTypeEnum.text, label: 'Apellido', name: 'lastName' },
  { type: FieldTypeEnum.phone, label: 'Teléfono', name: 'phoneNumber' },
  {
    type: FieldTypeEnum.select,
    label: 'Rol',
    name: 'profile',
    options: Profiles
  },
  { type: FieldTypeEnum.image, label: 'Foto', name: 'photo' }
]

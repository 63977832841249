import React, { FC, useEffect, useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Search as SearchIcon
} from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import './styles.scss'
import { transformDate } from '../../utils/utils'
import { Pagination } from '../../types/types'
import Switch from '../Form/Switch'
import { Image } from 'antd'

export enum enumTypeColumn {
  'string' = 'string',
  'photo' = 'photo',
  'photo3' = 'photo3',
  'date' = 'date',
  'boolean' = 'boolean',
  'showId' = 'showId',
  'custom' = 'custom'
}

export interface TableColumn {
  title: string
  dataIndex: string
  dataArray?: string[]
  key: string
  type: enumTypeColumn
  options?: any
  CustomRender?: React.ComponentType<{ id: string; item?: any }>
}

interface TableProps<T> {
  columns: TableColumn[]
  handleOpenModal?: () => void
  handleBoolean?: (id: string) => void
  pagination: Pagination<T>
  onChangeSearch?: (value: string) => void
  onPageChange: (currentPage: number) => void
  Actions?: React.ComponentType<{ id: string; item?: any }>
  canAdd?: boolean
  itemsPerPage?: number
  AdditionalButtons?: React.ComponentType
}

const Table: FC<TableProps<any>> = ({
  columns,
  handleOpenModal,
  handleBoolean,
  pagination,
  onChangeSearch,
  onPageChange,
  Actions,
  canAdd = true,
  itemsPerPage = 10,
  AdditionalButtons
}) => {
  const { total, data, hasNextPage, hasPrevPage, currentPage: currentPagePag } = pagination
  const [currentPage, setCurrentPage] = useState(1)

  const goToPage = (page: number) => {
    if (page >= 1 && page <= Math.ceil(total / itemsPerPage)) {
      setCurrentPage(page)
      onPageChange(page)
    }
  }

  const handlePrevPage = () => {
    goToPage(currentPage - 1)
  }

  const handleNextPage = () => {
    goToPage(currentPage + 1)
  }

  const firstData = data.slice(0, itemsPerPage)
  useEffect(() => {
    setCurrentPage(currentPagePag)
  }, [currentPagePag])

  return (
    <>
      <div className="search-section">
        {onChangeSearch && (
          <div className="search-bar-container">
            <div className="search-icon">
              <SearchIcon style={{ color: 'white' }} />
            </div>
            <input
              onChange={e => onChangeSearch(e.target.value)}
              type="text"
              className="search-input"
              placeholder="Buscar..."
            />
          </div>
        )}
        {AdditionalButtons && <AdditionalButtons />}
        {canAdd && (
          <Tooltip
            title="Crear"
            placement="right-start"
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#261a3a', // Color de fondo
                  color: 'white', // Color del texto
                  fontSize: '14px', // Tamaño del texto
                  padding: '8px 12px', // Espaciado interno
                  borderRadius: '8px', // Bordes redondeados
                  boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                }
              }
            }}
          >
            <IconButton onClick={handleOpenModal}>
              <AddIcon style={{ color: 'white', fontSize: '50px' }} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="table-container">
        <div className="table-header">
          {columns.map(column => (
            <div key={column.key} className={`column ${column.key}`}>
              {column.title}
            </div>
          ))}
          {Actions && <div className="column actions">Acciones</div>}
        </div>
        {firstData.map((item, index) => (
          <div key={`${index}`} className="table-row">
            {columns.map(column => (
              <div key={column.key} className={`column ${column.key}`}>
                {column.type === enumTypeColumn.string && item[column.dataIndex]}
                {column.type === enumTypeColumn.photo3 && column.dataArray && (
                  <>
                    <Image
                      preview={{
                        mask: <div>Ver</div>
                      }}
                      rootClassName="CheckInImg"
                      src={
                        item[column.dataArray[0]] &&
                        item[column.dataArray[0]] !== 'undefined' &&
                        item[column.dataArray[0]] !== 'null'
                          ? item[column.dataArray[0]]
                          : 'images/icons/user.png'
                      }
                    />
                    <Image
                      preview={{
                        mask: <div>Ver</div>
                      }}
                      rootClassName="CheckInImg"
                      src={
                        item[column.dataArray[1]] &&
                        item[column.dataArray[1]] !== 'undefined' &&
                        item[column.dataArray[1]] !== 'null'
                          ? item[column.dataArray[1]]
                          : 'images/icons/user.png'
                      }
                    />
                    <Image
                      preview={{
                        mask: <div>Ver</div>
                      }}
                      rootClassName="CheckInImg"
                      src={
                        item[column.dataArray[2]] &&
                        item[column.dataArray[2]] !== 'undefined' &&
                        item[column.dataArray[2]] !== 'null'
                          ? item[column.dataArray[2]]
                          : 'images/icons/user.png'
                      }
                    />
                  </>
                )}

                {column.type === enumTypeColumn.photo && (
                  <>
                    <Image
                      preview={{
                        mask: <div>Ver</div>
                      }}
                      rootClassName="CheckInImg"
                      src={
                        item[column.dataIndex] &&
                        item[column.dataIndex] !== 'undefined' &&
                        item[column.dataIndex] !== 'null'
                          ? item[column.dataIndex]
                          : 'images/icons/user.png'
                      }
                    />
                  </>
                )}
                {column.type === enumTypeColumn.date &&
                  transformDate(item[column.dataIndex], column.options)}
                {column.type === enumTypeColumn.boolean && handleBoolean && (
                  <Switch
                    value={item[column.dataIndex]}
                    onChange={() => {
                      handleBoolean(item.id)
                    }}
                  />
                )}
                {column.type === enumTypeColumn.custom && column.CustomRender && (
                  <column.CustomRender item={item} id={item.id} />
                )}

                {column.type === enumTypeColumn.showId && (
                  <div>{item[column.dataIndex].slice(-4)}</div>
                )}
              </div>
            ))}
            {Actions && (
              <div className="column actions">
                <Actions item={item} id={item.id} />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="pagination">
        <IconButton onClick={handlePrevPage} disabled={!hasPrevPage}>
          <KeyboardArrowLeft style={{ color: hasPrevPage ? 'white' : 'gray' }} />
        </IconButton>
        <span className="page-number">{currentPage}</span>
        <IconButton onClick={handleNextPage} disabled={!hasNextPage}>
          <KeyboardArrowRight style={{ color: hasNextPage ? 'white' : 'gray' }} />
        </IconButton>
      </div>
    </>
  )
}

export default Table

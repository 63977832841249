import { Locations, LocationsCreate } from '../types/Locations'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const createLocations = async (params: FormData): Promise<Locations> => {
  try {
    const response = await axiosInstance.post(`/api/reserves/locations`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en createLocations :', error)
    throw error
  }
}
export const updateLocations = async (id: string, params: FormData): Promise<Locations> => {
  try {
    const response = await axiosInstance.put(`/api/reserves/locations/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en  updateLocations :', error)
    throw error
  }
}
export const deleteLocations = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/reserves/locations/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en deleteLocations :', error)
    throw error
  }
}
export const updatePatchLocations = async (
  id: string,
  newStatus: boolean
): Promise<Locations> => {
  try {
    const response = await axiosInstance.patch(`/api/reserves/locations/${id}`, {
      newStatus
    })

    return response.data
  } catch (error) {
    console.error('Error en updateLocations:', error)
    throw error
  }
}

export const createCopyLocations = async (id: string): Promise<Locations> => {
  try {
    const response = await axiosInstance.post(`/api/reserves/locations/copy/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en createCopyLocations:', error)
    throw error
  }
}

import React from 'react'
import { BorderIpass } from '../../components/BorderIpass/Index'
import { useLoading } from '../../Provider/LoadingProvider'
import { generateReportResident } from '../../services/resident'
import { useAuth } from '../../Provider/AuthProvider'

interface ExportToExcelProps {
  status?: string[]
  style?: React.CSSProperties
  className?: string
  enableHover?: boolean
  text: string
  disabled?: boolean
}

export const ExportToExcelAcreditamientos: React.FC<ExportToExcelProps> = ({
  status,
  style,
  className,
  enableHover = true,
  disabled = false,
  text
}) => {
  const { startLoading, stopLoading } = useLoading()
  const [hover, setHover] = React.useState(false)

  const { token } = useAuth()

  const handleExport = async () => {
    try {
      startLoading()

      const value = await generateReportResident({
        params: {
          status
        }
      })

      // Hacer fetch para obtener el archivo
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_BACKEND}/report/${value.url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = value.url // Nombre del archivo descargado
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      // Liberar el objeto URL
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(`error al crear reporte`, error) // Maneja el error
    } finally {
      stopLoading()
    }
  }

  return (
    <div style={style}>
      <BorderIpass
        personClassName={'button'}
        margin={4}
        status="Normal"
        onClick={handleExport}
        style={{
          borderRadius: '10px',
          border: '1px',
          width: '150px',
          opacity: disabled ? 0.5 : 1, // Reduce opacidad si está deshabilitado
          cursor: disabled ? 'not-allowed' : 'pointer', // Cambia cursor
          ...style // Permite sobrescribir estilos
        }}
        styleInner={{
          ...SelectStyles,
          backgroundColor: disabled ? '#555' : hover && enableHover ? '#7C1084' : '#261a3a'
        }}
        onMouseEnter={() => enableHover && !disabled && setHover(true)}
        onMouseLeave={() => enableHover && !disabled && setHover(false)}
      >
        <div>{text}</div>
      </BorderIpass>
    </div>
  )
}

// const SelectStyles: React.CSSProperties = {
//   margin: '4px',
//   height: '40px',
//   borderRadius: '8px',
//   width: '110px',
//   color: 'white',
//   alignItems: 'center',
//   cursor: 'pointer'
// }

const SelectStyles: React.CSSProperties = {
  margin: '2px',
  height: '40px',
  color: 'white',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '8px',
  // backgroundColor: '#6a0dad', // Color inicial
  transition: 'background-color 0.3s ease'
}

import React, { useEffect, useState } from 'react'

import { useLoading } from '../../Provider/LoadingProvider'
import { columnsAccessConfig } from '../../components/Tables/AccessConfig/Columns'
import FormAccessConfig from '../../components/Tables/AccessConfig/Form'
import { Pagination } from '../../types/types'
import './style.scss'

import { IconButton, Tooltip } from '@mui/material'
import { useAuth } from '../../Provider/AuthProvider'
import Modal from '../../components/GeneralComponents/Modal'
import Table from '../../components/Table'
import DeleteButton from '../../components/Table/components/DeleteButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { deleteAccessConfig, listAccessConfig } from '../../services/AccessConfig'
import { AccessConfig } from '../../types/AccessConfig'

const AccessConfigPage = () => {
  const { user } = useAuth()
  const [data, setData] = useState<Pagination<AccessConfig>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const limit = 6
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredBySearch, setFilteredBySearch] = useState(false)
  const [selected, setSelected] = useState<AccessConfig | undefined>(undefined)

  const { startLoading, stopLoading } = useLoading()

  useEffect(() => {
    getData(search.length > 3 ? search : '', data.currentPage)
  }, [])

  useEffect(() => {
    if (search.length >= 3) {
      setFilteredBySearch(true)
      getData(search, 1)
    } else if (filteredBySearch) {
      setFilteredBySearch(false)
      getData('', 1)
    }
  }, [search])

  useEffect(() => {
    getData(search, data.currentPage)
  }, [data.currentPage])

  useEffect(() => {
    if (selected) {
      setIsOpenModal(true)
    }
  }, [selected])

  const onCloseModal = () => {
    setIsOpenModal(false)
    setSelected(undefined)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const getData = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const access = await listAccessConfig({
        params: { page, search: searchValue, limit },
        id: user?.clientId as string
      })
      setData(access)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleSubmit = async () => {
    setSelected(undefined)
    setIsOpenModal(false)
    await getData(search.length > 3 ? search : '', data.currentPage)
  }

  const handlePageChange = (newPage: number) => {
    getData(search.length >= 3 ? search : '', newPage)
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteAccessConfig(id)
      await getData(search.length > 3 ? search : '', data.currentPage)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleView = (value: AccessConfig) => {
    setSelected(value)
  }

  const Actions = ({ id, item }: { id: string; item: AccessConfig }) => {
    return (
      <div className="actionsContainer">
        <Tooltip
          title="Actualizar"
          placement="top-end"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#261a3a', // Color de fondo
                color: 'white', // Color del texto
                fontSize: '14px', // Tamaño del texto
                padding: '8px 12px', // Espaciado interno
                borderRadius: '8px', // Bordes redondeados
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
              }
            }
          }}
        >
          <IconButton onClick={() => handleView(item as AccessConfig)}>
            <VisibilityIcon style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Eliminar"
          placement="top-end"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#261a3a', // Color de fondo
                color: 'white', // Color del texto
                fontSize: '14px', // Tamaño del texto
                padding: '8px 12px', // Espaciado interno
                borderRadius: '8px', // Bordes redondeados
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
              }
            }
          }}
        >
          <IconButton style={{ padding: '0px', margin: '0px' }}>
            <DeleteButton OnDelete={() => handleDelete(id)} />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  return (
    <>
      <div className="checkPointsContainer">
        <div className="titleCheckPoints">
          <h2>Checkins</h2>
        </div>
        <div className="tableContainer">
          <Table
            onPageChange={handlePageChange}
            onChangeSearch={setSearch}
            pagination={data}
            itemsPerPage={limit} //6
            handleOpenModal={handleOpenModal}
            columns={columnsAccessConfig}
            Actions={Actions as any}
          />
        </div>
      </div>
      <Modal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        borderColor={true}
        style_Subcontainer={{ padding: '1rem' }}
        styleContainerContent={{ padding: '2px', margin: '1px' }}
        styleContainer={{
          width: '600px',
          height: '400px',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: '20px', top: '30px' }}
      >
        <FormAccessConfig onSubmit={handleSubmit} initialValue={selected} />
      </Modal>
    </>
  )
}

export default AccessConfigPage

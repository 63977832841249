import React, { useEffect, useState } from 'react'
import { useLoading } from '../../Provider/LoadingProvider'
import Table, { TableColumn, enumTypeColumn } from '../../components/Table'
import { columnsCheckIn } from '../../components/Tables/CheckIn/columns'
import { entries } from '../../types/entries'
import { Pagination } from '../../types/types'
import './style.scss'
import { ReactComponent as waitInLobby } from '../../assets/svg/waitInLobby.svg'
import { ReactComponent as leaveInLobby } from '../../assets/svg/leaveInLobby.svg'
import { ReactComponent as noLeaveInLobby } from '../../assets/svg/noLeaveInLobby.svg'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { CheckCircleOutlined, Visibility, Warning } from '@mui/icons-material'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { Fade, IconButton } from '@mui/material'
import { columnsCheckInOkId } from '../../components/Tables/CheckIn/columnsOkid'
import { DownloadPdfOkId } from '../../services/entries'

import AutoComplete from '../../components/Form/AutoComplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'
import { useAuth } from '../../Provider/AuthProvider'
import Button from '../../components/Form/Button'
import InputDark from '../../components/Form/InputDark'
import RangePicker from '../../components/Form/RangePicker'
import { generateReport, getDataReport } from '../../services/entries'
import { convertEntriesAttributes } from '../../utils/utils'

import { faEraser, faFileExcel, faFilePdf, faSearch } from '@fortawesome/free-solid-svg-icons'
import Select from '../../components/Form/Select'
import { listBrandsAll } from '../../services/brand'
import { brand } from '../../types/brand'
import dayjs from 'dayjs'
import { listByDestinationCode } from '../../services/resident'
import { origin } from '../../types/origin'
import { listOriginAll } from '../../services/origin'
import { typeQrEnumString } from '../../types/qr'
import FilesWachs from '../../components/CheckIn/SocketNotifications/filesWachs'
import { LightTooltip } from '../../components/tooltips/text'
import Icon, {
  ClockCircleOutlined,
  CloseCircleOutlined,
  DislikeOutlined
} from '@ant-design/icons'
import { PreViewCheckIn } from './PreViewCheckIn'
import Modal from '../../components/GeneralComponents/Modal'

const ReportsPage = () => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(dayjs().startOf('day'))
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs().endOf('day'))
  const { user, token } = useAuth()
  const OkId = user?.client?.OkId
  const [isopenModal, setisopenModal] = useState<boolean>(false)
  const [selectEntrie, setSelectEntrie] = useState<entries>()

  const [data, setData] = useState<Pagination<entries>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const [brands, setBrands] = useState<brand[]>([])
  const [origins, setOrigins] = useState<origin[]>([])
  const [formData, setFormData] = useState<any>({
    destinationCode: ''
  })
  const [optionsDestinations, setOptionsDestinations] = useState<
    { label: string; value: string }[]
  >([])
  const [searchedValue, setSearchedValue] = useState<string>()
  const { startLoading, stopLoading } = useLoading()

  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    WAITING: <ClockCircleOutlined style={{ color: 'yellow' }} />,
    APPROVED: <CheckCircleOutlined style={{ color: 'green' }} />,
    REJECT: <DislikeOutlined style={{ color: 'red' }} />,
    RECESSION: <Icon component={waitInLobby} />,
    LEAVE: <Icon component={leaveInLobby} />,
    NO_LEAVE: <Icon component={noLeaveInLobby} />,
    UNANSWERED: <CloseCircleOutlined style={{ color: 'red' }} />
  }

  const getDataCodes = async () => {
    try {
      const values = await listByDestinationCode({
        search: searchedValue || '',
        clientId: user?.clientId as string
      })
      setOptionsDestinations(
        values.map(e => ({
          label: `${e.destinationCode} ${e.name} ${e.lastName}`,
          value: e.destinationCode
        }))
      )
    } catch (error) {
      console.error(error)
    }
  }
  const values = [
    {
      label: 'Nombres',
      value: 'name'
    },
    {
      label: 'Apellidos',
      value: 'lastName'
    },
    {
      label: 'DNI',
      value: 'DNI'
    }
  ]
  const handleChange = (name: string, value: string | boolean) => {
    setFormData((prevData: any) => ({ ...prevData, [name]: value }))
  }
  const onSearch = async (page?: number) => {
    try {
      startLoading()

      const startDateG = moment(startDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')
      const endDateG = moment(endDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')

      const resp = await getDataReport({
        id: user?.clientId as string,
        token: token as string,
        params: {
          startDate: startDate ? startDateG : null,
          endDate: endDate ? endDateG : null,
          page: page || data.currentPage,
          ...formData
        }
      })
      setData({ ...resp, data: resp.data.map(convertEntriesAttributes) })
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const onGenerate = async (typeDocument: string) => {
    try {
      startLoading()

      const startDateG = moment(startDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')
      const endDateG = moment(endDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')

      const value = await generateReport({
        id: user?.clientId as string,
        token: token as string,
        params: {
          startDate: startDate ? startDateG : null,
          endDate: endDate ? endDateG : null,
          ...formData,
          typeDocument
        }
      })

      // Hacer fetch para obtener el archivo
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_BACKEND}/report/${value.url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = value.url // Nombre del archivo descargado
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      // Liberar el objeto URL
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(`error al crear reporte`, error) // Maneja el error
    } finally {
      stopLoading()
    }
  }

  const getBrands = async () => {
    const brands = await listBrandsAll(token)
    setBrands(brands)
    const origins = await listOriginAll(token)
    setOrigins(origins)
  }
  const handleChangeText = (value: string) => {
    setSearchedValue(value)
  }
  const handleSearch = () => {
    setData(prevData => ({ ...prevData, currentPage: 1 }))
    onSearch(1)
  }
  const handleClear = () => {
    setFormData({
      destinationCode: ''
    })
    setStartDate(null)
    setEndDate(null)
    setSearchedValue(undefined)
  }

  const handleView = (dataCheckIn: entries) => {
    setSelectEntrie(dataCheckIn)
    setisopenModal(true)
  }

  const closeModal = () => {
    setSelectEntrie(undefined)
    setisopenModal(false)
  }

  useEffect(() => {
    getBrands()
  }, [])
  useEffect(() => {
    if (searchedValue && searchedValue.length) {
      getDataCodes()
    }
  }, [searchedValue])

  const svgOkid = (item: entries | undefined) => {
    if (item?.okid && user?.role === 'admin') {
      const dataOkid = parseInt(item?.okid)
      if (item.FECHA_DEFUNCION) {
        return <Warning style={{ color: 'red' }} />
      }
      if (dataOkid >= 70) {
        return <DoneAllIcon style={{ color: 'green' }} />
      }
      if (dataOkid >= 70) {
        return <PriorityHighIcon style={{ color: 'yellow' }} />
      } else {
        return <Warning style={{ color: 'red' }} />
      }
    }
  }

  const valueTable = OkId ? columnsCheckInOkId : columnsCheckIn
  const showAProved: TableColumn[] = [
    ...valueTable,

    {
      title: 'Estado',
      dataIndex: 'approved',
      key: 'approved',
      type: enumTypeColumn.custom,
      CustomRender: ({ item }: { item?: entries }) => {
        return item?.OKIDNumber ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '30px',
              gap: '0.5rem'
            }}
          >
            {svgOkid(item)}

            {item?.OKIDNumber && user?.role === 'admin' && (
              <IconButton onClick={() => handlePdfOkId(item?.OKIDNumber)}>
                <PictureAsPdfIcon style={{ color: 'red' }} />
              </IconButton>
            )}
          </div>
        ) : (
          <>
            <LightTooltip
              title={
                item && item.cameraIn && item.authorizedIn && item.approved !== 'WAITING' ? (
                  <>
                    {`INGRESO:${item?.cameraIn}\n${item?.authorizedIn}`}
                    {item?.cameraOut
                      ? `\nEGRESO:${item?.cameraOut}\n${item?.authorizedOut}`
                      : ''}
                  </>
                ) : (
                  'ESPERANDO'
                )
              }
              followCursor
              TransitionComponent={Fade}
            >
              <div className="svgStatus">
                {item?.approved ? statusSvg[item?.approved] : statusSvg.WAITING}
                {item && svgOkid(item)}
              </div>
            </LightTooltip>

            {item && (
              <IconButton onClick={() => handleView(item)}>
                <Visibility style={{ color: 'white' }} />
              </IconButton>
            )}
          </>
        )
      }
    }
  ]

  const handlePdfOkId = async (id?: string) => {
    if (id) {
      try {
        startLoading()
        console.log(id)
        const dataPdf64 = await DownloadPdfOkId(id, token) // Pedimos el base64 del pdf

        // Convierte el base64 en un objeto Blob
        const pdfBlob = base64ToBlob(dataPdf64?.pdf64, 'application/pdf')

        // Crea un enlace para descargar el PDF
        const pdfUrl = URL.createObjectURL(pdfBlob)

        // Puedes mostrar un modal con el PDF o descargarlo directamente
        window.open(pdfUrl)

        // También puedes crear un enlace de descarga
        const downloadLink = document.createElement('a')
        downloadLink.href = pdfUrl
        downloadLink.download = 'document.pdf'
        downloadLink.click()
      } catch (error) {
        console.error('Error al crear reporte', error) // Maneja el error
      } finally {
        stopLoading()
      }
    }
  }

  const base64ToBlob = (base64: string, contentType: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }

  return (
    <div className="reportPage">
      <div className="containerTitle">
        <div className="title">Consultar Registros</div>
      </div>
      <div className="containerFilters">
        <div className="containerInputFilters">
          <div className="container-top">
            <div className="inputDark">
              <RangePicker
                value={{
                  start: startDate,
                  end: endDate
                }}
                onChange={({ start, end }) => {
                  if (start) {
                    setStartDate(start)
                  }
                  if (end) {
                    setEndDate(end)
                  }
                }}
              />
            </div>

            {values.map(e => (
              <div key={e.value} className="inputDark" style={{ marginBottom: '10px' }}>
                <InputDark
                  placeholder={e.label}
                  value={formData ? formData[e?.value] ?? '' : ''}
                  onChange={value => handleChange(e?.value, value.target.value)}
                />
              </div>
            ))}
          </div>

          <div className="container-bottom">
            <div className="container-select">
              <Select
                placeholder={'Origen'}
                options={origins.map(e => ({ value: e.name, label: e.name }))}
                value={formData['origin']}
                onChange={value => handleChange('origin', value)}
              />
            </div>
            <div className="container-autocomplete">
              <AutoComplete
                label="Destino"
                options={optionsDestinations}
                onChangeText={handleChangeText}
                value={formData['destinationCode']}
                onChange={value => {
                  handleChange('destinationCode', value)
                  setSearchedValue(undefined)
                }}
              />
            </div>

            <div className="container-select">
              <Select
                placeholder={'Marca'}
                options={brands.map(e => ({ value: e.name, label: e.name }))}
                value={formData['brand']}
                onChange={value => handleChange('brand', value)}
              />
            </div>
            <div className="container-select">
              <Select
                placeholder={'Tipo'}
                options={Object.keys(typeQrEnumString).map(key => ({
                  label: typeQrEnumString[key as keyof typeof typeQrEnumString],
                  value: key
                }))}
                value={formData['type']}
                onChange={value => handleChange('type', value)}
              />
            </div>
          </div>
        </div>
        <div className="containerButtonsFilters">
          <Button
            text="Buscar"
            icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faSearch} />}
            onClick={handleSearch}
          />
          <Button
            icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faEraser} />}
            text="Limpiar"
            onClick={handleClear}
          />
          {data.data.length > 0 && (
            <Button
              icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faFileExcel} />}
              text="Generar Excel"
              onClick={() => onGenerate('excel')}
            />
          )}
          {data.data.length > 0 && (
            <Button
              icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faFilePdf} />}
              text="Generar PDF"
              onClick={() => onGenerate('pdf')}
            />
          )}
        </div>
      </div>
      <Table
        pagination={data}
        onPageChange={page => {
          setData(prevData => ({ ...prevData, currentPage: page }))
          onSearch(page)
        }}
        columns={showAProved}
        itemsPerPage={6}
        canAdd={false}
      />
      {user?.role === 'reception' && <FilesWachs />}

      <Modal
        isOpen={isopenModal}
        borderColor={true}
        onClose={closeModal}
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '770px',
          height: '400px',
          padding: '0px',
          overflow: 'hidden',
          margin: '3px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
        style_Subcontainer={{ overflow: 'hidden', padding: '0rem 0.5rem' }}
      >
        <PreViewCheckIn Entrie={selectEntrie} />
      </Modal>
    </div>
  )
}

export default ReportsPage

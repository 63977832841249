import React, { FC } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { createWorker, updateWorker } from '../../../services/worker'
import { Worker, WorkerCreate } from '../../../types/worker'
import DynamicForm from '../../Form/DynamicForm'
import { workerFormConfig } from './Columns'
import './style.scss'
import { useAuth } from '../../../Provider/AuthProvider'
import {} from '../../../utils/utils'

const FormWorker: FC<{
  onSubmit: () => void
  residentId: string
  initialValue?: Worker
}> = ({ onSubmit, residentId, initialValue }) => {
  const { startLoading, stopLoading, showMessage } = useLoading()
  const { user } = useAuth()
  const handleSubmit = async (formData: WorkerCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        phoneNumber: formData.phoneNumber,
        ...(formData.photo ? { photo: formData.photo } : {}),
        ...(formData.DNI_A ? { DNI_A: formData.DNI_A } : {}),
        ...(formData.DNI_B ? { DNI_B: formData.DNI_B } : {}),
        residentId,
        clientId: user?.clientId as string
      }).forEach(([key, value]) => {
        data.append(key, value)
      })

      if (initialValue) {
        await updateWorker(initialValue.id, data)
      } else {
        await createWorker(data)
      }

      onSubmit()
    } catch (error: any) {
      showMessage('error', `${error.response.data.details}`)
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="newDelegateResidentForm">
      <h2>{initialValue ? 'Actualizar' : 'Nuevo'} trabajador</h2>
      <DynamicForm
        initialValues={initialValue}
        formConfig={workerFormConfig}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default FormWorker

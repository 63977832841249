import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../Provider/AuthProvider'
import './style.scss'
import Table, { enumTypeColumn, TableColumn } from '../../components/Table'
import { useLoading } from '../../Provider/LoadingProvider'
import { Pagination } from '../../types/types'
import Modal from '../../components/GeneralComponents/Modal'
import { Navigate } from 'react-router-dom'

import { Visibility } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import DeleteButton from '../../components/Table/components/DeleteButton'
import { PaymentConcept } from '../../types/Payments'
import FormPaymentconcepts from '../../components/Tables/PaymentconceptsPage/Form'
import { columnsPaymentConcept } from '../../components/Tables/PaymentconceptsPage/Columns'
import {
  listPaymentConcept,
  deletePaymentConcept,
  setGlobalConcepts
} from '../../services/Payments'
import Switch from '../../components/Form/Switch'

const CheckFiles = () => {
  const { user } = useAuth()

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<PaymentConcept | undefined>(undefined)
  const [search, setSearch] = useState('')
  const [data, setData] = useState<Pagination<PaymentConcept>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const { startLoading, stopLoading } = useLoading()
  const isFirstRender = useRef(true)

  useEffect(() => {
    getDataPaymentsConcepts('', 1)
  }, [])

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    } else {
      delayDebounceFn = setTimeout(() => {
        getDataPaymentsConcepts(search, 1)
      }, 500)
    }

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  if (!user || !user.clientId) {
    return <Navigate to="/login" />
  }

  const id = user.clientId
  const limit = 6
  const getDataPaymentsConcepts = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const paymentConcept = await listPaymentConcept({
        id,
        params: { page, search: searchValue, limit }
      })
      setData(paymentConcept)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const Actions = ({ id, item }: { id: string; item: PaymentConcept }) => {
    return (
      <div className="actionsContainer">
        <Tooltip
          title="Actualizar"
          placement="top-end"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#261a3a', // Color de fondo
                color: 'white', // Color del texto
                fontSize: '14px', // Tamaño del texto
                padding: '8px 12px', // Espaciado interno
                borderRadius: '8px', // Bordes redondeados
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
              }
            }
          }}
        >
          <IconButton onClick={() => handleView(item as PaymentConcept)}>
            <Visibility style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Eliminar"
          placement="top-end"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#261a3a', // Color de fondo
                color: 'white', // Color del texto
                fontSize: '14px', // Tamaño del texto
                padding: '8px 12px', // Espaciado interno
                borderRadius: '8px', // Bordes redondeados
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
              }
            }
          }}
        >
          <IconButton style={{ padding: '0px', margin: '0px' }}>
            <DeleteButton OnDelete={() => handleDelete(id)} />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deletePaymentConcept(id)
      getDataPaymentsConcepts(search, data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleView = (value: PaymentConcept) => {
    setSelected(value)
    setOpen(true)
  }

  const handlePageChange = (newPage: number) => {
    getDataPaymentsConcepts(search, newPage)
  }

  const handleBoolean = async (id: string) => {
    setData(lastData => ({
      ...lastData,
      data: lastData.data.map(item =>
        item.id === id ? { ...item, global: !item.global } : item
      )
    }))

    await setGlobalConcepts(id)
  }

  const handleOpenModal = () => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setSelected(undefined)
    setOpen(false)
  }

  const handleSubmit = () => {
    setOpen(false)
    setSelected(undefined)
    getDataPaymentsConcepts(search, data.currentPage)
  }

  const newColumnsPaymentConcept: TableColumn[] = [
    ...columnsPaymentConcept,
    {
      title: 'OBLIGATORIO',
      dataIndex: 'OBLIGATORIO',
      key: 'global',
      type: enumTypeColumn.custom,
      CustomRender: ({ id, item }: { id: string; item?: PaymentConcept }) => {
        if (!item) return null

        const datePoup = {
          title: 'ADVERTENCIA',
          description: `Estas a punto de aplicar este cargo como ${
            item.global ? 'opcional' : 'obligatorio'
          } a todos los residentes`,
          acceptText: 'CONTINUAR',
          rejectText: 'CANCELAR'
        }

        return (
          <Switch
            value={item.global}
            onChange={() => {
              handleBoolean(item.id)
            }}
            PopUp={datePoup}
            disabled={item.type !== 'Cuota'}
          />
        )
      }
    }
  ]

  return (
    <div className="homeContainer">
      <div className="containerTitle">
        <h4>Conceptos</h4>
      </div>
      <div className="tableContainer">
        <Table
          onPageChange={handlePageChange}
          onChangeSearch={setSearch}
          pagination={data}
          handleBoolean={handleBoolean}
          handleOpenModal={handleOpenModal}
          columns={newColumnsPaymentConcept}
          Actions={Actions as any}
          itemsPerPage={limit}
        />
      </div>
      <Modal styleContainer={{ width: '900px' }} isOpen={open} onClose={onCloseModal}>
        <FormPaymentconcepts initialValue={selected} clientId={id} onSubmit={handleSubmit} />
      </Modal>
    </div>
  )
}

export default CheckFiles

import React, { useState, ButtonHTMLAttributes, ReactNode } from 'react'
import './style.scss'
import { BorderIpass } from '../../BorderIpass/Index'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void
  text?: string
  styleType?: 'normal' | 'cancel' | 'disable'
  icon?: ReactNode
  selected?: boolean
  enableHover?: boolean
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  text,
  styleType = 'normal',
  icon,
  selected = false,
  enableHover = true,
  disabled,
  ...buttonProps
}) => {
  const [hover, setHover] = useState(false)

  return (
    <BorderIpass style={{ borderRadius: '8px' }}>
      <button
        {...buttonProps}
        className={`custom-button ${styleType} ${selected ? 'selected' : ''}`}
        onClick={!disabled ? onClick : undefined}
        onMouseEnter={() => enableHover && !disabled && setHover(true)}
        onMouseLeave={() => enableHover && !disabled && setHover(false)}
        disabled={disabled}
        style={{
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? 'not-allowed' : 'pointer',
          background: disabled ? '#555' : hover && enableHover ? '#7C1084' : '#261a3a'
        }}
      >
        {icon && <span className="button-icon">{icon}</span>}
        <span className="button-text">{text}</span>
      </button>
    </BorderIpass>
  )
}

export default Button

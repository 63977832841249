// Modal.tsx
import React, { FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import './style.scss'
import { Tooltip } from '@mui/material'

type ModalProps = {
  isOpen: boolean
  borderColor?: boolean
  onClose: () => void
  children: ReactNode
  styleContainer?: React.CSSProperties
  styleContainerContent?: React.CSSProperties
  style_Subcontainer?: React.CSSProperties
  styleClose?: React.CSSProperties
  overlayStyle?: React.CSSProperties
  typeborder?: 'borderAnimateIpass' | 'borderAnimateIpassError' | 'borderAnimateIpassDisable'
  position?: 'center' | 'left'
}

const Modal: FC<ModalProps> = ({
  isOpen,
  borderColor = false,
  styleContainerContent,
  onClose,
  children,
  styleContainer,
  styleClose,
  style_Subcontainer,
  overlayStyle,
  typeborder = 'borderAnimateIpass',
  position = 'center'
}) => {
  return isOpen
    ? createPortal(
        <div style={overlayStyle} className={`modal-overlay`}>
          <div
            style={styleContainer}
            className={`modal ${position === 'left' ? 'modal-left' : ''}`}
          >
            <div
              style={styleContainerContent}
              className={`content ${borderColor ? `${typeborder} borderModal` : ''}`}
            >
              <div
                className={`subContent  ${borderColor ? 'inner' : ''}`}
                style={style_Subcontainer}
              >
                {children}
              </div>
            </div>
            <Tooltip
              title="Salir"
              placement="right-start"
              slotProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#261a3a', // Color de fondo
                    color: 'white', // Color del texto
                    fontSize: '14px', // Tamaño del texto
                    padding: '8px 12px', // Espaciado interno
                    borderRadius: '8px', // Bordes redondeados
                    boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                  }
                }
              }}
            >
              <button className="close-button" onClick={onClose} style={styleClose}>
                <FontAwesomeIcon icon={faRightFromBracket} />
              </button>
            </Tooltip>
          </div>
        </div>,
        document.body
      )
    : null
}

export default Modal

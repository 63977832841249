import React, { useState } from 'react'
import { BorderIpass } from '../../BorderIpass/Index'
import PopUpConfirm from '../../Table/components/PopupConfirm'

interface PopUpProps {
  title: string
  description?: string
  acceptText: string
  rejectText: string
}
interface ButtonBProps {
  style?: React.CSSProperties
  onClick?: () => void
  disabled?: boolean
  children?: React.ReactNode
  text?: string
  className?: string
  enableHover?: boolean
  icon?: React.ReactNode
  selected?: boolean
  status?: 'Normal' | 'Disable' | 'Error' | 'NO_BORDER'
  PopUp?: PopUpProps
}

export const ButtonB: React.FC<ButtonBProps> = ({
  className,
  style,
  onClick,
  text,
  disabled = false,
  enableHover = true,
  icon,
  selected = false,
  status = 'Normal',
  PopUp
}) => {
  const [hover, setHover] = useState(false)

  const [showPopUp, setShowPopUp] = useState(false)

  const handleClick = () => {
    if (disabled) return

    if (PopUp) {
      setShowPopUp(true)
    } else {
      onClick?.()
    }
  }

  const handleAccept = () => {
    setShowPopUp(false)
    onClick?.() // Ejecuta la acción después de aceptar
  }

  const handleReject = () => {
    setShowPopUp(false)
  }

  return (
    <>
      <BorderIpass
        style={{
          borderRadius: '10px',
          border: selected ? '2px solid white' : '1px solid transparent',
          width: '180px',
          opacity: disabled ? 0.5 : 1, // Reduce opacidad si está deshabilitado
          cursor: disabled ? 'not-allowed' : 'pointer', // Cambia cursor
          ...style // Permite sobrescribir estilos
        }}
        onClick={handleClick}
        status={status}
        styleInner={{
          ...SelectStyles,
          backgroundColor: disabled ? '#555' : hover && enableHover ? '#7C1084' : '#261a3a'
        }}
        onMouseEnter={() => enableHover && !disabled && setHover(true)}
        onMouseLeave={() => enableHover && !disabled && setHover(false)}
      >
        <div>
          {icon && <span>{icon}</span>} {/* Renderizar icono si existe */}
          <span>{text}</span>
        </div>
      </BorderIpass>
      {PopUp && showPopUp && (
        <PopUpConfirm
          isOpen={showPopUp}
          onClose={handleReject}
          handleAccept={handleAccept}
          handleReject={handleReject}
          title={PopUp.title}
          description={PopUp.description}
          acceptText={PopUp.acceptText}
          rejectText={PopUp.rejectText}
        />
      )}
    </>
  )
}

const SelectStyles: React.CSSProperties = {
  margin: '2px',
  height: '40px',
  color: 'white',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '8px',
  // backgroundColor: '#6a0dad', // Color inicial
  transition: 'background-color 0.3s ease'
}

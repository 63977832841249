import React, { FC, useEffect, useState, useRef } from 'react'
import { useAuth } from '../../Provider/AuthProvider'
import { listGraphPayment } from '../../types/Payments'
import ReactECharts from 'echarts-for-react'
import './style.scss'

import { getDasboardGraphPayments } from '../../services/Payments'
import { useLoading } from '../../Provider/LoadingProvider'

import { LightTooltip } from '../../components/tooltips/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { backdropClasses, Fade, IconButton } from '@mui/material'
import {
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleArrowRight
} from '@fortawesome/free-solid-svg-icons'
import Icon from '@ant-design/icons'

import { ReactComponent as ACREDITADO } from '../../assets/svg/ACREDITADO.svg'
import { ReactComponent as POR_COBRAR } from '../../assets/svg/POR_COBRAR.svg'
import { ExpandLess, ExpandMore, Update } from '@mui/icons-material'
import { fontSize } from '@mui/system'

const graphResident = {
  total: 0,
  enVerificacion: 0,
  mora: 0,
  alDia: 0
}

const KPIS = {
  KPI_1_1_paymes: 0,
  KPI_1_1_charges: 0,
  KPI_1_2_paymes: 0,
  KPI_1_2_charges: 0,
  KPI_2_1: 0,
  KPI_2_2: 0,
  KPI_2_3: 0,
  KPI_2_4: 0,
  KPI_3_1: 0,
  KPI_3_2: 0,
  KPI_3_3: 0
}

const graphProjected = {
  total: 0,
  month: 0,
  CC: 0,
  payments: 0
}

const graphDistribution = { cuotas: 0, cargos: 0, rsrvs: 0, mora: 0, multas: 0 }

const generalInit = {
  graphResident,
  graphProjected,
  graphDistribution,
  KPIS,
  date: ''
}

export const ChartsAcreditamiento: FC<{ id: string }> = ({ id }) => {
  const { SocketWhats, SocketBackEnd } = useAuth()
  const [slider, setSlider] = useState(true)
  const { startLoading, stopLoading } = useLoading()
  const [GeneralData, setGeneralData] = useState<listGraphPayment>(generalInit)

  useEffect(() => {
    const eventName = `payment-${id}`

    const eventAction = (message: string) => {
      getGeneralData(message)
    }

    if (SocketWhats) {
      SocketWhats.on(eventName, eventAction)
    }

    if (SocketBackEnd) {
      SocketBackEnd.on(eventName, eventAction)
    }

    if (id) {
      getGeneralData(id)
    }

    return () => {
      SocketWhats && SocketWhats.off(eventName, eventAction)
      SocketBackEnd && SocketBackEnd.off(eventName, eventAction)
    }
  }, [id, SocketWhats, SocketBackEnd])

  const getGeneralData = async (id: string) => {
    try {
      startLoading()
      const data = await getDasboardGraphPayments(id)
      setGeneralData(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const barProjected = () => {
    const { total, month, CC, payments } = GeneralData.graphProjected

    const categories = ['TOTAL', 'MES', 'CC', 'PAGADO']

    const colors = [
      '#e516bb', // Rosa fuerte
      '#d414c0', // Rosa intenso
      '#c218c5', // Rosa-morado
      '#b11ccb' // Morado claro
    ]

    const data = categories.map((name, index) => ({
      name,
      value: [total, month, CC, payments][index],
      color: colors[index]
    }))

    const formatCurrency = (value: number): string => {
      return `Q${value.toLocaleString('en-US', {
        minimumFractionDigits: 2, // Siempre muestra 2 decimales
        maximumFractionDigits: 2 // Nunca muestra más de 2 decimales
      })}`
    }

    return {
      title: {
        text: `INGRESOS`,
        subtext: `${GeneralData.date}`,
        left: 'center',
        textStyle: {
          color: '#fff',
          fontSize: 16
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          if (Array.isArray(params)) {
            return params
              .map(
                param =>
                  `${param.marker} ${param.name}: ${formatCurrency(param.value as number)}`
              )
              .join('<br>')
          }
          return `${params.marker} ${params.name}: ${formatCurrency(params.value as number)}`
        }
      },
      grid: {
        // left: '5%', // Margen izquierdo
        right: '0%', // Margen derecho
        bottom: '10%', // Margen inferior
        top: '25%',
        containLabel: true // Asegura que las etiquetas no se corten
      },
      xAxis: {
        type: 'category',
        data: categories,
        axisLabel: {
          color: '#fff',
          fontSize: window.innerWidth < 1000 ? 8 : 12
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#fff',
          formatter: (value: number) => `Q${abreviarNumero(value)}` // Formatea los valores del eje Y
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.2)'
          }
        }
      },
      series: [
        {
          type: 'bar',
          data: data.map(item => ({
            name: item.name,
            value: item.value,
            itemStyle: {
              color: item.color
            }
          })),
          label: {
            show: true,
            position: 'top',
            color: '#fff',
            fontSize: window.innerWidth < 1000 ? 8 : 12,
            fontWeight: 'bold',
            formatter: (params: { value: number }) => formatCurrency(params.value) // Formatea las etiquetas de las barras
          },
          barWidth: '50%' // Ajuste de tamaño de barra
        }
      ],
      backgroundColor: '#2a1744' // Fondo azul oscuro
    }
  }

  const barDistribution = () => {
    const { cuotas, cargos, rsrvs, mora, multas } = GeneralData.graphDistribution

    const categories: string[] = ['CUOTAS', 'CARGOS', 'MULTAS', 'RSRVS', 'MORA']

    const colors: string[] = [
      '#a621cf', // Morado claro
      '#9525d4', // Morado medio
      '#e516bb',
      '#8d25d9', // Morado medio
      '#7a28e0' // Morado oscuro
    ]

    const formatCurrency = (value: number): string => {
      return `Q${value.toLocaleString('en-US', {
        minimumFractionDigits: 2, // Siempre muestra 2 decimales
        maximumFractionDigits: 2 // Nunca muestra más de 2 decimales
      })}`
    }

    const data = categories.map((name, index) => ({
      name,
      value: [cuotas, cargos, multas, rsrvs, mora][index],
      color: colors[index]
    }))

    return {
      title: {
        text: `CUENTAS DEL MES`,
        subtext: `${GeneralData.date}`,
        left: 'center',
        textStyle: {
          color: '#fff',
          fontSize: 16
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          if (Array.isArray(params)) {
            return params
              .map(
                param =>
                  `${param.marker} ${param.name}: ${formatCurrency(param.value as number)}`
              )
              .join('<br>')
          }
          return `${params.marker} ${params.name}: ${formatCurrency(params.value as number)}`
        }
      },
      grid: {
        left: '5%', // Margen izquierdo
        right: '0%', // Margen derecho
        bottom: '10%', // Margen inferior
        top: '25%',
        containLabel: true // Asegura que las etiquetas no se corten
      },
      xAxis: {
        type: 'category',
        data: categories,
        axisLabel: {
          color: '#fff',
          fontSize: window.innerWidth < 1000 ? 6 : 12
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#fff',
          formatter: (value: number) => `Q${abreviarNumero(value)}` // Formatea los valores del eje Y
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.2)'
          }
        }
      },
      series: [
        {
          type: 'bar',
          data: data.map(item => ({
            name: item.name,
            value: item.value,
            itemStyle: {
              color: item.color
            }
          })),
          label: {
            show: true,
            position: 'top',
            color: '#fff',
            fontSize: window.innerWidth < 1000 ? 8 : 12,

            fontWeight: 'bold',
            formatter: (params: { value: number }) => formatCurrency(params.value) // Formatea las etiquetas de las barras
          },
          barWidth: '50%' // Ajuste de tamaño de barra
        }
      ],
      backgroundColor: '#2a1744' // Fondo azul oscuro
    }
  }

  const barResident = () => {
    const { total, alDia, enVerificacion, mora } = GeneralData.graphResident

    const categories = ['TOTAL', 'AL DIA', 'VRF', 'MORA']

    const colors = [
      '#7528e3', // Morado oscuro
      '#6125f0', // Azul-morado intenso
      '#4d22eb', // Azul-morado más oscuro
      '#391de6' // Azul profundo
    ]

    const data = categories.map((name, index) => ({
      name,
      value: [total, alDia, enVerificacion, mora][index],
      color: colors[index]
    }))

    return {
      title: {
        text: `ESTADO RESIDENTES`,
        subtext: `${GeneralData.date}`,
        left: 'center',
        textStyle: {
          color: '#fff',
          fontSize: 16
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '5%', // Margen izquierdo
        right: '0%', // Margen derecho
        bottom: '10%', // Margen inferior
        top: '25%',
        containLabel: true // Asegura que las etiquetas no se corten
      },
      xAxis: {
        type: 'category',
        data: categories,
        axisLabel: {
          color: '#fff',
          fontSize: window.innerWidth < 1000 ? 8 : 12
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#fff'
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.2)'
          }
        }
      },
      series: [
        {
          type: 'bar',
          data: data.map(item => ({
            name: item.name,
            value: item.value,
            itemStyle: {
              color: item.color
            }
          })),
          label: {
            show: true,
            position: 'top',
            color: '#fff',
            fontSize: window.innerWidth < 1000 ? 8 : 12,

            fontWeight: 'bold'
          },
          barWidth: '50%' // Ajuste de tamaño de barra
        }
      ],
      backgroundColor: '#2a1744' // Fondo azul oscuro
    }
  }

  function abreviarNumero(numero: number): string {
    if (!numero) return '0' // Si el número es 0 o no está definido, retornar '0'

    const unidades = ['', 'K', 'M', 'B', 'T'] // Unidades: miles, millones, mil millones, billones

    // Si el número es menor a 1, no se abrevia
    if (numero < 1) {
      return numero.toFixed(1) // Retornar el número con 1 decimal
    }

    const escala = Math.floor(Math.log10(numero) / 3) // Determinar la escala (K, M, B, T)

    // Si la escala es 0 o el número es menor a 1000, no se abrevia
    if (escala === 0) {
      return numero.toFixed(1) // Retornar el número con 1 decimal
    }

    // Si la escala es mayor que las unidades disponibles, usar la última unidad
    if (escala >= unidades.length) {
      return `${(numero / Math.pow(1000, unidades.length - 1)).toFixed(1)}${
        unidades[unidades.length - 1]
      }`
    }

    const abreviado = (numero / Math.pow(1000, escala)).toFixed(1) // Redondear a 1 decimal
    return `${abreviado}${unidades[escala]}` // Devolver el número abreviado
  }

  const safeDivide = (numerator?: number, denominator?: number): number => {
    const validNumerator = numerator && !isNaN(numerator) ? numerator : 0
    if (!denominator || isNaN(denominator)) return 0

    const porcent = (validNumerator / denominator) * 100
    return Math.floor(porcent)
  }

  const suggestion = (currentMonth: number, lastMonth: number) => {
    const diff = currentMonth - lastMonth
    if (diff < 0) return `SI QUIERES IGUAL RECAUDA: Q${Math.abs(diff).toFixed(2)}.`
    if (diff > 0)
      return `FELICIDADES LLEVAS UNA DIFERENCIA A FAVOR ESTE MES DE: Q${Math.abs(diff).toFixed(
        2
      )}.`
    else return `HAZ RECAUDADO LA MISMA CANTIDAD DEL MES PASADO : Q${currentMonth}.`
  }

  const diffLastMonth = (currentMonth: number, lastMonth: number) => {
    const porcent = safeDivide(currentMonth, lastMonth)
    return porcent - 100
  }

  return (
    <>
      <div className="Container_kpis">
        <div style={{ position: 'absolute', left: '-40px', bottom: '-25px' }}>
          <IconButton onClick={() => setSlider(!slider)}>
            {slider ? (
              <ExpandMore style={{ color: 'white' }} />
            ) : (
              <ExpandLess style={{ color: 'white' }} />
            )}
          </IconButton>
        </div>

        <li className="generalInf">
          <LightTooltip title={`INGRESOS`} followCursor TransitionComponent={Fade}>
            <Icon component={ACREDITADO} className="icon" />
          </LightTooltip>
          <div>
            <LightTooltip
              title={`TOTAL ACREDITADO: Q${GeneralData.KPIS.KPI_1_1_paymes.toFixed(2)}`}
              followCursor
              TransitionComponent={Fade}
            >
              <span>{`${abreviarNumero(GeneralData.KPIS.KPI_1_1_paymes)} /`} </span>
            </LightTooltip>

            <LightTooltip
              title={`PORCENTAJE INGRESOS DEL MES \nACREDITADO MES ACTUAL: Q${GeneralData.KPIS.KPI_1_1_paymes}\nINGRESOS ESTIMADO DEL MES ACTUAL: Q${GeneralData.KPIS.KPI_1_1_charges}`}
              followCursor
              TransitionComponent={Fade}
            >
              <span>{`${safeDivide(
                GeneralData.KPIS.KPI_1_1_paymes,
                GeneralData.KPIS.KPI_1_1_charges
              )}%`}</span>
            </LightTooltip>
          </div>

          <div>
            <LightTooltip
              title={`TOTAL ACREDITADO MES ANTERIOR: Q${GeneralData.KPIS.KPI_1_2_paymes.toFixed(
                2
              )}`}
              followCursor
              TransitionComponent={Fade}
            >
              <span>{`${abreviarNumero(GeneralData.KPIS.KPI_1_2_paymes)} / `} </span>
            </LightTooltip>

            <LightTooltip
              title={`PORCENTAJE INGRESOS DEL MES ANTERIOR\nACREDITADO MES ANTERIOR: Q${GeneralData.KPIS.KPI_1_2_paymes}\nINGRESOS ESTIMADO DEL MES ANTERIOR: Q${GeneralData.KPIS.KPI_1_2_charges}`}
              followCursor
              TransitionComponent={Fade}
            >
              <span>{`${safeDivide(
                GeneralData.KPIS.KPI_1_2_paymes,
                GeneralData.KPIS.KPI_1_2_charges
              )}%`}</span>
            </LightTooltip>
          </div>

          <LightTooltip
            title={`ESTE MES Q${GeneralData.KPIS.KPI_1_1_paymes}\nRESPECTO DEL MES ANTERIOR Q${
              GeneralData.KPIS.KPI_1_2_paymes
            }\n${suggestion(
              GeneralData.KPIS.KPI_1_1_paymes,
              GeneralData.KPIS.KPI_1_2_paymes
            )}`}
            followCursor
            TransitionComponent={Fade}
          >
            <span>
              <FontAwesomeIcon
                icon={
                  diffLastMonth(
                    GeneralData.KPIS.KPI_1_1_paymes,
                    GeneralData.KPIS.KPI_1_2_paymes
                  ) > 0
                    ? faCircleArrowUp
                    : diffLastMonth(
                        GeneralData.KPIS.KPI_1_1_paymes,
                        GeneralData.KPIS.KPI_1_2_paymes
                      ) < 0
                    ? faCircleArrowDown
                    : faCircleArrowRight
                }
                className={`icon ${
                  diffLastMonth(
                    GeneralData.KPIS.KPI_1_1_paymes,
                    GeneralData.KPIS.KPI_1_2_paymes
                  ) > 0
                    ? 'icon-positive'
                    : diffLastMonth(
                        GeneralData.KPIS.KPI_1_1_paymes,
                        GeneralData.KPIS.KPI_1_2_paymes
                      ) < 0
                    ? 'icon-negative'
                    : 'icon-neutral'
                }`}
              />
              <span>{`${Math.abs(
                diffLastMonth(GeneralData.KPIS.KPI_1_1_paymes, GeneralData.KPIS.KPI_1_2_paymes)
              )}`}</span>
            </span>
          </LightTooltip>
        </li>

        <li className="generalInf">
          <LightTooltip title={`MORA`} followCursor TransitionComponent={Fade}>
            <Update className="icon" />
          </LightTooltip>

          <LightTooltip title={`PROYECCION MORA`} followCursor TransitionComponent={Fade}>
            <span>{`${GeneralData.KPIS.KPI_2_1}%`}</span>
          </LightTooltip>

          <LightTooltip
            title={`MORA DEL MES EN CURSO`}
            followCursor
            TransitionComponent={Fade}
          >
            <span>{`${GeneralData.KPIS.KPI_2_2}%`}</span>
          </LightTooltip>

          <LightTooltip
            title={`MORA DEL MES ANTERIOR`}
            followCursor
            TransitionComponent={Fade}
          >
            <span>{`${GeneralData.KPIS.KPI_2_3}%`}</span>
          </LightTooltip>

          <LightTooltip
            title="RESPECTO A MES ANTERIOR"
            followCursor
            TransitionComponent={Fade}
          >
            <span>
              <FontAwesomeIcon
                icon={GeneralData.KPIS.KPI_2_4 > 0 ? faCircleArrowUp : faCircleArrowDown}
                className={`icon ${
                  GeneralData.KPIS.KPI_2_3 >= 0 ? 'icon-negative' : 'icon-positive'
                }`}
              />
              <span>{`${Math.abs(GeneralData.KPIS.KPI_2_3)}`}</span>
            </span>
          </LightTooltip>
        </li>

        <li className="generalInf">
          <LightTooltip title={`CUENTAS POR COBRAR`} followCursor TransitionComponent={Fade}>
            <Icon component={POR_COBRAR} className="icon" />
          </LightTooltip>
          <div>
            <LightTooltip
              title={`POR COBRAR: Q${GeneralData.KPIS.KPI_3_1.toFixed(2)}`}
              followCursor
              TransitionComponent={Fade}
            >
              <span>{`${abreviarNumero(GeneralData.KPIS.KPI_3_1)} /`}</span>
            </LightTooltip>

            <LightTooltip
              title={`CON RESPECTO AL MES ANTERIOR`}
              followCursor
              TransitionComponent={Fade}
            >
              <span>{`${GeneralData.KPIS.KPI_3_2}%`}</span>
            </LightTooltip>
          </div>

          <LightTooltip
            title="RESPECTO A MES ANTERIOR"
            followCursor
            TransitionComponent={Fade}
          >
            <span>
              <FontAwesomeIcon
                icon={GeneralData.KPIS.KPI_3_3 > 0 ? faCircleArrowUp : faCircleArrowDown}
                className={`icon ${
                  GeneralData.KPIS.KPI_3_3 > 0 ? 'icon-positive' : 'icon-negative'
                }`}
              />
              <span>{`${Math.abs(GeneralData.KPIS.KPI_3_3)}`}</span>
            </span>
          </LightTooltip>
        </li>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: slider ? `2.5rem` : '0rem',
          maxHeight: slider ? '400px' : '0px',
          overflow: 'hidden',
          transition: 'max-height 0.5s linear 0s, margin-top 0.5s linear 0s'
        }}
      >
        <div className="ContainerCharters">
          <ReactECharts option={barProjected()} className="chart-container" />
          <ReactECharts option={barDistribution()} className="chart-container" />
          <ReactECharts option={barResident()} className="chart-container" />
        </div>
      </div>
    </>
  )
}

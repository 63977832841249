import React, { FC, useEffect, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Image } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { IconButton } from '@mui/material'
import { Link } from 'react-router-dom'

import {
  Update,
  CheckCircle,
  Cancel,
  IndeterminateCheckBoxOutlined,
  Edit,
  PictureAsPdf
} from '@mui/icons-material'
import { getPaymentDetail, getStatementPdf } from '../../../services/Payments'
import './style.scss'
import { listPaymentsResident, PaymentDetaild, PaymentDetails } from '../../../types/Payments'
import Modal from '../../GeneralComponents/Modal'
import { ModalEditPayment } from './ModalEditPayment'
import { base64ToBlob } from '../../../utils/base64ToBlob'

interface ReceiptProps {
  destino?: string
  residente?: string
  fecha?: string
  agency?: string
  boleta?: string
  monto?: string
  expanded: boolean
  onClick?: () => void
}

const Receipt: React.FC<ReceiptProps> = ({
  destino,
  residente,
  fecha,
  agency,
  boleta,
  monto,
  expanded = false,
  onClick
}) => {
  return (
    <div
      className={`receipt-container ${expanded ? 'receipt-expanded' : ''}`}
      onClick={onClick}
      style={{
        cursor: expanded ? 'default' : 'pointer',
        padding: expanded ? '10px' : '0px',
        boxShadow: expanded ? '0px 4px 15px rgba(0, 0, 0, 0.2)' : 'none'
      }}
    >
      {expanded ? (
        <>
          <h6 className="receipt-title">Boleta de acreditamiento</h6>
          <div className="receipt-table">
            <p>
              <span className="bold-text">Destino:</span>
              <span className="small-text">{destino}</span>
            </p>
            <p>
              <span className="bold-text">Residente:</span>
              <span className="small-text">{residente}</span>
            </p>
            <p>
              <span className="bold-text">Fecha:</span>
              <span className="small-text">{fecha}</span>
            </p>
            <p>
              <span className="bold-text">Agencia:</span>
              <span className="small-text">{agency}</span>
            </p>
            <p>
              <span className="bold-text">Boleta:</span>
              <span className="small-text">{boleta}</span>
            </p>
            <p>
              <span className="bold-text">Monto:</span>
              <span className="small-text">{monto}</span>
            </p>
          </div>
          <img
            style={{
              width: '100px',
              marginLeft: '200px',
              marginBottom: '500px',
              marginTop: '-40px'
            }}
            src="images/logo_inicio.png"
            alt="Logo"
            className="receipt-image"
          />
        </>
      ) : (
        <span
          style={{
            padding: '5px',
            fontSize: '15px',
            fontWeight: 'bold',
            border: '1px solid pink',
            borderRadius: '50%',
            marginTop: '5px'
          }}
        >
          BA
        </span>
      )}
    </div>
  )
}

const PaymentsDetails: FC<{ Predata: listPaymentsResident; onClose: () => void }> = ({
  Predata,
  onClose
}) => {
  const { startLoading, stopLoading } = useLoading()
  const [data, setData] = useState<PaymentDetails>()
  const [select, setSelect] = useState<PaymentDetaild>()
  const [photo, setPhoto] = useState('images/logo_inicio.png')
  const [pdfUrl, setPdfUrl] = useState<string>()

  const altPhoto = 'images/logo_inicio.png'

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      startLoading()
      const statement = await getPaymentDetail({
        residentId: Predata.residentId,
        month: Predata.month
      })
      setData(statement)
      // statement?.lastPhoto && setPhoto(statement.lastPhoto)
      const hasPaymentWithoutPhoto = statement?.details.some(
        detail => detail.type === 'Pago' && !detail.photo
      )

      setPhoto(hasPaymentWithoutPhoto ? 'receipt' : statement?.lastPhoto || altPhoto)
      // setPhoto('')
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    'SIN HISTORIAL': <IndeterminateCheckBoxOutlined className="dashBoardInterminate" />,
    'AL DÍA': <CheckCircle className="dashBoardClose" />,
    'EN MORA': <Cancel className="dashBoardSpam" />,
    'EN VERIFICACIÓN': <Update className="dashBoardHold" />
  }

  const handlePhoto = (photo: string) => {
    setPhoto(photo) // Muestra la foto normalmente
  }

  const handleClosed = () => {
    setSelect(undefined)
    getData()
  }

  const handlePdfPayments = async () => {
    if (Predata.residentId) {
      try {
        startLoading()
        console.log(Predata.residentId)
        const month = Predata.month
        const dataPdf64 = await getStatementPdf(Predata.residentId, month) // Pedimos el base64 del pdf

        // Convierte el base64 en un objeto Blob
        const pdfBlob = base64ToBlob(dataPdf64, 'application/pdf')

        // Crea un enlace para ver el PDF
        const url = URL.createObjectURL(pdfBlob)
        setPdfUrl(url)
      } catch (error) {
        console.error('Error al crear reporte', error) // Maneja el error
      } finally {
        stopLoading()
      }
    }
  }

  const latestPayment = data?.details
    .filter(detail => detail.type === 'Pago' && detail.dateConfirm)
    .sort((a, b) => new Date(b.dateConfirm!).getTime() - new Date(a.dateConfirm!).getTime())[0]

  return (
    <div className="paymentsDetail-container">
      {data && (
        <>
          <div className="containerphoto">
            {photo === 'receipt' ? (
              latestPayment && (
                <Receipt
                  // key={index}
                  destino={Predata.destinationCode}
                  residente={Predata.names}
                  fecha={latestPayment.originalDate}
                  agency={latestPayment.agency}
                  boleta={latestPayment.boleta}
                  monto={` Q ${Math.abs(latestPayment.amount).toFixed(2)}`}
                  expanded={true}
                />
              )
            ) : (
              <div className="photo">
                <Image
                  preview={{
                    mask: (
                      <div>
                        <EyeOutlined />
                        <span>Ver</span>
                      </div>
                    )
                  }}
                  wrapperStyle={{ width: '100%', height: '100%' }}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    margin: '0px'
                  }}
                  src={photo}
                  className="image"
                />
              </div>
            )}
          </div>

          <div className="inf">
            <div className="database-container">
              <div className="data">
                <p>ACREDITÓ {data.dateAcredit || '--'}</p>
                <p>{Predata.names}</p>

                <p>
                  {data.clienName} {data.destinationCode}
                </p>

                <p>ESTADO DE CUENTA - {data.invoiceDate}</p>
              </div>
              <div className="status">{statusSvg[Predata.status]}</div>

              <div className="svgPdf">
                <IconButton onClick={handlePdfPayments}>
                  <PictureAsPdf style={{ color: 'white' }} />
                </IconButton>
              </div>
            </div>

            <div className="statement">
              {data?.details.map((detail, index) => (
                <div
                  key={index}
                  className="detail"
                  style={{ gridTemplateColumns: '1fr 2fr 2fr 4fr 3fr 2fr 0.5fr' }}
                >
                  {detail.type === 'Pago' && !detail.photo ? (
                    <Receipt
                      destino={Predata.destinationCode}
                      residente={Predata.names}
                      fecha={detail.originalDate}
                      agency={detail.agency}
                      boleta={detail.boleta}
                      monto={`${detail.amount}`}
                      expanded={false}
                      onClick={() => handlePhoto('receipt')}
                    />
                  ) : (
                    <img
                      onClick={() =>
                        detail.type === 'Pago' && detail?.photo && handlePhoto(detail?.photo)
                      }
                      src={detail.photo ?? altPhoto}
                      alt={'foto o icono'}
                      style={{
                        width: '30px',
                        height: '30px',
                        objectFit: 'cover',
                        borderRadius: '50%',
                        cursor: detail.type === 'Pago' ? 'pointer' : 'default'
                      }}
                    />
                  )}{' '}
                  <span>{detail.originalDate}</span>
                  <span>{detail.type}</span>
                  <span>{detail.name}</span>
                  <span>{detail.conceptDate}</span>
                  <span
                    style={{
                      justifySelf: 'right',
                      ...(detail.isDelete && detail.id !== '0'
                        ? {
                            textDecoration: 'line-through',
                            textDecorationColor: '#ff0000',
                            textDecorationThickness: '1px'
                          }
                        : {})
                    }}
                  >
                    Q {detail.amount.toFixed(2)}
                  </span>
                  {detail.type === 'Pago' && !detail.dateConfirm ? (
                    <IconButton onClick={() => setSelect(detail)} style={{ padding: '0px' }}>
                      <Edit style={{ color: 'antiquewhite', padding: '0px', width: '40px' }} />
                    </IconButton>
                  ) : (
                    <div
                      style={{
                        padding: '0px',
                        width: '40px'
                      }}
                    ></div>
                  )}
                </div>
              ))}
            </div>
            <div className="totalStatement">
              <div>SALDO A LA FECHA</div>
              <div>
                <span>Q {data.balance.toFixed(2)}</span>
              </div>
            </div>
          </div>

          {select && (
            <Modal
              isOpen={true}
              borderColor
              onClose={handleClosed}
              styleContainerContent={{ padding: '5px', margin: '0px' }}
              styleContainer={{
                width: '50%',
                height: '40%',
                padding: '0px',
                overflow: 'hidden',
                margin: '5px',
                borderRadius: '20px'
              }}
              styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
            >
              <ModalEditPayment
                IsEdit={select?.dateConfirm ? true : false}
                select={select}
                onClose={handleClosed}
                closeAll={onClose}
              />
            </Modal>
          )}

          {pdfUrl && (
            <Modal
              isOpen={true}
              styleContainer={{
                width: ' 100%',
                height: ' 100%',
                padding: '0px',
                background: 'rgb(0 0 0 / 0%)',
                overflow: 'hidden'
              }}
              styleContainerContent={{ padding: '0 60px', height: '100%', maxHeight: '100vh' }}
              onClose={() => setPdfUrl(undefined)}
            >
              <object
                data={pdfUrl}
                type="application/pdf"
                width="100%"
                height="99%" // Ajusta la altura según tus necesidades
              >
                <Link to={pdfUrl} target="_blank" rel="noopener noreferrer">
                  Descargar PDF
                </Link>
              </object>
            </Modal>
          )}
        </>
      )}
    </div>
  )
}

export default PaymentsDetails

import React, { FC } from 'react'
import { DatePicker } from 'antd'
import './style.scss'
import moment from 'moment-timezone'
import dayjs from 'dayjs'
import { BorderIpass } from '../../BorderIpass/Index'

type valueType = {
  start?: dayjs.Dayjs | null
  end?: dayjs.Dayjs | null
}

const RangePicker: FC<{
  onChange: (value: valueType) => void
  value: valueType
}> = ({ value, onChange }) => {
  const disabledEndDate = (current: any) => {
    if (!value.start || !current) {
      return false
    }
    return current.valueOf() < value.start.valueOf()
  }

  const handleChange = (date: any, type: 'start' | 'end') => {
    const newDate = date ? (date as any).toDate() : null
    const newDateGuatemala = moment.tz(newDate, 'America/Guatemala').toDate()
    onChange({ ...value, [type]: date ? dayjs(newDateGuatemala) : null })
  }

  return (
    <BorderIpass style={{ height: '45px', width: '100%', marginBottom: '10px' }}>
      <div className="date-range-picker-container">
        <DatePicker
          showTime
          className="date-picker"
          popupClassName="date-picker-dropdown"
          placeholder="Seleccione una fecha"
          value={value.start}
          onChange={date => handleChange(date, 'start')}
        />
        <DatePicker
          showTime
          className="date-picker"
          popupClassName="date-picker-dropdown"
          placeholder="Seleccione una fecha"
          value={value.end}
          onChange={date => handleChange(date, 'end')}
          disabledDate={disabledEndDate}
        />
      </div>
    </BorderIpass>
  )
}

export default RangePicker

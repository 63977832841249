// ClientDetailsPage.tsx
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ButtonSection from '../../components/ButtonSelection'
import { clientFormConfig } from '../../components/Tables/Clients/Columns'
import DynamicForm from '../../components/Form/DynamicForm'
import { getClient, updateClient } from '../../services/clients'
import { Client, ClientCreate } from '../../types/client'
import './styles.scss'
import AdministratorByClientTable from '../../components/Tables/Administrators/AdministratorByClientTable'
import ReceptionistByClientTable from '../../components/Tables/Receptionist/ReceptionistByClientTable'
import LocationByClientTable from '../../components/Tables/Location/LocationByClientTable'
import EquipmentByClientTable from '../../components/Tables/Equipment/EquipmentByClientTable'
import { useLoading } from '../../Provider/LoadingProvider'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { LightTooltip } from '../../components/tooltips/text'
import { ButtonB } from '../../components/Form/ButtonB'
import CertifierModal from '../../components/CertifierModal/CertifierModal'
import { getCertifiers } from '../../services/certifier'
import { Certifier } from '../../types/certifier'

const ClientDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const [clientDetails, setClientDetails] = useState<Client | null>(null)
  const { startLoading, stopLoading } = useLoading()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dataCertifier, setCertifier] = useState<Certifier>()

  const fetchData = async () => {
    try {
      const details = await getClient(id as string)

      setClientDetails(details)

      const certifier = await getCertifiers(id as string)
      certifier && setCertifier(certifier)
    } catch (error) {
      console.error('Error fetching client details:', error)
    }
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])

  const handleSubmit = async (formData: ClientCreate) => {
    try {
      startLoading()
      const data = new FormData()
      Object.entries({
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        nit: formData.nit,
        photo: formData.photo,
        administratorName: formData.administratorName
      }).forEach(([key, value]) => {
        data.append(key, value)
      })
      await updateClient(clientDetails?.id as string, data)
      fetchData()
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const buttonsInfo = [
    {
      text: 'ADMINISTRADORES',
      component: <AdministratorByClientTable id={id as string} />
    },
    {
      text: 'RECEPCIONISTAS',
      component: <ReceptionistByClientTable id={id as string} />
    },
    {
      text: 'LOCACIONES',
      component: <LocationByClientTable id={id as string} />
    },
    {
      text: 'VER EQUIPOS',
      component: <EquipmentByClientTable id={id as string} />
    }
  ]
  return (
    <div className="clientContainer">
      <div className="container-sup">
        <div className="title">
          <p>Detalles de cliente</p>
        </div>
        {clientDetails && (
          <DynamicForm
            initialValues={clientDetails}
            formConfig={clientFormConfig}
            onSubmit={handleSubmit}
          />
        )}
      </div>

      <div className="config-cliente">
        <div className="title-config">
          <p>Configuraciones</p>
          <LightTooltip
            title="Set de opciones para
                    configurar administradores
                    maestros, así como equipos
                    IPASS asociados, y el
                    certificador para emisión de
                    documentos fiscales."
            placement="right"
            sx={{
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#261a3a',
                color: '#fff',
                fontSize: '14px',
                padding: '10px',
                borderRadius: '8px',
                border: '1px solid  #5a1fd1',
                boxShadow: 'none'
              }
            }}
          >
            <HelpOutlineIcon style={{ cursor: 'pointer', color: '#fff', fontSize: '25px' }} />
          </LightTooltip>
        </div>
        <div className="config-button">
          {id && <ButtonSection buttonsInfo={buttonsInfo} />}
          <ButtonB
            style={{ marginLeft: '10px' }}
            text="CERTIFICADOR"
            onClick={() => setIsModalOpen(true)}
          />
          {isModalOpen && (
            <CertifierModal
              onSuccess={fetchData}
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              initialValue={dataCertifier}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ClientDetailsPage

import { QrCode } from '@mui/icons-material'
import { Badge, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../Provider/AuthProvider'
import { useLoading } from '../../Provider/LoadingProvider'
import { updateMaxGeneration } from '../../services/clients'
import Button from '../Form/Button'
import InputDark from '../Form/InputDark'
import InputLarge from '../Form/InputLarge'
import Switch from '../Form/Switch'
import './styles.scss'
import { BorderIpass } from '../BorderIpass/Index'
function QrLimit() {
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [infiniteGeneration, setInfiniteGeneration] = useState(
    user?.client?.infiniteGeneration || false
  )
  const [maxGeneration, setMaxGeneration] = useState(user?.client?.maxGeneration || 1)
  const handleAccept = async () => {
    try {
      startLoading()
      await updateMaxGeneration(user?.clientId as string, infiniteGeneration, maxGeneration)
    } catch (error) {
      console.log(error)
    } finally {
      stopLoading()
    }
  }
  const handleReject = () => {
    setMaxGeneration(user?.client?.maxGeneration || 1)
    setInfiniteGeneration(user?.client?.infiniteGeneration || false)
    setIsPopUpOpen(false)
  }
  return (
    <>
      <Badge
        badgeContent={infiniteGeneration ? 'L' : maxGeneration}
        color="success"
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <IconButton size="large" onClick={() => setIsPopUpOpen(true)}>
          <QrCode style={{ fontSize: '30px', color: '#e869b7' }} />
        </IconButton>
      </Badge>
      {isPopUpOpen && (
        <div className="popup-overlay-qr-limit">
          <BorderIpass>
            <div className="popup">
              <h3>{`Limite Qr por residente`}</h3>
              <div className="itemsContainer">
                <Switch
                  label=" Liberada"
                  onChange={setInfiniteGeneration}
                  value={infiniteGeneration}
                />
                {!infiniteGeneration && (
                  <InputLarge
                    type="number"
                    min={1}
                    label="Limitada"
                    onChange={e => setMaxGeneration(parseInt(e.target.value))}
                    value={maxGeneration}
                  />
                )}
              </div>
              <div className="itemsContainerButton">
                <BorderIpass style={{ width: '130px', borderRadius: '12px', border: '5px' }}>
                  <button
                    className="accept-button"
                    color="primary"
                    disabled={!infiniteGeneration && maxGeneration < 1}
                    onClick={handleAccept}
                  >
                    Aceptar
                  </button>
                </BorderIpass>
                <BorderIpass style={{ width: '130px', borderRadius: '12px', border: '5px' }}>
                  <button className="accept-button" color="primary" onClick={handleReject}>
                    Cerrar
                  </button>
                </BorderIpass>
              </div>
            </div>
          </BorderIpass>
        </div>
      )}
    </>
  )
}

export default QrLimit

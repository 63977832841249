import { SelectOptions } from '../types/Message_center'
import { ReasonAndRules } from '../types/reasonAndRules'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listResonAndRules = async ({
  // id,
  params
}: {
  // id: string
  params: PaginationQuery
}): Promise<Pagination<ReasonAndRules>> => {
  try {
    const response = await axiosInstance.get(`/api/reason_and_rules/`, {
      params
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en listStaffClient :', error)
    throw error
  }
}

export const createReasonAndRules = async (params: FormData): Promise<ReasonAndRules> => {
  try {
    const response = await axiosInstance.post(`/api/reason_and_rules`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en createReasonAndRules :', error)
    throw error
  }
}
export const updateReasonAndRules = async (
  id: string,
  params: FormData
): Promise<ReasonAndRules> => {
  try {
    const response = await axiosInstance.put(`/api/reason_and_rules/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en  updateReasonAndRules :', error)
    throw error
  }
}
export const deleteReasonAndRules = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/reason_and_rules/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en deleteReasonAndRules :', error)
    throw error
  }
}
export const updatePatchReasonAndRules = async (
  id: string,
  newStatus: boolean
): Promise<ReasonAndRules> => {
  try {
    const response = await axiosInstance.patch(`/api/reason_and_rules/${id}`, {
      newStatus
    })

    return response.data
  } catch (error) {
    console.error('Error en updateReasonAndRules:', error)
    throw error
  }
}

export const createCopyReasonAndRules = async (id: string): Promise<ReasonAndRules> => {
  try {
    const response = await axiosInstance.post(`/api/reason_and_rules/copy/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en createCopyReasonAndRules:', error)
    throw error
  }
}

export const ResonAndRulesListSelect = async (): Promise<SelectOptions[]> => {
  try {
    const response = await axiosInstance.get(`/api/reason_and_rules/dataSelect`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en listStaffClient :', error)
    throw error
  }
}

import React, { useState, useEffect } from 'react'
import './style.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Tooltip } from '@mui/material'
import 'moment/locale/es'
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons'
import { TitlePages } from '../../components/TitlePages'
import {
  Add,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  AccessTime,
  Search as SearchIcon
} from '@mui/icons-material'
import ModalCreateReserve from './ModalCreateReserve'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { BorderIpass } from '../../components/BorderIpass/Index'
import { listSegments } from '../../services/Segments'
import { useLoading } from '../../Provider/LoadingProvider'
import { Segments } from '../../types/Segments'
import { Pagination } from '../../types/types'
import MultiSelectANTD from '../../components/Form/multiSelet'
import { Select_Option_Resident } from '../../types/Message_center'
import { get_all_Residents } from '../../services/Message'
import { getDataResident, getReserves, deleteReserves } from '../../services/reserve'
import { ResidentData, Location, Reserve } from '../../types/reserve'
import moment from 'moment'
import { Visibility } from '@mui/icons-material'
import DeleteButton from '../../components/Table/components/DeleteButton'
import 'dayjs/locale/es'
dayjs.locale('es')
moment.locale('es')

type GroupedReserves = {
  [hour: string]: {
    count: number
    icon: string
    reservations: Reserve[]
  }
}

const ReservePage: React.FC = () => {
  const { startLoading, stopLoading } = useLoading()

  const [value, setValue] = useState<{
    day: string
    month: string
    year: string
    segmentId?: string
    locationId?: string
  }>({
    day: moment().format('DD'),
    month: moment().format('MM'),
    year: moment().format('YYYY')
  })

  const [allResidents, setAllResidents] = useState<Select_Option_Resident[]>([])
  const [selectedSegment, setSelectedSegment] = useState<Segments | null>(null)
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([])
  const [selectedLocation, setSelectedLocation] = useState<string | Location>('TODAS')
  const [showAllButton, setShowAllButton] = useState(false)
  const [selectModal, setSelectModal] = useState<React.ReactNode>()
  const [formValues, setFormValues] = useState<{ value: string | null }>({ value: null })
  const [residentDetail, setResidentDetail] = useState<ResidentData>()
  const [reserves, setReserves] = useState<Reserve[]>([])
  const [selectedDate, setSelectedDate] = useState(moment())
  const [isOpen, setIsOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedReserve, setSelectedReserve] = useState<Reserve | null>(null)
  const [data, setData] = useState<Pagination<Segments>>({
    total: 1,
    data: [],
    currentPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false
  })

  const [open, setOpen] = useState(false)

  const getResidentes = async (destines?: string[]) => {
    const data = await get_all_Residents({ search: destines })
    setAllResidents(data)
  }

  const getDataSegments = async (searchValue = '', page = 1) => {
    try {
      startLoading()
      const segments = await listSegments({ params: { page, search: searchValue, limit: 10 } })
      setData(segments)
    } catch (error) {
      console.error('Error al obtener segmentos:', error)
    } finally {
      stopLoading()
    }
  }

  useEffect(() => {
    setReserves([])

    getDataSegments('', 1)
    getResidentes()
  }, [])

  // useEffect(() => {
  //   if (data.data.length > 0) {
  //     setSelectedSegment(data.data[0])

  //     if (data.data[0]?.Locations && data.data[0].Locations.length > 0) {
  //       setShowAllButton(true)
  //     } else {
  //       setShowAllButton(false)
  //     }

  //     setSelectedLocations(data.data[0]?.Locations || [])
  //     setValue(prevValue => ({
  //       ...prevValue,
  //       segmentId: data.data[0]?.id
  //     }))
  //   }
  // }, [data])

  const handleIconClick = () => {
    setIsOpen(!isOpen)
  }

  const handleInputChange = ({ name, value }: { name: string; value: string | null }) => {
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleResidentSelection = async (value: string | string[] | null) => {
    const selectedValue = Array.isArray(value) ? value[0] : value

    handleInputChange({ name: 'value', value: selectedValue })

    if (selectedValue) {
      try {
        startLoading()
        const residentData = await getDataResident({ id: selectedValue })
        setResidentDetail(residentData)
      } catch (error) {
        console.error('Error al obtener el residente:', error)
      } finally {
        stopLoading()
      }
    }
  }

  const fetchReserves = async () => {
    try {
      startLoading()
      setReserves([])
      const data = await getReserves(value)
      setReserves(data)
    } catch (error) {
      console.error('Error fetching reserves:', error)
    } finally {
      stopLoading()
    }
  }

  const handleChangeDate = (date: moment.Moment) => {
    setReserves([])

    const newDate = date.clone()
    setSelectedDate(newDate)
    setValue({
      day: newDate.format('DD'),
      month: newDate.format('MM'),
      year: newDate.format('YYYY'),
      segmentId: selectedSegment?.id
    })
    setSelectedLocation('TODAS')
  }

  const handleSegmentClick = (segment: 'TODOS' | Segments) => {
    setReserves([])

    if (segment === 'TODOS') {
      setSelectedSegment(null)
      setSelectedLocations([])
      setShowAllButton(false)
      setValue(prevValue => ({
        ...prevValue,
        segmentId: undefined,
        locationId: undefined
      }))
    } else {
      setSelectedSegment(segment)
      setSelectedLocations(segment?.Locations || [])

      if (segment?.Locations && segment.Locations.length > 0) {
        setSelectedLocation('TODAS')
        setShowAllButton(true)
      } else {
        setShowAllButton(false)
      }

      setValue(prevValue => ({
        ...prevValue,
        segmentId: segment.id
      }))
    }

    setSelectedDate(moment())
  }

  const handleLocationClick = (location: string | Location) => {
    setReserves([])
    setSelectedLocation(location)

    if (location === 'TODAS') {
      setValue(prevValue => ({
        ...prevValue,
        locationId: undefined
      }))
    } else {
      setValue(prevValue => ({
        ...prevValue,
        locationId: (location as Location).id
      }))
    }
  }

  useEffect(() => {
    fetchReserves()
  }, [JSON.stringify(value)])

  const handleDeleteReserve = async (id: string) => {
    try {
      startLoading()
      await deleteReserves(id)
      getDataSegments('', data.currentPage)
      fetchReserves()
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }

  const onClose = () => {
    setIsModalOpen(false)
    setSelectedReserve(null)
  }

  useEffect(() => {
    if (selectedReserve) {
      setSelectModal(
        <ModalCreateReserve
          onClose={() => onClose()}
          initialValue={selectedReserve}
          onSuccess={() => onSuccess()}
          destinationCode={residentDetail?.destinationCode}
          isOpen={true}
        />
      )
    }
  }, [selectedReserve])

  const handleViewReserve = (reserva: Reserve) => {
    setSelectedReserve({
      ...reserva,
      code: reserva.destinationCode
    })
    setIsModalOpen(true)
  }

  const onSuccess = () => {
    fetchReserves()
    setIsModalOpen(false)
    setSelectedReserve(null)
  }

  const groupedReserves: GroupedReserves = reserves.reduce((acc, reserva) => {
    const hour = reserva.startHour
    if (!acc[hour]) {
      acc[hour] = { count: 0, icon: reserva.icon ?? '', reservations: [] }
    }
    acc[hour].count++
    acc[hour].reservations.push(reserva)
    return acc
  }, {} as GroupedReserves)

  return (
    <div className="container-res">
      <div className="container-reserve">
        <TitlePages
          title="RESERVAS"
          tooltip="Módulo para gestionar y crear reservas
                    de residentes"
        />
        <div className="container-headers-reserve">
          <div className="container-headers-button">
            <div className="search-section">
              <BorderIpass
                margin={1}
                status={'Normal'}
                style={{ width: '98%', height: '45px' }}
              >
                <MultiSelectANTD
                  colorBg="#261a3a"
                  showSearch
                  className="multiselect"
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    height: '42px',
                    borderRadius: '20px'
                  }}
                  placeholder="Destino"
                  options={allResidents.map(option => ({
                    key: option.label,
                    label: (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.1rem',
                          maxWidth: '95%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          lineHeight: '1.5rem',
                          maxHeight: '1.5rem'
                        }}
                      >
                        {option.label}
                      </div>
                    ),
                    value: option.value
                  }))}
                  filterOption={(input, option) =>
                    typeof option?.key === 'string' &&
                    option.key.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formValues.value ?? null}
                  onChange={value => {
                    handleResidentSelection(value)
                    setOpen(false)
                  }}
                  allowClear
                  maxTagCount={1}
                  open={open}
                  onDropdownVisibleChange={setOpen}
                />
              </BorderIpass>
            </div>
          </div>

          <div className="reserve-content-data">
            {residentDetail !== null ? (
              <div className="data-result">
                <div className="data">
                  <p>
                    <strong>Nombre:</strong>
                  </p>
                  <p>
                    <strong>Mes:</strong>
                  </p>
                  <p>
                    <strong>Día:</strong>
                  </p>
                  <p>
                    <strong>Hora:</strong>
                  </p>
                  <p>
                    <strong>Locación:</strong>
                  </p>
                  <p>
                    <strong>Estatus:</strong>
                  </p>
                </div>
                {residentDetail && (
                  <div className="data-resultt">
                    <p>
                      {residentDetail.nameResident} {residentDetail.lastNameResident}
                    </p>
                    <p>
                      {moment(residentDetail.date, 'DD/MM/YY').format('MMMM').toUpperCase()}
                    </p>
                    <p>
                      {moment(residentDetail.date, 'DD/MM/YY').format('dddd D').toUpperCase()}
                    </p>
                    <p>{moment(residentDetail.startHour, 'HH:mm').format('HH:mm')}</p>
                    <p>{residentDetail.location}</p>
                    <p
                      style={{
                        color: residentDetail.status === 'Confirmed' ? 'green' : 'yellow'
                      }}
                    >
                      {residentDetail.status === 'Confirmed'
                        ? 'CONFIRMADO'
                        : residentDetail.status}
                    </p>
                  </div>
                )}
                <div className="data-add">
                  {/* <IconButton onClick={() => console.log('hola')}>
                <Add style={{ color: 'white', fontSize: '2rem' }} />
              </IconButton> */}
                </div>
              </div>
            ) : (
              <p className="data-null">Este residente aún no tiene reservas</p>
            )}
          </div>
          <div className="reserve-content-info">
            <div className="icono">
              {selectedSegment?.icon && (
                <img
                  src={`${selectedSegment.icon}`}
                  alt={selectedSegment.name}
                  style={{ width: '100px', height: '100px' }}
                />
              )}
            </div>
            <div className="info">
              <p className="ubication">ESTAS EN:</p>
              <p>
                {(typeof selectedLocation === 'string'
                  ? selectedLocation
                  : selectedLocation?.name || ''
                ).toUpperCase()}
              </p>
              <div className="dates">
                <KeyboardArrowLeft
                  style={{
                    color: '#6125f0',
                    fontSize: '35px',
                    cursor: 'pointer',
                    opacity: 1
                  }}
                  onClick={() => handleChangeDate(selectedDate.clone().subtract(1, 'day'))}
                />
                <p
                  onClick={() => setIsOpen(true)}
                  style={{ cursor: 'pointer', margin: '0 10px' }}
                >
                  {selectedDate.format('D [de] MMMM [de] YYYY')}
                </p>
                <KeyboardArrowRight
                  style={{ fontSize: '35px', cursor: 'pointer', color: '#6125f0', opacity: 1 }}
                  onClick={() => handleChangeDate(selectedDate.clone().add(1, 'day'))}
                />
              </div>
            </div>
            <div className="calend">
              <FontAwesomeIcon
                icon={faCalendarDays as any}
                size="2x"
                onClick={handleIconClick}
              />
              {isOpen && (
                <div style={{ position: 'absolute', top: '40px', right: '0px' }}>
                  <DatePicker
                    value={null}
                    onChange={date => {
                      if (date) {
                        handleChangeDate(date)
                      }
                      setIsOpen(false)
                    }}
                    onOpenChange={open => setIsOpen(open)}
                    open={isOpen}
                    allowClear={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container-columns">
          <div className="container-left">
            <div className="segments">
              <div className="tittle-segment">
                <BorderIpass
                  personClassName={'button'}
                  margin={3}
                  styleInner={{
                    ...LetterStyleLong,
                    width: '50px',
                    height: '50px'
                  }}
                  style={LetterStyleLong}
                >
                  A
                </BorderIpass>
                <h5>SEGMENTOS</h5>
              </div>
              <div
                className={`segment-all ${selectedSegment === null ? 'selected' : ''}`}
                key="all"
                onClick={() => handleSegmentClick('TODOS')}
              >
                TODAS
              </div>

              {data.data.map(segment => (
                <div
                  className={`border-segment ${
                    selectedSegment?.id === segment.id ? 'selected' : ''
                  }`}
                  key={segment.id}
                  onClick={() => handleSegmentClick(segment)}
                >
                  {segment.icon && (
                    <Tooltip
                      title={segment.name}
                      placement="right-start"
                      slotProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: '#261a3a', // Color de fondo
                            color: 'white', // Color del texto
                            fontSize: '14px', // Tamaño del texto
                            padding: '8px 12px', // Espaciado interno
                            borderRadius: '8px', // Bordes redondeados
                            boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                          }
                        }
                      }}
                    >
                      <img
                        src={`${segment.icon}`}
                        alt={segment.name}
                        style={{ width: '100px', height: '100px' }}
                      />
                    </Tooltip>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="container-center">
            <div className="locations">
              <div className="tittle-location">
                <BorderIpass
                  personClassName={'button'}
                  margin={3}
                  styleInner={{ ...LetterStyleLong, width: '50px', height: '50px' }}
                  style={LetterStyleLong}
                >
                  B
                </BorderIpass>
                <h5>LOCACIONES</h5>
              </div>

              {showAllButton && (
                <div
                  className={`location-all ${selectedLocation === 'TODAS' ? 'selected' : ''}`}
                  key="all"
                  onClick={() => handleLocationClick('TODAS')}
                >
                  TODAS
                </div>
              )}
              {selectedLocations.length > 0 &&
                selectedLocations.map(item => (
                  <div
                    className={`location-item ${
                      selectedLocation !== 'TODAS' &&
                      (selectedLocation as Location)?.id === item.id
                        ? 'selected'
                        : ''
                    }`}
                    key={item.id}
                    onClick={() => handleLocationClick(item)}
                  >
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
          <div className="container-right">
            <div className="tittle-calendar">
              <BorderIpass
                personClassName={'button'}
                margin={3}
                styleInner={{ ...LetterStyleLong, width: '50px', height: '50px' }}
                style={LetterStyleLong}
              >
                C
              </BorderIpass>
              <h5>HORARIOS</h5>
            </div>

            <div className="container-calendar">
              <AccessTime style={{ color: 'white', fontSize: '40px' }} />

              <div className="data-add">
                <Tooltip
                  title="Crear reserva"
                  placement="right-start"
                  slotProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#261a3a', // Color de fondo
                        color: 'white', // Color del texto
                        fontSize: '14px', // Tamaño del texto
                        padding: '8px 12px', // Espaciado interno
                        borderRadius: '8px', // Bordes redondeados
                        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                      }
                    }
                  }}
                >
                  <IconButton onClick={() => setIsModalOpen(true)}>
                    <Add style={{ color: 'white', fontSize: '2.5rem' }} />
                  </IconButton>
                </Tooltip>

                {isModalOpen && (
                  <ModalCreateReserve
                    isOpen={isModalOpen}
                    onClose={() => onClose()}
                    data={data}
                    initialValue={selectedReserve ?? undefined}
                    destinationCode={residentDetail?.destinationCode}
                    onSuccess={() => onSuccess()}
                  />
                )}
              </div>
            </div>

            <div className="number-reservas">
              <div className="number">
                {[9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map(hora => (
                  <p key={hora}>{hora}</p>
                ))}
              </div>
              <div className="reservation">
                {selectedLocation === 'TODAS' ||
                selectedLocation === null ||
                selectedSegment === null
                  ? Object.entries(groupedReserves).map(([hour, data], index) => {
                      const hourNumber = parseInt(hour.split(':')[0])

                      return (
                        <div
                          key={index}
                          className="summary"
                          style={{
                            top: `${(hourNumber - 9) * 46}px`,
                            height: `35px`,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2px',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                          }}
                        >
                          {data.reservations.map((reservation, i) => {
                            const locationNumber =
                              reservation.location?.match(/\d+$/)?.[0] || 'N/A'
                            return (
                              <div
                                key={i}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '2px',
                                  flexDirection: 'row'
                                }}
                              >
                                <Tooltip
                                  title={
                                    <>
                                      <span>{reservation.location}</span>
                                      <br />
                                      <strong>
                                        {reservation.nameResident}{' '}
                                        {reservation.lastNameResident}
                                      </strong>{' '}
                                      <br />
                                      <span>{reservation.shortId}</span> <br />
                                      <span
                                        style={{
                                          color:
                                            reservation.status === 'Confirmed'
                                              ? 'green'
                                              : 'yellow'
                                        }}
                                      >
                                        {reservation.status === 'Confirmed'
                                          ? 'CONFIRMADO'
                                          : reservation.status}
                                      </span>
                                    </>
                                  }
                                  placement="top-start"
                                  slotProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: '#261a3a', // Color de fondo
                                        color: 'white', // Color del texto
                                        fontSize: '14px', // Tamaño del texto
                                        padding: '8px 12px', // Espaciado interno
                                        borderRadius: '8px', // Bordes redondeados
                                        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
                                      }
                                    }
                                  }}
                                >
                                  <img
                                    src={reservation.icon}
                                    alt="Reserva"
                                    style={{
                                      width: '40px',
                                      height: '40px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                </Tooltip>
                                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                  {locationNumber}
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      )
                    })
                  : reserves?.length > 0 &&
                    reserves.map((reserva, index) => {
                      return (
                        <div
                          key={index}
                          className="location-details"
                          style={{
                            top: `${(parseInt(reserva.startHour?.split(':')[0]) - 9) * 49}px`,
                            height: `${
                              (parseInt(reserva.endHour?.split(':')[0]) -
                                parseInt(reserva.startHour?.split(':')[0])) *
                              48
                            }px`,
                            border: `3px solid ${
                              reserva.status === 'Confirmed' ? 'green' : 'yellow'
                            }`
                          }}
                        >
                          <div className="detail">
                            <h5>
                              {reserva.shortId} {reserva.date}
                            </h5>
                            <p>
                              <strong>DE</strong>{' '}
                              {reserva.startTime?.split(' ')[1]?.split(':')[0]} A{' '}
                              {reserva.endTime?.split(' ')[1]?.split(':')[0]} HRS
                            </p>
                            <p>
                              {reserva.nameResident} {''}
                              {reserva.lastNameResident}
                            </p>
                            <p>{reserva.destinationCode}</p>
                            <p>
                              {reserva.status === 'Confirmed' ? 'CONFIRMADO' : reserva.status}
                            </p>
                          </div>
                          <div className="icon">
                            <div className="top">
                              <IconButton onClick={() => handleViewReserve(reserva)}>
                                <Visibility style={{ color: 'white' }} />
                              </IconButton>
                            </div>
                            <div className="bottom">
                              <DeleteButton OnDelete={() => handleDeleteReserve(reserva.id)} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReservePage

const LetterStyleLong: React.CSSProperties = {
  alignItems: 'center',
  borderRadius: '50%',
  fontSize: '40px',
  fontWeight: 'bold',
  color: 'white'
}

import axiosInstance from './axiosInstance'
import { ResidentData, searchResident, Reserve, confirmDates } from '../types/reserve'

export const getDataResident = async ({ id }: { id: string }): Promise<ResidentData> => {
  try {
    const response = await axiosInstance.get(`/api/reserves/made/resident/${id}`)
    return response.data
  } catch (error) {
    console.error('Error al obtener el residente:', error)
    throw error
  }
}

export const getReserves = async (params: {
  day: string
  month: string
  year: string
  segmentId?: string
  locationId?: string
}): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/api/reserves/made/`, {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching reserves:', error)
    throw error
  }
}

export const createReserve = async (params: FormData): Promise<Reserve> => {
  try {
    const response = await axiosInstance.post(`/api/reserves/made`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error al crear la reserva:', error)
    throw error
  }
}
export const confirmationDates = async (params: FormData): Promise<confirmDates> => {
  try {
    const response = await axiosInstance.post(`/api/reserves/made/confirm_dates`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return response.data
  } catch (error) {
    console.error('Error confirming dates:', error)
    throw error
  }
}

export const updateReserves = async (id: string, params: FormData): Promise<Reserve> => {
  try {
    const response = await axiosInstance.put(`/api/reserves/made/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en  updatedReserves :', error)
    throw error
  }
}
export const deleteReserves = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/reserves/made/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en deleteReserve :', error)
    throw error
  }
}

export const searchResidents = async (search: string): Promise<searchResident> => {
  try {
    const response = await axiosInstance.get(`/api/residents/search_for_destination`, {
      params: { search }
    })
    return response.data
  } catch (error) {
    console.error('Error al buscar el residente:', error)
    throw error
  }
}

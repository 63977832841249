import React, { FC, useEffect, useState, useRef } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Pagination } from '../../../types/types'
import Modal from '../../GeneralComponents/Modal'
import Table from '../../Table'

import { deleteReceptionist, listReceptionistClient } from '../../../services/receptionist'
import { Receptionist } from '../../../types/receptionist'
import DeleteButton from '../../Table/components/DeleteButton'
import { columnsReceptionist } from './Columns'
import FormReceptionist from './Form'
import { Visibility } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import './styles.scss'

const ReceptionistByClientTable: FC<{ id: string }> = ({ id }) => {
  const [data, setData] = useState<Pagination<Receptionist>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Receptionist | undefined>(undefined)
  const isFirstRender = useRef(true)

  useEffect(() => {
    getDataReceptionist('', 1)
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const delayDebounceFn = setTimeout(() => {
      getDataReceptionist(search, 1)
    }, 600)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onCloseModal = () => {
    setOpen(false)
    setSelected(undefined)
  }
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await deleteReceptionist(id)
      getDataReceptionist(search, data.currentPage)
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }
  const handleView = (value: Receptionist) => {
    setSelected(value)
  }
  const Actions = ({ id, item }: { id: string; item?: Receptionist }) => {
    return (
      <>
        <Tooltip
          title="Actualizar"
          placement="top-end"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#261a3a', // Color de fondo
                color: 'white', // Color del texto
                fontSize: '14px', // Tamaño del texto
                padding: '8px 12px', // Espaciado interno
                borderRadius: '8px', // Bordes redondeados
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
              }
            }
          }}
        >
          <IconButton onClick={() => handleView(item as Receptionist)}>
            <Visibility style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Eliminar"
          placement="top-end"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#261a3a', // Color de fondo
                color: 'white', // Color del texto
                fontSize: '14px', // Tamaño del texto
                padding: '8px 12px', // Espaciado interno
                borderRadius: '8px', // Bordes redondeados
                boxShadow: '0px 4px 10px rgba(0,0,0,0.2)' // Sombra
              }
            }
          }}
        >
          <IconButton style={{ padding: '0px', margin: '0px' }}>
            <DeleteButton OnDelete={() => handleDelete(id)} />
          </IconButton>
        </Tooltip>
      </>
    )
  }
  const handlePageChange = (newPage: number) => {
    getDataReceptionist(search.length >= 3 ? search : '', newPage)
  }

  const getDataReceptionist = async (searchValue: string, page: number) => {
    try {
      startLoading()
      const receptionist = await listReceptionistClient({
        id,
        params: { page, search: searchValue }
      })
      setData(receptionist)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleSubmit = () => {
    setOpen(false)
    getDataReceptionist(search, data.currentPage)
  }

  useEffect(() => {
    if (selected) {
      setOpen(true)
    }
  }, [selected])

  return (
    <div className="container-receptionist">
      <div className="title-receptionist">
        <h2>Recepcionistas</h2>
      </div>
      <div className="tableContainer">
        <Table
          onPageChange={handlePageChange}
          onChangeSearch={setSearch}
          pagination={data}
          handleOpenModal={handleOpenModal}
          columns={columnsReceptionist}
          Actions={Actions}
        />
      </div>
      <Modal
        isOpen={open}
        onClose={onCloseModal}
        borderColor={true}
        style_Subcontainer={{ padding: '1rem' }}
        styleContainerContent={{ padding: '2px', margin: '1px' }}
        styleContainer={{
          width: '620px',
          height: '610px',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: '23px', top: '47px' }}
      >
        <FormReceptionist initialValue={selected} clientId={id} onSubmit={handleSubmit} />
      </Modal>
    </div>
  )
}

export default ReceptionistByClientTable
